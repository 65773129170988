import logo from './logo.png';
import React, { useEffect, useState } from "react";
import './App.css';
// import { Outlet } from "react-router-dom";
import Routing from "./routing";
import { json } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { windowSize } from "./utility";
import PullToRefresh from 'react-simple-pull-to-refresh';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {

  const { isMobile } = windowSize()
  const query = new URLSearchParams(window.location.search);
  const department = query.get('department');
  const token = query.get('access_token');
  if (department && token) {
    // localStorage.setItem("department", department)
    // localStorage.setItem("access_token", token)
    // window.history.pushState({}, document.title, "/");

    let params = {};
    new URL(window.location).searchParams.forEach(function (val, key) {
      params[key] = val;
    });
    for (var el in params) {
      let value = params[el]
      localStorage.setItem(el, value)
    }
    localStorage.setItem('localDeviceId', uuidv4())
    localStorage.setItem('roles', JSON.stringify({'role':localStorage.getItem("role"),otherRoles:localStorage.getItem("otherRoles")}))
    window.history.pushState({}, document.title, "/");
  }

  return (
    <>
    <GoogleOAuthProvider clientId="200050510075-8u4kuhtknof3ni9ulil74adnm1vgltb4.apps.googleusercontent.com">
      {isMobile ?
        <PullToRefresh resistance={4} onRefresh={() => {
          return new Promise((resolve, reject) => {
            if (isMobile) {
              setTimeout(() => {
                resolve(window.location.reload())
              }, 1000)
            } else {
              reject()
            }
          })

        }}>
          <Routing />
          <ToastContainer />
        </PullToRefresh>
        : <>
          <Routing />
          <ToastContainer />
        </>}
    </GoogleOAuthProvider>
    </>
  );
}

export default App;
