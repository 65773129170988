import { v4 as uuidv4 } from 'uuid';
var token = localStorage.getItem('access_token')
const localDeviceId = uuidv4();

var baseUrl = process.env.REACT_APP_BASE_URL
var javaUrl = process.env.REACT_APP_JAVA_URL
var loginUrl = process.env.REACT_APP_LOGIN_URL
let tokenUrl = process.env.REACT_APP_TOKEN_URL
var socketUrl = `${process.env.REACT_APP_SOCKET_URL}&deviceId=${localDeviceId}`
var clientId = process.env.REACT_APP_CLIENT_ID

var axios = require('axios');

import { useNavigate } from "react-router-dom";
import { toaster, windowSize, getSystemRole } from "../utility";
export { clientId, socketUrl }

async function commonAxios(config) {

    if (!localStorage.getItem('access_token')) {
        localStorage.clear();
        window.location.href = '/login';
        return;
    } else {
        try {
            return await axios(config)
        } catch (error) {
            if (error.response.status == 401) {
                toaster('error', 'Session Expired, Please log in again!')
                localStorage.clear();
                window.location.href = '/login';
            } else {
                toaster('error', 'Request Timeout!')
            }
        }
    }
}

export function getTaskList(data) {
    var config = {
        method: 'post',
        url: `${baseUrl}/getTasksList`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return commonAxios(config)
}

export function fetchTaskDetails(id) {
    var data = JSON.stringify({
        "taskId": id
    });

    var config = {
        method: 'post',
        url: `${baseUrl}/fetchTaskDetails`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };

    return commonAxios(config)
}

export function getFiltersData() {
    var data = JSON.stringify({});
    var config = {
        method: 'post',
        url: `${baseUrl}/getFiltersData`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function createTask(data,isBd) {
    
    let apiUrl=isBd?`${baseUrl}/v2/createTask`:`${baseUrl}/createTask`

    var config = {
        method: 'post',
        url: apiUrl,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
        },
        data: data
    };
    return commonAxios(config)
}

export function getAllFilterData() {

    var config = {
        method: 'post',
        url: `${baseUrl}/getFiltersData`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
        },
        data: {}
    };
    return commonAxios(config)
}


export function editTaskDetails(data) {

    var config = {
        method: 'post',
        url: `${baseUrl}/editTaskDetails`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
        },
        data: data
    };
    return commonAxios(config)
}

export function getTaskHistory(id) {
    var data = JSON.stringify({
        "taskId": id
    });
    var config = {
        method: 'post',
        url: `${baseUrl}/getTaskAuditHistory`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function getTaskMessages(id) {
    var data = JSON.stringify({
        "taskId": id
    });
    var config = {
        method: 'post',
        url: `${baseUrl}/getTaskMessages`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function sendMessage(data) {
    var config = {
        method: 'post',
        url: `${baseUrl}/sendMessage`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function getSignedUrl(data) {
    var config = {
        method: 'post',
        url: `${javaUrl}/api/getSignedUrl`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function updateTaskStatus(data) {
    var config = {
        method: 'post',
        url: `${baseUrl}/updateTaskStatus`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function uploadFiles(data) {
    var config = {
        method: 'post',
        url: `${baseUrl}/uploadFiles`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function getBDTasksList(data) {
    var config = {
        method: 'post',
        url: `${baseUrl}/getBDTasksList`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function updateBDTaskDetails(data) {
    var config = {
        method: 'post',
        url: `${baseUrl}/updateBDTaskDetails`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function downloadTasksListCSVFile(data) {
    var config = {
        method: 'post',
        url: `${baseUrl}/downloadTasksListCSVFile`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function oauthToken1(email, code) {
    var qs = require('qs');
    var data = qs.stringify({
        'username': email,
        'password': '0000',
        'grant_type': "code",
        'code': code,
        'redirect_uri': 'http://localhost:4200',
        'clientId': clientId
    });

    var config = {
        method: 'post',
        url: `${loginUrl}/oauth/token`,
        headers: {
            "Authorization": "Basic YW5kcm9pZC1jbGllbnQ6YW5kcm9pZC1zZWNyZXQ=",
            "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data,
    };
    return commonAxios(config)
}

export function oauthToken(email, code) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YW5kcm9pZC1jbGllbnQ6YW5kcm9pZC1zZWNyZXQ=");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("User-Agent", "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36");
    myHeaders.append("Referer", window.location.origin);

    var urlencoded = new URLSearchParams();
    urlencoded.append("username", email);
    urlencoded.append("password", "0000");
    urlencoded.append("grant_type", "code");
    urlencoded.append("code", code);
    urlencoded.append("redirect_uri", window.location.origin);
    urlencoded.append("clientId", clientId);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    return fetch(tokenUrl, requestOptions)

}

export function oauthToken2(response) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic YW5kcm9pZC1jbGllbnQ6YW5kcm9pZC1zZWNyZXQ=");
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("User-Agent", "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36");
    myHeaders.append("Referer", window.location.origin);

    var urlencoded = new URLSearchParams();
    urlencoded.append("credential", response.credential)
    urlencoded.append("clientId", response.clientId);
    urlencoded.append("grant_type", "credential");

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    return fetch(tokenUrl, requestOptions)

}

export function getBDNotifications(userId) {
    var data = JSON.stringify({
        "userId": userId
    });
    var config = {
        method: 'post',
        url: `${baseUrl}/getBDNotifications`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function iconNotificationsCount() {
    var config = {
        method: 'post',
        url: `${baseUrl}/UpdateNotification`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: {}
    };
    return commonAxios(config)
}


export function markReadNotifications(readMsgId) {
    
    var data = new FormData();
    data.append("msgId", readMsgId);
    var config = {
        method: 'post',
        url: `${baseUrl}/MarkNotificationAsRead`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}


export function checkInTask(taskId, latitude, longitude, hospitalId, hospitalName, isClinic) {
    var data = JSON.stringify({
        "taskId": taskId,
        "latitude": latitude,
        "longitude": longitude,
        "hospitalId": hospitalId,
        "hospitalName": hospitalName,
        "isClinic": isClinic
    });
    var config = {
        method: 'post',
        url: `${baseUrl}/checkInTask`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}
export function checkOutTask(taskId, latitude, longitude, hospitalId, hospitalName, isClinic) {
    console.log(hospitalId)
    console.log(taskId)
    var data = JSON.stringify({
        "taskId": taskId,
        "latitude": latitude,
        "longitude": longitude,
        "hospitalId": hospitalId,
        "hospitalName": hospitalName,
        "isClinic": isClinic,
    });
    var config = {
        method: 'post',
        url: `${baseUrl}/checkOutTask`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function getHospitalMapping() {
    var data = JSON.stringify({});
    var config = {
        method: 'post',
        url: `${baseUrl}/getHospitalMapping`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}

export function getClinicMapping() {
    var data = JSON.stringify({});
    var config = {
        method: 'post',
        url: `${baseUrl}/getClinicMapping`,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return commonAxios(config)
}