import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, CardActions } from "@mui/material";
import highPriority from '../../../assets/highPriority.svg';
import mediumPriority from '../../../assets/mediumPriority.svg';
import lowPriority from '../../../assets/lowPriority.svg';
import { updateTaskStatus } from '../../../services';
import { windowSize, handleDate, toaster } from "../../../utility";

const priority = (key) => {
    if (!key) return
    let icon
    switch (key.toLowerCase()) {
        case 'high': icon = highPriority; break;
        case 'medium': icon = mediumPriority; break;
        case 'low': icon = lowPriority; break;
        default: break;
    }
    return icon
}

export default function MyCard({ index, task, filterData }) {

    const navigate = useNavigate();
    const { isMobile } = windowSize();

    const routing = (path) => {
        navigate(path)
    }

    const changeStatus = (status) => {
        let data = {
            "taskId": task.taskId,
            "status": status
        }
        updateTaskStatus(data).then((res) => {
            toaster('success', res.data.description)
        });
    }


    return (
        <React.Fragment>
            {!isMobile &&
                <CardActions sx={{ paddingTop: '20px', paddingLeft: '15px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            {index >= 0 && <div className="count">{index + 1}</div>}
                        </Grid>
                        <Grid item xs={5}>
                            <span className="title">Task ID:</span> <span onClick={() => routing('/viewTask/' + task.taskId)} className="taskId">{task.taskId}</span>
                        </Grid>
                        <Grid item xs={3}>
                            <span className="title">BD Name:</span> {task.bdName && task.bdName != 'undefined' ? task.bdName : ""}
                        </Grid>
                        <Grid item xs={3}>
                            <Grid item xs={7} sx={{ textAlign: 'right' }}>
                                <select className="taskStatus" onChange={(event) => changeStatus(event.target.value)}>
                                    <option value={''} selected={task.status ? true : false} disabled>Status</option>
                                    {filterData?.statusList && filterData?.statusList.map((status) => (<option value={status} selected={status == task.status}>{status}</option>))}
                                </select>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <span className="title">Title:</span>
                                </Grid>
                                <Grid item xs={11}>
                                    <div className="description"> {task.title}</div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <span className="title">GM:</span> {task.gmName && task.gmName != 'undefined' ? task.gmName : ""}
                        </Grid>
                        <Grid item xs={3}>
                            <span className="title">Visit Date:</span>
                            {task.visitDate ? handleDate(task.visitDate) : ''}
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={5}>
                            <span className="title">Department:</span> {task.department}
                        </Grid>
                        <Grid item xs={3}>
                            <span className="title">Reporter:</span> {task.reporterName}
                        </Grid>
                        <Grid item xs={3}>
                            <span className="title">Start Date:</span> {task.startDate ? handleDate(task.startDate) : ''}
                        </Grid>
                        <Grid item xs={1}>
                            <img src={priority(task.priority)} />
                        </Grid>
                        <Grid item xs={5}>
                            <span className="title">Task Type:</span> {task.taskType}
                        </Grid>
                        <Grid item xs={3}>
                            <span className="title">City:</span> {task.city}
                        </Grid>
                        <Grid item xs={3}>
                            <span className="title">End Date:</span> {task.endDate ? handleDate(task.endDate) : ''}
                        </Grid>
                    </Grid>
                </CardActions>
            }
            {isMobile &&
                <CardActions sx={{ marginTop: "5px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            {index >= 0 && <div className="count">{index + 1}</div>}
                        </Grid>
                        <Grid item xs={8} sx={{ fontWeight: "bold" }}>
                            {task?.taskId}
                        </Grid>
                        <Grid item xs={2}>
                            <img height={"20px"} sx={{ marginTop: "5px" }} src={priority(task?.priority)} />
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center", paddingTop: "0px!important", textOverflow:'ellipsis',overflow:"hidden",whiteSpace:'nowrap'}}>
                            {task?.title}
                        </Grid>
                        <Grid xs={12} sx={{ textAlign: "right", color: "#3D7CC9", fontSize: "10px" }}>
                            {task?.visitDate}
                        </Grid>
                    </Grid>
                </CardActions>
            }
        </React.Fragment>
    );
}