import React, { useState,useEffect } from "react";
import uploadImage from '../../../assets/uploadImage.svg';
import { useDropzone } from 'react-dropzone';
import documentImage from '../../../assets/document.svg'
import './dropfile.css';
import { generateAttachTime } from '../../../utility';
import pdfIcon from "../../../assets/icon-pdf.svg";
import docIcon from "../../../assets/icon-doc.svg";
import csvIcon from "../../../assets/icon-csv.svg";
import excelIcon from "../../../assets/icon-xls.svg";
const IMAGE_OBJ = {
  pdf:pdfIcon,
  xlsx:excelIcon,
  csv:csvIcon,
  doc:docIcon,
  docx:docIcon,
  default:documentImage,
  'application/pdf':pdfIcon
}

function DropImage({ isMobile, files, addImage }) {
    const { getRootProps, getInputProps } = useDropzone({
        accept: '*',
        onDrop: (acceptedFiles) => {
            addImage(acceptedFiles)
        },
        multiple: true
    })

    function checkURL(url) {
        return (url.match(/\.(jpeg|jpg|gif|png|svg)$/) != null);
    }

    const images = files.map((file, index) => {
        return (
            <>
                {file.preview ?
                    <li className="dropListImage" key={file.name}>
                        <a style={{ textDecoration: "none" }} href={file.preview} target='_blank'>
                            <img className={file.type.includes('image') ? "dropImage" : 'dropDocumentImage'} src={file.type.includes('image') ? file.preview : IMAGE_OBJ[file.filetype?file.filetype:'default']} alt='preview' />
                            <div className="caption"><p>Attachment {index + 1}</p> <p style={{ float: 'right' }}>{generateAttachTime(file.timestamp)}</p></div></a>
                    </li>
                    :
                    // file.subUrl &&
                    file.subUrl ?
                        file.subUrl.map((imageUrl, index2) =>
                            <li className="dropListImage" key={file.name}>
                                <a style={{ textDecoration: "none" }} href={imageUrl} target='_blank'>
                                    <img className={checkURL(file.multiUrl[index2]) ? "dropImage" : "dropDocumentImage"} src={checkURL(file.multiUrl[index2]) ? imageUrl : IMAGE_OBJ[file.filetype?file.filetype:'default']} alt='preview' />
                                    <div className="caption"><p>Attachment {index + index2 + 1}</p> <p style={{ float: 'right' }}>{generateAttachTime(file.timestamp)}</p></div>
                                </a>
                            </li>
                        ) :
                        <li className="dropListImage" key={file.name}>
                            <a style={{ textDecoration: "none" }} href={file.url} target='_blank'>
                                <img className={checkURL(file.message_media) ? "dropImage" : "dropDocumentImage"} src={checkURL(file.message_media) ? file.url : IMAGE_OBJ[file.filetype?file.filetype:'default']} alt='preview' />
                                <div className="caption"><p>Attachment {index + 1}</p> <p style={{ float: 'right' }}>{generateAttachTime(file.timestamp)}</p></div></a>
                        </li>
                }
            </>
        )
    })

    // const images = files.map((file, index) => {
    //     return (
    //         <li className="dropListImage" key={file.name}>
    //             <a style={{textDecoration:"none"}} href={file.preview} target='_blank'>
    //                 {file.preview ? 
    //                     <img className={file.type.includes('image')?"dropImage":'dropDocumentImage'} src={file.type.includes('image')?file.preview:documentImage} alt='preview' />
    //                     :
    //                     file.subUrl?
    //                     file.subUrl.map(imageUrl=><img className={"dropImage"} src={imageUrl} alt='preview' />):
    //                     <img className={"dropImage"} src={file.url} alt='preview' />
    //                 }
    //             <div className="caption"><p>Attachment {index}</p> <p style={{float:'right'}}>{generateAttachTime(file.timestamp)}</p></div></a>
    //         </li>
    //     )
    // })

    return (
        <div className='form-title'>
            <label style={{ marginBottom: '2%' }}>Attach Files: </label><br />
            <div className={'drop-attachment'}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="drop-message">
                        {isMobile ? <div className="drop-text mobile-screen">Upload New</div> :
                            <div className="drop-text large-screen"><a> Drop Files Here or Browse & Upload </a> </div>
                        }
                        <div className="drop-icon"><img src={uploadImage} /></div>
                    </div>
                </div>
            </div>
            {files.length > 0 ?
                <div className="drop-attachment">
                    <div>
                        <ul className="dropImagesContainer">
                            {files.length > 0 && files.map((file, index) => {
        return (
            <>
                    {file.preview ? 
                        <li className="dropListImage" key={file.name}>
                        <a style={{textDecoration:"none"}} href={file.preview} target='_blank'>
                        <img className={file.type.includes('image')?"dropImage":'dropDocumentImage'} src={file.type.includes('image')?file.preview:IMAGE_OBJ[file.filetype?file.filetype:'default']} alt='preview' />
                        <div className="caption"><p>{file.fileName?file.fileName:`Attachment ${index+1}`}</p> <p style={{float:'right'}}>{generateAttachTime(file.timestamp)}</p></div></a>
                        </li>
                        :
                         file.subUrl && file.subUrl.length>0? 
                        file.subUrl.map((imageUrl,index2)=>{
                            return(
                                <li className="dropListImage" key={file.name}>
                            <a style={{textDecoration:"none"}} href={imageUrl} target='_blank'>
                                <img className={checkURL(file.multiUrl[index2])?"dropImage":"dropDocumentImage"} src={checkURL(file.multiUrl[index2])?imageUrl:IMAGE_OBJ[file.filetype?file.filetype:'default']} alt='preview' />
                                <div className="caption"><p>{file.fileName?file.fileName:`Attachment ${index+index2+1}`}</p> <p style={{float:'right'}}>{generateAttachTime(file.timestamp)}</p></div>
                            </a>
                            </li>
                            )
                        }

                        ):
                        <li className="dropListImage" key={file.name}>
                        <a style={{textDecoration:"none"}} href={file.url} target='_blank'>
                        <img className={checkURL(file.message_media)?"dropImage":"dropDocumentImage"} src={checkURL(file.message_media)?file.url:IMAGE_OBJ[file.filetype?file.filetype:'default']} alt='preview' />
                        <div className="caption"><p>{file.fileName?file.fileName:`Attachment ${index+1}`}</p> <p style={{float:'right'}}>{generateAttachTime(file.timestamp)}</p></div></a>
                        </li>
                    }
            </>
        )
    })}
                        </ul>
                    </div>
                </div> : ""
            }
        </div>
    )
}

export default DropImage