import React, { useEffect, useRef, useState } from "react";
import { Grid, MenuItem, Select } from '@mui/material';
import { windowSize } from "../../../utility";
import { updateTaskStatus, updateBDTaskDetails } from '../../../services';
import { handleDate, toaster } from "../../../utility";
import { DatePkr } from "../../common";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';



const useStyles = makeStyles({

    forTextField: {
        width: '100%',
        '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            color: '#00263E',
            height: '10px',
        },
        '& .css-183cnfa-MuiInputBase-root-MuiOutlinedInput-root': {
            color: '#00263E',
            border: '1px solid #3D7CC9',
        },
        '& .css-mgud4i-MuiInputBase-root-MuiOutlinedInput-root': {
            border: '1px solid #3D7CC9',
        },

        '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
            height: '10px',
            minHeight: '0px',
            display: 'flex',
            alignItems: 'center',
        },
        '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root': {
            border: '1px solid #3D7CC9',
        },
        '& .makeStyles-forTextField-5 .css-183cnfa-MuiInputBase-root-MuiOutlinedInput-root': {
            border: '1px solid #3D7CC9',
        },
        '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
            color: '#3D7CC9',
        },
        '& .css-mhc70k-MuiGrid-root>.MuiGrid-item': {
            alignItems: 'start',
            paddingTop: "26px",
        },

        '&:hover': {
            border: "0px solid #3D7CC9",
        },

    }

});

function TaskDetail({ viewTask, filterData }) {
    const classes = useStyles();

    const [visitDate, setVisitDate] = useState(null)
    const [prevDate, setPrevDate] = useState(null)

    useEffect(() => {
        setVisitDate(viewTask.visitDate)
    }, [viewTask])

    const changeDateFormat = (date) => {
        if (!date) return
        let d = new Date(date)
        d.setDate(d.getDate() + 1)
        return d.toISOString().split("T")[0]

    }

    const changeStatus = (status) => {
        let data = {
            "taskId": viewTask.taskId,
            "status": status,
            "visitDate": moment(visitDate).format('YYYY-MM-DD') || ''
        }
        updateBDTaskDetails(data)
            .then((res) => {
                toaster('success', res.data.description);
            })
            .catch(() => { })
    }

    const updatTaskDetails = (event) => {
       
        // const date = `${event.$d.getFullYear()}-${event.$d.getMonth() + 1}-${event.$d.getDate()}`
        // let data = {
        //     "taskId": viewTask.taskId,
        //     "visitDate": moment(date).format('YYYY-MM-DD'),
        //     "status": viewTask.status
        // }
        let data = {
            "taskId": viewTask.taskId,
            "visitDate": moment(event.$d).format('YYYY-MM-DD'),
            "status": viewTask.status
        }
        updateBDTaskDetails(data)
            .then((res) => {
                toaster('success', res.data.description);
                // setPrevDate(date)
            })
            .catch((err) => {
                setVisitDate(prevDate)
            })
    }

    const updatTaskDetailsDesktop = (event) => {
        let prevDate = visitDate
        setVisitDate(event.target.value)
        let data = {
            "taskId": viewTask.taskId,
            "visitDate": moment(event.target.value).format('YYYY-MM-DD'),
            "status": viewTask.status
        }
        updateBDTaskDetails(data)
            .then((res) => {
                toaster('success', res.data.description);
            })
            .catch(err => {
                setVisitDate(prevDate)
            })
    }

    const { isMobile } = windowSize();
    return (
        <>
            <Grid container>
                <Grid item md={6} xs={6} className='status'>
                    Status
                </Grid>
                <Grid item md={6} xs={6}>
                    <div className='input-field'>
                        <select onChange={(event) => changeStatus(event.target.value)} className='selectInput selectLeftColWid1' style={!isMobile ? { width: "80%" } : { color: "white" }}>
                            <option value='' disabled>Select Current Status</option>
                            {filterData?.statusList.length && filterData.statusList.map((el) => (
                                <option value={el} selected={el == viewTask.status}>{el}</option>
                            ))}
                        </select>
                    </div>
                </Grid>
            </Grid>
            {isMobile &&
                <>
                    <Grid container className="mobile-screen">
                        <Grid item md={6} xs={6} className='form-title visitDate'>
                            Visit Date:
                        </Grid>
                        <Grid item md={6} xs={6} className='valueField'>
                            {/* {viewTask.reporterName} */}
                            {/* <DatePkr filterArray={viewTask} handleDate={(event) => updatTaskDetails(event)} name={"Visit Date"} value={"visitDate"} /> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker className="datePkr"
                                    sx={{ alignItems: 'center' }}
                                    label={name}
                                    inputFormat="DD/MM/YYYY"
                                    value={visitDate || null}
                                    onChange={(event) => {
                                       
                                        setPrevDate(visitDate)
                                        const date = `${event.$d.getFullYear()}-${event.$d.getMonth() + 1}-${event.$d.getDate()}`
                                        setVisitDate(date)
                                    }}
                                    onAccept={(event) => {
                                        updatTaskDetails(event)
                                    }}
                                    renderInput={(params) =>
                                        <TextField
                                            sx={{
                                                svg: { color: "#3D7CC9" },
                                                input: { color: "#818181" },
                                                label: { color: "#3D7CC9" },
                                            }} {...params}
                                            inputProps={{ ...params.inputProps, placeholder: 'dd/mm/yyyy' }}
                                        />}
                                        
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    <Grid container className="fieldContainer mobile-screen">
                        <Grid item md={12} xs={12} className='form-title'>
                            Description:
                        </Grid>
                        <Grid item md={12} xs={12} className='valueField' style={{
                            marginTop: '3%',
                        }}>
                            <p style={{
                                wordBreak: "break-word", maxHeight: "10rem",
                                overflow: "auto"
                            }}>
                                {viewTask.description}
                            </p>
                        </Grid>
                    </Grid>
                </>
            }

            {
                !isMobile &&
                <>
                    <Grid container className="fieldContainer">
                        <Grid item md={6} xs={6} className='form-title visitDateDesktop'>
                            Visit Date:
                        </Grid>
                        <Grid item md={5} xs={6} className='valueField'>
                            <TextField
                                className={classes.forTextField}
                                id="date"
                                type="date"
                                name='proposedStartDate'
                                value={visitDate}
                                onChange={(event) => { updatTaskDetailsDesktop(event) }}
                                sx={{ width: 'fit-content' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onKeyDown={(e) => e.preventDefault()}
                            />
                        </Grid>
                    </Grid>
                </>
            }


            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Assigned BD:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.bdName && viewTask.bdName != 'undefined' ? viewTask.bdName : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Reporter:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.reporterName && viewTask.reporterName != 'undefined' ? viewTask.reporterName : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    GM:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.gmName && viewTask.gmName != 'undefined' ? viewTask.gmName : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    AGM:
                </Grid>
                <Grid item md={6} className='valueField'>
                    {viewTask.agmName && viewTask.agmName != 'undefined' ? viewTask.agmName : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    AM/ZM:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.amName && viewTask.amName != 'undefined' ? viewTask.amName : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Start Date:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.startDate ? handleDate(viewTask.startDate) : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    End Date:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.endDate ? handleDate(viewTask.endDate) : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Creation Date:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask._created_at ? handleDate(viewTask._created_at) : '-'}
                </Grid>
            </Grid>
            {/* {!isMobile && 
                        <>
                        <Grid container className="fieldContainer large-screen">
                            <Grid item md={6}>
                                Visit Date:
                            </Grid>
                            <Grid item md={6} className='valueField'>
                                {viewTask.reporterName}
                            </Grid>
                        </Grid>                        
                        </>
                    } */}


            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Actual Start Date:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.actualStartDate ? handleDate(viewTask.actualStartDate) : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Actual End Date:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.actualEndDate ? handleDate(viewTask.actualEndDate) : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Task Type:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.taskType && viewTask.taskType != 'undefined' ? viewTask.taskType : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    City:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.city && viewTask.city != 'undefined' ? viewTask.city : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Hospital:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.hospName && viewTask.hospName != 'undefined' ? viewTask.hospName : '-'}
                </Grid>
            </Grid>

            <Grid container className="fieldContainer">
                <Grid item md={6} xs={6} className='form-title'>
                    Clinic:
                </Grid>
                <Grid item md={6} xs={6} className='valueField'>
                    {viewTask.clinicName && viewTask.clinicName != 'undefined' ? viewTask.clinicName : '-'}
                </Grid>
            </Grid>

            {!isMobile &&
                <Grid container className="fieldContainer large-screen">
                    <Grid item md={12}>
                        Description:
                    </Grid>
                    <Grid item md={12} className='valueField' >
                        <p style={{
                            wordBreak: "break-word", maxHeight: "7.5rem",
                            overflow: "auto", paddingRight: "30px"
                        }}>
                            {viewTask.description}
                        </p>
                    </Grid>
                </Grid>
            }

        </>
    )
}

export default TaskDetail