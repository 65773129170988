import React, { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './index.css'
import { Grid, MenuItem, Select, TextField, CircularProgress, Autocomplete } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { DropImage } from "../../common";
import { useNavigate } from "react-router-dom";
import { createTask } from "../../../services";
import { toaster, getSystemRole } from "../../../utility";
import Loader from "../../common/Loader/loader";

export default function CreateTask({ filterData, hospitalData, clinicData }) {

    const [role, setRole] = useState('')
    const [titleChar, setTitleChar] = useState(160)
    const [descriptionChar, setDescriptionChar] = useState(1000)

    useEffect(() => {
        setRole(getSystemRole())
    }, [])
    const [isLoading, setIsLoading] = useState(true)
    const [completeData, setCompleteData] = useState(null)
    const [files, setFiles] = useState([])
    const [imageArray, setImages] = useState([])
    const [AllData, setAllData] = useState({ title: "", taskType: "", description: "", city: "", priority: "", agmId: "", agmName: "", gmId: "", gmName: "", startDate: "", endDate: "", bdId: "", hospId: "", hospName: "", clinicId: "", clinicName: "" })
    const [errorMessage, setErrorMessage] = useState('')
    const [totalSize, setTotalSize] = useState(0)
    const [isButtonLoading, setIsButonLoading] = useState(false)
    const [filteredHospData, setFilteredHospData] = useState([])
    const [filteredClinicData, setFilteredClinicData] = useState([])

    useEffect(() => {
        if (filterData) {
            setCompleteData(filterData)
            setIsLoading(false)
        }
    }, [filterData])

    useEffect(() => {
        toaster('warning', errorMessage)
        setErrorMessage('')
    }, [errorMessage])

    const handleChangeHosp = (event, value) => {
        if (value == null) {
            setAllData({ ...AllData, hospId: "", hospName: "" })
        } else {
            setAllData({ ...AllData, hospId: value.id, hospName: value.label })
        }
    }

    const handleChangeClinic = (event, value) => {
        if (value == null) {
            setAllData({ ...AllData, clinicId: "", clinicName: "" })
        } else {
            setAllData({ ...AllData, clinicId: value.id, clinicName: value.label })
        }
    }

    const handleChange = (event) => {
        setAllData({ ...AllData, [event.target.name]: event.target.value })
    }

    const handleTask = (event, value) => {
        setAllData({ ...AllData, taskType: value })
    }

    const handleGM = (event, value) => {
        setAllData({ ...AllData, gmName: value.label, gmId: value.id })
    }

    const handleAGM = (event, value) => {
        setAllData({ ...AllData, agmName: value.label, agmId: value.id })
    }

    const handleBD = (event, value) => {
        setAllData({ ...AllData, bdName: value.label, bdId: value.id })
    }

    const handleCity = (event, value) => {
        if (hospitalData && hospitalData.length) {
            let hospitalDataTmp = hospitalData.filter(function (el) {
                return el.city == value
            });
            console.log(hospitalDataTmp)
            setFilteredHospData(hospitalDataTmp)
        }
        if (clinicData && clinicData.length) {
            let clinicDataTmp = clinicData.filter(function (el) {
                return el.city == value
            });
            setFilteredClinicData(clinicDataTmp)
        }
        setAllData({ ...AllData, city: value })
    }

    const addImage = (acceptedFiles) => {
        const fileTypeRegex = new RegExp(".(pdf|csv|doc|docx|xls?|gif|jpe?g|tiff?|png|webp|bmp)")
        let fileArray = []
        let total = totalSize
        acceptedFiles.forEach(file => {
            if (!fileTypeRegex.test(file.name)) {
                toaster("info", `${file.name} is not correct format!`)
                return
            }
            total = file.size + total
            if (total >= 5242880) {
                toaster("info", `total size exceed 5 Mb`)
                return
            }
            fileArray.push({
                // preview: acceptedFiles[0].type.includes('image') ?URL.createObjectURL(file):documentImage,
                preview: URL.createObjectURL(file),
                name: file.name,
                timestamp: new Date(),
                type: file.type
            })
        })
        setFiles(prevState => [
            ...prevState,
            ...acceptedFiles
        ])
        setImages(prevState => [
            ...prevState,
            ...fileArray
        ])
        setTotalSize(total)

    }

    const changeDateFormat = (date) => {
        if (!date) return
        let d = new Date(date)
        d.setDate(d.getDate() + 1)
        return d.toISOString().split("T")[0]

    }

    const isValidation = () => {
        let message = ''
        let flag = true
        if (AllData.taskType.trim() === '') {
            flag = false
            message = 'Please Select Task Type'
        }
        else if (AllData.gmName.trim() === '' && role != "cityGM" && role != "cityAGM" && role != "cityAM") {
            flag = false
            message = 'Please Select GM'
        }
        else if (AllData.city.trim() === '') {
            flag = false
            message = 'Please select the city'
        }
        else if (AllData.title.trim() === '') {
            flag = false
            message = 'Please Enter the Task Title'
        }
        else if (AllData.description.trim() === '') {
            flag = false
            message = 'Please Enter the description'
        }
        else if (AllData.priority.trim() === '') {
            flag = false
            message = 'Please Select the Priority'
        }
        // else if (AllData.hospId.trim() === '') {
        //     flag = false
        //     message = 'Please Select the Hospital'
        // }
        setErrorMessage(message)
        return (flag)
    }
    const submitDetail = (event) => {
        event.preventDefault()
        let isBd=false;
        let data = new FormData()
        data.append('title', AllData.title)
        data.append('description', AllData.description);
        data.append('priority', AllData.priority);
        data.append('taskType', AllData.taskType);
        data.append('startDate', changeDateFormat(AllData.startDate) || "");
        data.append('endDate', changeDateFormat(AllData.endDate) || "");
        data.append('city', AllData.city);
        data.append('hospId', AllData.hospId);
        data.append('hospName', AllData.hospName);
        data.append('clinicId', AllData.clinicId);
        data.append('clinicName', AllData.clinicName);
        let department = localStorage.getItem("department")
        if (!department) {
            if (role == 'cityOps') {
                toaster('warn', "BD not allowed to create Task!")
            }
            routing('/')
        }
        data.append('department', department);
        if (role == "cityGM") {
            data.append('gmId', localStorage.getItem('userId') || "");
            data.append('gmName', localStorage.getItem('name') || "");
        } else {
            if (AllData.gmId) {
                data.append('gmId', AllData.gmId || "");
                data.append('gmName', AllData.gmName || "");
            } else {
                data.append('gmId', '');
                data.append('gmName', '');
            }
        }
        if (role == "cityAGM") {
            data.append('agmId', localStorage.getItem('userId') || "");
            data.append('agmName', localStorage.getItem('name') || "");
        } else {
            data.append('amId', '');
            data.append('amName', '');
        }
        if (AllData.bdId) {
            data.append('bdId', AllData.bdId || "");
            data.append('bdName', AllData.bdName || "");
        } else {
            data.append('bdId', '');
            data.append('bdName', '');
        }
        // Finance
        // Insurance
        // Supply Team
        files.forEach(file => {
            const newName = file.name.slice(file.name.lastIndexOf("."), file.name.length)
            data.append('files', file, newName)
        })

        if (isValidation()) {
            setIsLoading(true)
            createTask(data,isBd)
                .then(function (response) {
                    if (response && response.data && response.data.status === "Success") {
                        toaster('success', response.data.description)
                        routing('/')
                    }
                    else {
                        if (response && response.data && response.data.status === "Failed") {
                            toaster('error', description)
                        }

                    }
                    setIsButonLoading(false)
                })
                .catch(function (error) {
                    setIsButonLoading(false)
                });
        }
    }

    const navigate = useNavigate();

    const routing = (path) => {
        navigate(path)
    }
    return (
        <div>
            {isLoading && <Loader />}
            <header className='screen-header'>
                <Grid container>
                    <Grid item md={2} className="left-arrow">
                        <ArrowBackIcon onClick={() => routing('/')} />
                    </Grid>
                    <Grid item md={10} className='header-title'>
                        <h4> Create Task</h4>
                    </Grid>
                </Grid>
            </header>

            <form className='input-field-container' onSubmit={submitDetail}>
                <Grid container>
                    <Grid item md={3}>
                        <div className='input-field'>
                            <label>Task Type</label><br />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={completeData && completeData.taskNamesList.map(task => { return task })}
                                renderInput={(params) => <TextField {...params} />}
                                className="selectLeftColWid"
                                name='taskType'
                                onChange={(event, value) => handleTask(event, value)}
                            />
                        </div>

                        {(role != "cityGM" && role != "cityAGM" || role == "taskHead") &&
                            <div className='input-field'>
                                <label>GM Name</label><br />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={completeData && completeData.gmList.map(gm => { return { id: gm['userId'], label: gm['name'] } })}
                                    renderInput={(params) => <TextField {...params} />}
                                    className="selectLeftColWid"
                                    name='gmId'
                                    onChange={(event, value) => handleGM(event, value)}
                                />
                            </div>
                        }
                        {role != "cityAGM" || role == "taskHead" &&
                            <div className='input-field'>
                                <label>AGM Name</label><br />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={completeData && completeData.agmList.map(agm => { return { id: agm['userId'], label: agm['name'] } })}
                                    renderInput={(params) => <TextField {...params} />}
                                    className="selectLeftColWid"
                                    name='agmId'
                                    onChange={(event, value) => handleAGM(event, value)}
                                />
                            </div>
                        }
                        {/* {(role == "cityGM" || role == "cityAGM" || role == "taskHead") && */}
                        <div className='input-field'>
                            <label>BD Name</label><br />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={completeData && completeData.bdList.map(bd => { return { id: bd['userId'], label: bd['name'] } })}
                                renderInput={(params) => <TextField {...params} />}
                                className="selectLeftColWid"
                                name='bdId'
                                onChange={(event, value) => handleBD(event, value)}
                            />
                        </div>
                        {/* } */}

                        <div className='input-field'>
                            <label> Start Date </label><br />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    inputFormat="DD-MM-YY"
                                    maxDate={AllData.endDate}
                                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, placeholder: 'DD - MM - YY' }} style={{ color: 'rgb(0, 38, 62)' }} />}
                                    value={AllData.startDate !== '' ? AllData.startDate : null}
                                    onChange={(newValue) => {
                                        setAllData({ ...AllData, startDate: newValue })
                                    }}
                                    components={{
                                        OpenPickerIcon: CalendarMonthOutlinedIcon
                                    }}
                                />
                            </LocalizationProvider>
                        </div>

                        <div className='input-field'>
                            <label> End Date</label><br />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    inputFormat="DD-MM-YY"
                                    minDate={AllData.startDate}
                                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, placeholder: 'DD - MM - YY' }} />}
                                    value={AllData.endDate !== '' ? AllData.endDate : null}
                                    onChange={(newValue) => {
                                        setAllData({ ...AllData, endDate: newValue })
                                    }}
                                    components={{
                                        OpenPickerIcon: CalendarMonthOutlinedIcon
                                    }}
                                />
                            </LocalizationProvider>
                        </div>

                        <div className='input-field'>
                            <label>City</label><br />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={completeData && completeData.citiesList.map(city => { return city })}
                                renderInput={(params) => <TextField {...params} />}
                                className="selectLeftColWid"
                                name='city'
                                onChange={(event, value) => handleCity(event, value)}
                            />
                        </div>

                        <div className='input-field'>
                            <label>Hospital</label><br />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={filteredHospData.map(el => { return { id: el['_id'], label: el['hospitalName'] } })}
                                renderInput={(params) => <TextField {...params} />}
                                className="selectLeftColWid"
                                onChange={(event, value) => handleChangeHosp(event, value)} name='hospId'
                                disabled={AllData.clinicName ? true : false}
                            />
                        </div>

                        <div className='input-field'>
                            <label>Clinic</label><br />
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={filteredClinicData.map(el => { return { id: el['_id'], label: el['hospitalName'] } })}
                                renderInput={(params) => <TextField {...params} />}
                                className="selectLeftColWid"
                                onChange={(event, value) => handleChangeClinic(event, value)} name='clinicId'
                                disabled={AllData.hospName ? true : false}
                            />
                        </div>
                    </Grid>

                    <Grid item md={9}>
                        <div className='input-field'>
                            <label>Title</label> <br />
                            <textarea
                                name='title'
                                value={AllData.title}
                                onChange={(event) => {
                                    handleChange(event)
                                    setTitleChar(160 - event.target.value.length)
                                }}
                                type='textarea'
                                className='text-field title-h text-area-input-field'
                                maxLength='160'
                            />
                            <p className="character-limit">{titleChar} characters</p>
                        </div>

                        <div className='input-field'>
                            <label>Description</label> <br />
                            <textarea
                                name="description"
                                onChange={(event) => {
                                    handleChange(event)
                                    setDescriptionChar(1000 - event.target.value.length)
                                }}
                                value={AllData.description}
                                className='text-field description-h text-area-input-field'
                                maxLength='1000'
                            />
                            <p className="character-limit">{descriptionChar} characters</p>
                        </div>

                        <div className='input-field'>
                            <label> Priority</label><br />
                            <Select displayEmpty className={AllData?.priority == "" ? 'selectInput selectRightColWid' : "sselectRightColWid selectInputToggle"} name='priority' onChange={handleChange}>
                                <MenuItem>Select Priority based on urgency</MenuItem>
                                <MenuItem value='high' name='priority'> <p style={{ color: '#00263E', margin: "0px" }}> High </p> </MenuItem>
                                <MenuItem value='medium' name='priority'> <p style={{ color: '#00263E', margin: "0px" }}> Medium </p> </MenuItem>
                                <MenuItem value='low' name='priority'> <p style={{ color: '#00263E', margin: "0px" }}> Low </p></MenuItem>
                            </Select>
                        </div>
                        <Grid container>
                            <Grid item md={7} xs={12} style={{ margiTop: '5%' }}>
                                <DropImage files={imageArray} addImage={addImage} savedFiles={[]} />
                            </Grid>

                            <Grid item md={5} xs={12} className='btn-container'>
                                <button type='button' onClick={() => routing('/')} className='btn-info cancel-button'> Cancel </button>
                                <button type='submit' className='btn-info create-button'> Create
                                    {isButtonLoading && <CircularProgress style={{
                                        width: '19px',
                                        height: '19px',
                                        color: 'black'
                                    }} />}
                                </button>
                            </Grid></Grid>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}