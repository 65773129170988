import { Avatar, Box, Button, CircularProgress, CssBaseline, Grid, Link, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { gapi, loadAuth2 } from 'gapi-script';
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { connect, useSelector } from "react-redux";
// import { userActions } from "../../../../../_actions";
import { Debounce, validateInput, toaster } from "../../../utility";
// import { resetSelectedStatesToInitial } from "../../../../../_helpers/getInitialState";
import googleLogo from "../../../assets/googleLogo.png";
// import RegisterNewUser from "../../../../authScreen/RegisterNewUser";
// import "./../../../../authScreen/__style.css";
// import Divider from '@mui/material/Divider';
import { clientId, oauthToken, oauthToken2 } from "../../../services";
import { v4 as uuidv4 } from 'uuid';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';

const snackBarDebounce = new Debounce();
// const otpTimerDebounce = new Debounce();
// const otpTimerLength = 5000;

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://pristyncare.com/">
                Pristyn Care
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}
var googleCredsFound = false;
var globalCode = ''
export const getAccess = async (responseGoogle) => {
    let auth2 = await loadAuth2(gapi, clientId).catch(err => {
        toaster('error', 'Client Id not authorised');
    });
    if (!auth2) {
        return
    }
    // let gapiClient =await loadClientAuth2(gapi, process.env.REACT_APP_CLIENT_ID, '');

    // var ga = auth2.getAuthInstance();
    var ga = gapi.auth2.getAuthInstance();
    googleCredsFound = false;
    const creds = await ga
        .grantOfflineAccess()
        .then((res) => {
            globalCode = res.code
            return res.code;
        })
        .then((res) => {
            ga.currentUser.listen((data) => {
                if (googleCredsFound == false) {
                    var email = ga.currentUser.get().getBasicProfile().getEmail();
                    googleCredsFound = true;
                    // responseGoogle(email, globalCode)
                    oauthToken(email, globalCode)
                        .then(res => res.json())
                        .then(resp => {
                            for (var el in resp) {
                                let value = resp[el]
                                if (el === "roles") {
                                    value = JSON.stringify(resp[el]);
                                }
                                localStorage.setItem(el, value)
                            }
                            localStorage.setItem('localDeviceId', uuidv4())
                            window.location.href = '/';
                        })
                }
            });
        })
        .catch((err) => {
            toaster('error', err);

        });
    return creds;
};
const theme = createTheme();
export default function Login(props) {

    const { loggingIn, user, isOtpLoader, topText, isOtpScreen, isVerifyLoader } = props;

    // const otpResponse = useSelector((state) => state.authentication.otpResponse);
    // const error = useSelector((state) => state.authentication.error) ?? {};
    // const showRegisterUser =
    // useSelector((state) => state.authentication.showRegisterUser) ?? false;

    // const [isLoader, setLoader] = useState(false);
    const [otp, setOtp] = useState(false);

    const [isInvaildInput, setIsInvaildInput] = useState({
        error: false,
        des: "Only digits are allowed",
    });

    const responseGoogle = (email, code) => {
        const { dispatch } = props;

        // dispatch(
        //     userActions.getAuth({
        //         mobile: mobileNumber,
        //         otp: otp,
        //         email: email,
        //         code: code
        //     }, true)
        // );
    };

    const [mobileNumber, setMobileNumber] = useState("");
    // const navigate = useNavigate()

    const handleInputChange = (e) => {
        let { value } = e.target;

        if (!validateInput(value, setIsInvaildInput)) return;
        setMobileNumber(value);
    };

    const handleSubmit = (event) => {
        event?.preventDefault();

        if (!validateInput(mobileNumber, setIsInvaildInput)) return;
        if (mobileNumber.length !== 10 || isInvaildInput["error"]) return;

        const { dispatch } = props;
        dispatch(
            userActions.getOtp({
                mobile: mobileNumber,
            })
        );

        //clear the OTP when the resend is clicked
        setOtp(() => "");

        // storing the user mobile number in local storage ----------------
        localStorage.setItem("userMobile", mobileNumber);
        // storing the user mobile number in local storage ----------------
    };

    const handleSubmitOtp = (e) => {
        e.preventDefault();
        // setVerifyLoader(true);
        const { dispatch } = props;
        dispatch(
            userActions.setUserMobileNumber({
                userMobile: mobileNumber,
            })
        );

        dispatch(
            userActions.getAuth({
                mobile: mobileNumber,
                otp: otp,
            })
        );

        //clear the OTP when the resend is clicked
        setOtp(() => "");
    };

    const handleCloseSnackbar = () => {
        // setLoginMsg(false);
    };
    const logoutFunc = () => {
        // const { dispatch } = props;
        // resetSelectedStatesToInitial(dispatch, [
        //     {
        //         action: userActions.updateFullState,
        //         stateName: "authentication",
        //         addtionalStateData: { loggingIn: false },
        //     }])
        // dispatch(userActions.logout(false))
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />

            <Box
                component="main"
                sx={{
                    maxWidth: "unset",
                    height: '100vh',
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                }}
            >
                <div
                    className="circle1"
                    style={{
                        background: "#F7BE00",
                        borderRadius: " 0px 0px 0px 100%",
                        width: "calc(16.6667vw)",
                        height: "calc(16.6667vw)",
                        marginLeft: "auto",
                        minHeight: "80px",
                        minWidth: "80px",
                        maxWidth: " 150px",
                        maxHeight: "150px",
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                />
                <>
                    {/* otp screen/ login screen/register new user screen */}
                    <Box
                        sx={{
                            display: "grid",
                            alignContent: "center",
                            position: 'relative',
                            zIndex: 9,
                            my: 'auto',
                            marginTop: ["auto", "auto", "10vh"],
                            pt: '20px'
                        }}
                    >
                        {isOtpScreen ? (
                            showRegisterUser ? (
                                // <RegisterNewUser />
                                <></>
                            ) : (
                                <OtpComp
                                    data={{
                                        otpResponse,
                                        mobileNumber,
                                        error,
                                        isInvaildInput,
                                        handleInputChange,
                                        handleSubmitOtp,
                                        otp,
                                        setOtp,
                                        logoutFunc,
                                        // otpTimerObj,
                                        handleSubmit,
                                        isOtpLoader,
                                        isVerifyLoader,
                                    }}
                                />
                            )
                        ) : (
                            <LoginComp
                                data={{
                                    handleSubmit,
                                    isInvaildInput,
                                    handleInputChange,
                                    mobileNumber,
                                    isOtpLoader,
                                    responseGoogle
                                }}
                            />
                        )}
                    </Box>
                    <Snackbar
                        open={loggingIn}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                    >
                        <div>Login SuccessFULLY</div>

                    </Snackbar>
                </>
                <div
                    className="circle2"
                    style={{
                        background: "#893ebf",
                        borderRadius: " 0px 100% 0px 0px",
                        width: "calc(16.6667vw)",
                        height: "calc(16.6667vw)",
                        minHeight: "120px",
                        minWidth: "120px",
                        maxWidth: " 150px",
                        maxHeight: "150px",
                    }}
                />
            </Box>


        </ThemeProvider>
    );
}

const OtpStyle = {
    justifyContent: "center",

    span: {
        margin: "0 1rem",
    },

    input: {
        height: "50px",
        width: "50px",
        borderRadius: "5px",
        border: "1px solid gray",
        fontSize: "1rem",
        letterSpacing: 0,
    },

    button: {
        marginTop: "40px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-evenly",
    },
};

function mapStateToProps(state) {
    const { loggingIn, user, isOtpLoader, topText, isOtpScreen, isVerifyLoader } =
        state.authentication;
    return {
        loggingIn,
        user,
        isOtpLoader,
        topText,
        isOtpScreen,
        isVerifyLoader,
    };
}

const LoginComp = ({ data }) => {

    let deferredPrompt;

    window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        deferredPrompt = e;
        document.getElementById("addToHomeScreen").style.display = "unset";
    });

    const installApp = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
            })
        } else {
            toaster('warning', "Device not Supported!");
        }
    }


    const {
        handleSubmit,
        isInvaildInput,
        handleInputChange,
        mobileNumber,
        isOtpLoader,
        responseGoogle
    } = data;

    const onLogin = (response) => {
        console.log(response)
        oauthToken2(response)
            .then(res => res.json())
            .then(resp => {
                for (var el in resp) {
                    let value = resp[el]
                    if (el === "roles") {
                        value = JSON.stringify(resp[el]);
                    }
                    localStorage.setItem(el, value)
                }
                localStorage.setItem('localDeviceId', uuidv4())
                window.location.href = '/';
            })
    }
    return (
        <Box
            component="form"
            noValidate
            sx={{ mt: 1, width: "240px", mx: "auto", }}
            onSubmit={handleSubmit}
        >
            <Paper elevation={2} sx={{ margin: "0 auto" }}>
            </Paper>
                <GoogleLogin
                    onSuccess={onLogin}
                    onError={() => {
                        console.log('Login Failed');
                    }}
                    sx={{
                        "textAlign":"center"
                    }}
                />
            <Paper elevation={2} sx={{ margin: "0 auto", marginTop: '50px' }}>
                <Button
                    id="addToHomeScreen"
                    onClick={() => installApp()}
                    type="submit"
                    fullWidth
                    variant="contained">
                    Add To Home Screen
                </Button>
            </Paper>
        </Box>
    );
};

const OtpComp = ({ data }) => {
    const {
        otpResponse,
        mobileNumber,
        error,
        handleSubmitOtp,
        otp,
        setOtp,
        handleSubmit,
        isOtpLoader,
        isVerifyLoader,
        isInvaildInput,
        handleInputChange,
        logoutFunc
        // otpTimerObj,
    } = data;

    const [showSnackBar, setShowSnackBar] = useState(false);
    const [disableOTPSubmit, setDisableOTPSubmit] = useState(true);
    const [disableNumberInput, setDisableNumberInput] = useState(true);
    const handleNumberDisable = (val) => {
        setDisableNumberInput(val);
    };
    const getResponseMessage = (res) => {
        return (
            res?.description ||
            res?.error ||
            res?.message ||
            "Something unknown happend here"
        );
    };

    useEffect(() => {
        setDisableOTPSubmit(() => isOtpLoader || otp.length < 4);
    }, [isOtpLoader, otp]);

    useEffect(() => {
        if (!otpResponse) return;

        if (otpResponse && otpResponse["description"] && otpResponse["description"].includes('Pristyn member can not use this option')) {
            logoutFunc()
            toaster('error', otpResponse["description"]);
            return
        }
        setShowSnackBar(true);

        snackBarDebounce.debounce(() => {
            setShowSnackBar(false);
        }, 4000);
    }, [otpResponse]);

    return (


        <Box sx={{ width: "80%", maxWidth: "360px", mx: "auto" }}>
            <Typography
                component={"h1"}
                sx={{
                    color: "black",
                    font: "normal normal 400 40px/60px Poppins",
                    textAlign: "center",
                    mt: "-1rem",
                    // mb: ["1rem"],
                    fontSize: {
                        lg: 24,
                        md: 21,
                        sm: 18,
                        xs: 18,
                    },
                    whiteSpace: "nowrap",
                }}
                className="responsiveHead"
            >
                Sign in Using Mobile Number
            </Typography>

            <div style={{ position: "relative" }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="mobile_number"
                    label="Mobile Number"
                    helperText={isInvaildInput["error"] && isInvaildInput["des"]}
                    name="mobile_number"
                    error={isInvaildInput["error"]}
                    // onInput={handleInputChange}
                    onChange={handleInputChange}
                    autoComplete="mobile_number"
                    autoFocus
                    disabled={disableNumberInput}
                    value={mobileNumber}
                    color="primary"
                    variant="outlined"
                    style={{
                        borderColor: "#3D7CC9",
                        color: "#3D7CC9",
                    }}
                    InputProps={{
                        style: {
                            color: "#3D7CC9",
                            borderColor: "#3D7CC9",
                            outline: "none",
                            borderRadius: "15px",
                        },
                    }}
                />
                <span
                    style={{
                        color: "#FF8300",
                        position: "absolute",
                        top: "2rem",
                        textDecoration: "underline",
                        right: "1rem",
                        display: disableNumberInput == false ? "none" : "block",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        logoutFunc()
                        setDisableNumberInput(false)
                    }}
                >
                    Edit
                </span>
            </div>

            {otpResponse["description"].includes('Pristyn member can not use this option') ? <></> : <>

                <Typography
                    align="left"
                    variant="h5"
                    sx={{
                        mb: 0.5,
                        fontSize: {
                            lg: 24,
                            md: 22,
                            sm: 20,
                            xs: 18,
                        },
                        mt: "3rem",
                    }}
                >
                    Enter OTP
                </Typography>

                <Typography
                    align="left"
                    paragraph
                    sx={{
                        mb: 1,
                        color: "#888",
                        fontSize: {
                            lg: 18,
                            md: 18,
                            sm: 16,
                            xs: 14,
                        },
                    }}
                >
                    Enter 4 digit code sent to your number
                </Typography>

                <Typography align="center" paragraph color="error" sx={{ m: 0.5 }}>
                    {error.error_description}
                </Typography>

                <Box
                    sx={{
                        maxWidth: "30rem",
                        mx: "auto",
                        mt: "20px",
                    }}
                >
                    <form sx={{"width":"240px"}} className="formInput" action="" onSubmit={handleSubmitOtp}>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            separator={<span style={OtpStyle.span}> - </span>}
                            inputStyle={OtpStyle.input}
                            isInputNum={true}
                            containerStyle={OtpStyle}
                        />
                        <div className="resend_otp">
                            {showSnackBar && (
                                <Typography
                                    sx={{
                                        m: 0,
                                        p: 0,
                                        fontSize: ".8rem",
                                        color:
                                            `${otpResponse?.status}`?.toLowerCase() === "success"
                                                ? "green"
                                                : "red",
                                        display: `${otpResponse?.status}`?.toLowerCase() === "success" ? 'none' : "unset"
                                    }}
                                >
                                    {getResponseMessage(otpResponse)}
                                </Typography>
                            )}
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={disableOTPSubmit}
                            sx={{
                                mt: 1,
                                // background: "#FF8300",
                                // textTransform: "capitalize",
                                fontWeight: "bold",
                            }}
                        >
                            Login {isVerifyLoader && (<CircularProgress size="16px" color="inherit" sx={{ ml: 2 }} />)}
                        </Button>
                        <ResendOTP
                            data={{
                                handleSubmit,
                                isOtpLoader,
                                setDisableNumberInput,
                                handleNumberDisable,
                            }}
                        />
                    </form>
                </Box>
            </>}
        </Box>

    );
};

const ResendOTP = ({ data }) => {
    const { handleSubmit, isOtpLoader, handleNumberDisable } = data;

    const [otpTimerObj, setOtpTimerObj] = useState({
        text: "Resend OTP",
        enable: true,
    });

    const [counter, setCounter] = useState(0);
    const [timerId, setTimerId] = useState(null);
    const [counterLimit, setCounterLimit] = useState(30);
    const [timerOpen, setTimerOpen] = useState(false);

    const handleSubmitLocal = () => {
        handleNumberDisable(true);
        if (otpTimerObj["enable"]) {
            handleSubmit();
            setCounter(0);
        }
    };

    useEffect(() => {
        setOtpTimerObj((prevObj) => {
            return {
                ...prevObj,
                text:
                    counter >= counterLimit
                        ? "Resend OTP"
                        : `Resend OTP after ${counterLimit - counter} sec`,
                enable: counter >= counterLimit,
            };
        });

        if (counter >= counterLimit) {
            timerId && clearInterval(timerId);
            setTimerOpen(false);
        }
    }, [counter]);

    useEffect(() => {
        if (!timerOpen) return;

        const timer = setInterval(() => {
            setCounter((counter) => counter + 1);
        }, 1000);

        setTimerId(timer);

        return () => {
            timer && clearInterval(timer);
        };
    }, [timerOpen]);

    useEffect(() => {
        isOtpLoader && setTimerOpen(true);
    }, [isOtpLoader]);

    useEffect(() => {
        setTimerOpen(true);
    }, []);

    return (
        <Grid container style={{ justifyContent: "center", marginTop: "10px" }}>
            <Typography
                component={"span"}
                onClick={handleSubmitLocal}
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    justifyContent: "flex-end",
                    py: 0,
                    color: "#666A70",
                    opacity: otpTimerObj["enable"] ? "1" : ".5",
                    cursor: otpTimerObj["enable"] ? "pointer" : "not-allowed",
                }}
            >
                {isOtpLoader ? <CircularProgress size="16px" color="inherit" sx={{ ml: 2 }} /> : null}

                <div> <p style={{ margin: 0 }}>{otpTimerObj["text"]}</p></div>
            </Typography>
        </Grid>
    );
};

