export const filterListDisplay = {
    'creationDate': 'Creation Date',
    'startDate': 'Start Date',
    'endDate': 'End Date',
    'visitDate': 'Visiting Date',
    'status': 'Status',
    'bdName': 'BD Names',
    'taskType': 'Task Type',
    'department': 'Department',
    'reporter': 'Reporter Names',
    'city': 'Cities',
    'gm': 'GM/AGM',
    'am': 'Assigned AM'
}