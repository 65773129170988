import windowSize from "./windowSize";
import { filterListDisplay } from "./constants";
import { toast} from "react-toastify";
import getSystemRole from "./user";
import moment from 'moment';

function handleDate(timestamp) {
    if (!timestamp || timestamp=='undefined') {
        return '';
    }
    return moment(timestamp).format("DD-MMM-YY");
    // let d = new Date(timestamp)
    // let date = d.toDateString().split(" ")
    // return (date[2] + "-" + date[1] + "-" + date[3][2] + date[3][3])
}

function generateChatTime(t) {
    let timestamp = new Date(t)
    let time = createTime(timestamp.toTimeString().split(" ")[0].split(":")) + ", " + createDate(timestamp.toDateString().split(" "))
    return (time)
}

function generateAttachTime(t) {
    let timestamp = new Date(t)
    let time = createDate(timestamp.toDateString().split(" ")) + ", " + createTime(timestamp.toTimeString().split(" ")[0].split(":"))
    return (time)
}

const createDate = (dateString) => {
    return (dateString[1] + " " + dateString[2])
}

const createTime = (timeString) => {
    let hr = parseInt(timeString[0])
    if (hr > 12) {
        return ((hr - 12) + ":" + timeString[1] + " PM")
    }
    else {
        return (hr + ":" + timeString[1] + " AM")
    }
}

function validateInput(value, setState) {

    const lengthError = value.length < 10;
    const regexError = Boolean(value.match(/\D/gm));

    setState(() => {
        return {
            error: lengthError || regexError,
            des: regexError
                ? "Only digits are allowed"
                : !value.length
                    ? "Please enter the mobile number"
                    : "Should contain only 10 digits",
        };
    });

    return value.length < 11;
};

class Debounce {
    debounceTimer;
    debounce(cb, delay = 1000) {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(cb, delay);
    }
}

function toaster(type, message) {

    if(!message)return
    switch (type) {
        case 'success':toast.success(message,{className:'mob-tostify'});break;
        case 'warn':toast.warn(message,{className:'mob-tostify'});break;
        case 'error':toast.error(message,{className:'mob-tostify'});break;
        case 'warning':toast.warning(message,{className:'mob-tostify'});break;
        default:toast.error(message,{className:'mob-tostify'})
    }
}

function timeToString(time) {
    if(!time) return
    let myday = new Date(time)  
    myday = myday.toISOString().split('T')[0]
    return myday
}

export {
    windowSize,
    handleDate,
    generateAttachTime,
    generateChatTime,
    validateInput,
    Debounce,
    filterListDisplay,
    toaster,
    timeToString,
    getSystemRole
}