import React, { useEffect, useState } from "react";
import WestIcon from '@mui/icons-material/West';
import { Button, Grid, Box, TextField, MenuItem, CircularProgress, Autocomplete } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { getAllFilterData, editTaskDetails, fetchTaskDetails, getHospitalMapping, getClinicMapping } from "../../../services"
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toaster, getSystemRole } from "../../../utility";
import Loader from "../../common/Loader/loader";
import { CollectionsOutlined } from "@mui/icons-material";


const useStyles = makeStyles({
  taskEditHeading: {
    backgroundColor: '#EAF1F9',
    display: "flex",
    justifyContent: 'space-between',
    // width: '100%',
    height: '3.5rem',
    alignItems: 'center',
    padding: "0px 2%",
    borderRadius: '0px 0px 10px 10px',

  },
  formContainer: {
    padding: '40px',
  },
  forsubContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  forLabelGrid: {
    display: 'flex',
    alignItems: 'center',
  },
  forLabel: {
    color: '#00263E',
    fontSize: '18px',
  },
  forTextField: {
    width: '100%',
    '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
      color: '#00263E',
      height: '10px',
    },
    '& .css-183cnfa-MuiInputBase-root-MuiOutlinedInput-root': {
      color: '#00263E',
      border: '1px solid #3D7CC9',
    },
    '& .css-mgud4i-MuiInputBase-root-MuiOutlinedInput-root': {
      border: '1px solid #3D7CC9',
    },

    '& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
      height: '10px',
      minHeight: '0px',
      display: 'flex',
      alignItems: 'center',
    },
    '& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root': {
      border: '1px solid #3D7CC9',
    },
    '& .makeStyles-forTextField-5 .css-183cnfa-MuiInputBase-root-MuiOutlinedInput-root': {
      border: '1px solid #3D7CC9',
    },
    '& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {
      color: '#3D7CC9',
    },
    '& .css-mhc70k-MuiGrid-root>.MuiGrid-item': {
      alignItems: 'start',
      paddingTop: "26px",
    },

    '&:hover': {
      border: "0px solid #3D7CC9",
    },

  }

});




export default function EditTask(props) {

  const [role, setRole] = useState('')
  const [titleChar, setTitleChar] = useState(160)
  const [descriptionChar, setDescriptionChar] = useState(1000)

  useEffect(() => {
    setRole(getSystemRole())
  }, [])

  let { id } = useParams();
  const classes = useStyles();
  const [updateFlag, setUpdateFlag] = useState(false)
  const [title, setTitle] = useState('')
  const [assignedBD, setAssignedBD] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [selectedReporter, setSelectedReporter] = useState(null);
  const [endDate, setEndDate] = useState('');
  const [selectedGM, setSelectedGM] = useState(null);
  const [proposedStartDate, setProposedStartDate] = useState('');
  const [proposedEndDate, setProposedEndDate] = useState('');
  const [selectedAM, setSelectedAM] = useState(null);
  const [selectedAGM, setSelectedAGM] = useState(null);
  const [taskType, setTaskType] = useState('')
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedHospital, setSelectedHospital] = useState('');
  const [description, setDescription] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [allCities, setAllCities] = useState([])
  const [allGmList, setAllGmList] = useState([])
  const [allAgmList, setAllAgmList] = useState([])
  const [allAmList, setAllAmList] = useState([])
  const [allReporters, setAllReporters] = useState([])
  const [allBdList, setAllBdList] = useState([])
  const [taskNamesList, setTaskNamesList] = useState([])
  const [isButtonLoading, setIsButonLoading] = useState(false)
  const [filteredHospData, setFilteredHospData] = useState([])
  const [filterData, setFilterData] = useState(null);
  const [hospitalData, sethospitalData] = useState(null);
  const [clinicData, setClinicData] = useState(null);
  const [filteredClinicData, setFilteredClinicData] = useState([])
  const [selectedClinic, setSelectedClinic] = useState('');

  useEffect(() => {

    getAllFilterData().then(res => {
      if (res.data.result) {
        let myTagList = []
        res.data.result && res.data.result.amList && res.data.result.amList.map((el) => (
          el.name && el.userId ? myTagList.push({ name: el.name, id: el.userId }) : ''
        ))
        res.data.result && res.data.result.bdList && res.data.result.bdList.map((el) => (
          el.name && el.userId ? myTagList.push({ name: el.name, id: el.userId }) : ''
        ))
        res.data.result && res.data.result.gmList && res.data.result.gmList.map((el) => (
          el.name && el.name ? myTagList.push({ name: el.name, id: el.userId }) : ''
        ))
        res.data.result && res.data.result.reporters && res.data.result.reporters.map((el) => (
          el.reporterName && el.reporterId ? myTagList.push({ name: el.reporterName, id: el.reporterId }) : ''
        ))
        myTagList.sort((a, b) => a.name.localeCompare(b.name))
        res.data.result['allList'] = myTagList

        let myBdList = []
        res.data.result && res.data.result.bdList && res.data.result.bdList.map((el) => (
          el.name && el.userId ? myBdList.push({ name: el.name, userId: el.userId }) : ''
        ))
        myBdList.sort((a, b) => a.name.localeCompare(b.name))
        res.data.result['bdList'] = myBdList

        let myGmList = []
        res.data.result && res.data.result.gmList && res.data.result.gmList.map((el) => (
          el.name && el.userId ? myGmList.push({ name: el.name, userId: el.userId }) : ''
        ))
        myGmList.sort((a, b) => a.name.localeCompare(b.name))
        res.data.result['gmList'] = myGmList

        let myAgmList = []
        res.data.result && res.data.result.agmList && res.data.result.agmList.map((el) => (
          el.name && el.userId ? myAgmList.push({ name: el.name, userId: el.userId }) : ''
        ))
        myAgmList.sort((a, b) => a.name.localeCompare(b.name))
        res.data.result['agmList'] = myAgmList

        let myAmList = []
        res.data.result && res.data.result.amList && res.data.result.amList.map((el) => (
          el.name && el.userId ? myAmList.push({ name: el.name, userId: el.userId }) : ''
        ))
        myAmList.sort((a, b) => a.name.localeCompare(b.name))
        res.data.result['amList'] = myAmList

        let myReporters = []
        res.data.result && res.data.result.reporters && res.data.result.reporters.map((el) => (
          el.reporterName && el.reporterId ? myReporters.push({ reporterName: el.reporterName, reporterId: el.reporterId }) : ''
        ))
        myReporters.sort((a, b) => a.reporterName.localeCompare(b.reporterName))
        res.data.result['reporters'] = myReporters

        setFilterData(res.data.result);
      }
    })
    getHospitalMapping().then(res => {
      if (res.data.result) {
        sethospitalData(res.data.result)
      }
    })
    getClinicMapping().then(res => {
      if (res.data.result) {
        setClinicData(res.data.result)
      }
    })

  }, [])



  useEffect(() => {
    setAllCities(filterData?.citiesList.length ? filterData?.citiesList : [])
    setAllGmList(filterData?.gmList.length ? filterData?.gmList : [])
    setAllAmList(filterData?.amList.length ? filterData?.amList : [])
    setAllReporters(filterData?.reporters.length ? filterData?.reporters : [])
    setAllBdList(filterData?.bdList.length ? filterData?.bdList : [])
    setTaskNamesList(filterData?.taskNamesList.length ? filterData?.taskNamesList : [])
    setAllAgmList(filterData?.agmList.length ? filterData?.agmList : [])
  })

  useEffect(() => {
    if (selectedCity) {
      let hospitalDataTmp
      console.info(selectedCity, hospitalData)
      if (hospitalData) {
        hospitalDataTmp = hospitalData.filter(function (el) {
          return el.city == selectedCity
        });
        setFilteredHospData(hospitalDataTmp)
      }
      let clinicDataTmp
      if (clinicData) {
        clinicDataTmp = clinicData.filter(function (el) {
          return el.city == selectedCity
        });
        setFilteredClinicData(clinicDataTmp)
      }
    }
  }, [selectedCity, hospitalData, clinicData])

  useEffect(() => {

    fetchTaskDetails(id).then(res => {
      if (res.data && res.data.result) {
        let finalData = res.data.result;
        setTitle(finalData.title)
        setTitleChar(160 - finalData.title.length)
        finalData && finalData.startDate && setStartDate(finalData.startDate)
        finalData && finalData.endDate && setEndDate(finalData.endDate)
        setTaskType(finalData.taskType)
        setSelectedCity(finalData.city)
        setFiteredData(finalData.city)
        setSelectedHospital('')
        finalData && finalData.hospId && finalData.hospName && setSelectedHospital({ id: finalData.hospId, label: finalData.hospName })
        setSelectedClinic('')
        finalData && finalData.clinicId && finalData.clinicName && setSelectedClinic({ id: finalData.clinicId, label: finalData.clinicName })
        setDescription(finalData.description)
        setDescriptionChar(1000 - finalData.description.length)
        finalData && finalData.gmName && finalData.gmId && setSelectedGM({ label: finalData.gmName, id: finalData.gmId })
        finalData && finalData.bdName && finalData.bdId && setAssignedBD({ label: finalData.bdName, id: finalData.bdId })
        finalData && finalData.reporterName && finalData.reporterId && setSelectedReporter({ label: finalData.reporterName, id: finalData.reporterId })
        finalData && finalData.amName && finalData.amId && setSelectedAM({ label: finalData.amName, id: finalData.amId })
        finalData && finalData.agmName && finalData.agmId && setSelectedAGM({ label: finalData.agmName, id: finalData.agmId })
        setProposedStartDate(finalData.actualStartDate)
        setProposedEndDate(finalData.actualEndDate)
      }
    })
      .catch(() => { })
      .finally(() => {
        setIsLoading(false)
      })

  }, [])

  const handleChange = (event) => {

    switch (event.target.name) {
      case 'title':
        setTitle(event.target.value)
        break;
      case 'assignedBD':
        setAssignedBD(event.target.value)
        break;
      case 'startDate':
        setStartDate(event.target.value)
        break;
      case 'reporter':
        setSelectedReporter(event.target.value)
        break;

      case 'endDate':
        setEndDate(event.target.value)
        break;
      case 'GM':
        setSelectedGM(event.target.value)
        break;
      case 'proposedStartDate':
        setProposedStartDate(event.target.value)
        break;
      case 'proposedEndDate':
        setProposedEndDate(event.target.value)
        break;
      case 'AM':
        setSelectedAM(event.target.value)
        break;
      case 'city':
        setSelectedCity(event.target.value)
        break;
      case 'Task Type':
        setTaskType(event.target.value)
        break;
      case 'description':
        setDescription(event.target.value)
        break;

      default:
      // code block
    }
    setUpdateFlag(true)
  };

  const handleChangeNew = (event, value, name) => {
    // console.log(event, value, name)
    switch (name) {
      case 'assignedBD':
        setAssignedBD(value)
        break;
      case 'reporter':
        setSelectedReporter(value)
        break;
      case 'GM':
        setSelectedGM(value)
        break;
      case 'AM':
        setSelectedAM(value)
        break;
      case 'AGM':
        setSelectedAGM(value)
        break;
      case 'city':
        setSelectedCity(value)
        setFiteredData(value)
        break;
      case 'Task Type':
        setTaskType(value)
        break;
      case 'hospital':
        setSelectedHospital(value)
        // setFiteredData(selectedCity)
        break;
      case 'clinic':
        setSelectedClinic(value)
        // setFiteredData(selectedCity)
        break;

      default:
      // code block
    }
    setUpdateFlag(true)
  };

  const setFiteredData = (value) => {
    if (hospitalData && hospitalData.length) {
      let hospitalDataTmp = hospitalData.filter(function (el) {
        return el.city == value
      });
      // setSelectedHospital('')
      setFilteredHospData(hospitalDataTmp)
    }
    if (clinicData && clinicData.length) {
      let clinicDataTmp = clinicData.filter(function (el) {
        return el.city == value
      });
      // setSelectedClinic('')
      setFilteredClinicData(clinicDataTmp)
    }
  }

  const validate = (completeData) => {
    let message = ''
    if (!completeData.title) {
      message = 'Please Enter Title'
      return message
    }
    else if (!completeData.description) {
      message = 'Please Enter the Description'
      return message
    }
    else if (!completeData.taskType) {
      message = 'Please Select Task Type'
      return message
    }
    else if (!completeData.city) {
      message = 'Please select the city'
      return message
    }
    // else if (!completeData.hospName) {
    //   message = 'Please select the Hospital'
    //   return message
    // }
    // if(completeData.gmName){

    // }
    // if(completeData.amName){

    // }
    // if(completeData.bdName){

    // }
    // else if (!completeData.startDate) {
    //   message = 'Please select Start Date'
    //   return message
    // }
    // else if (!completeData.endDate) {
    //   message = 'Please select End Date'
    //   return message
    // }
    // if(completeData.actualStartDate){

    // }
    // if(completeData.actualEndDate){

    // }
    // if(completeData.reporterId){

    // }
    // if(completeData.reporterName){

    // }
    return 'no'
  }


  const handleSubmit = () => {

    // "agmId": JSON.parse(assignedBD).userId,
    // "agmName": "Amit Shah",
    let assignedBDTmp = assignedBD
    let selectedReporterTmp = selectedReporter
    let selectedGMTmp = selectedGM
    let selectedAMTmp = selectedAM
    let selectedHospitalTmp = selectedHospital
    let selectedClinicTmp = selectedClinic
    let selectedAGMTmp = selectedAGM

    let completeData = {
      taskId: id,
      title: title,
      description: description,
      taskType: taskType,
      bdId: assignedBDTmp && assignedBDTmp.id ? assignedBDTmp.id : '',
      bdName: assignedBDTmp && assignedBDTmp.label ? assignedBDTmp.label : '',
      reporterId: selectedReporterTmp && selectedReporterTmp.id ? selectedReporterTmp.id : '',
      reporterName: selectedReporterTmp && selectedReporterTmp.label ? selectedReporterTmp.label : '',
      gmId: selectedGMTmp && selectedGMTmp.id ? selectedGMTmp.id : '',
      gmName: selectedGMTmp && selectedGMTmp.label ? selectedGMTmp.label : '',
      amId: selectedAMTmp && selectedAMTmp.id ? selectedAMTmp.id : '',
      amName: selectedAMTmp && selectedAMTmp.label ? selectedAMTmp.label : '',
      agmId: selectedAGMTmp && selectedAGMTmp.id ? selectedAGMTmp.id : '',
      agmName: selectedAGMTmp && selectedAGMTmp.label ? selectedAGMTmp.label : '',
      startDate: startDate,
      endDate: endDate,
      actualStartDate: proposedStartDate,
      actualEndDate: proposedEndDate,
      city: selectedCity,
      hospId: selectedHospitalTmp && selectedHospitalTmp.id ? selectedHospitalTmp.id : '',
      hospName: selectedHospitalTmp && selectedHospitalTmp.label ? selectedHospitalTmp.label : '',
      clinicId: selectedClinicTmp && selectedClinicTmp.id ? selectedClinicTmp.id : '',
      clinicName: selectedClinicTmp && selectedClinicTmp.label ? selectedClinicTmp.label : '',
    }
    let mes = validate(completeData)
    if (mes != 'no') {
      toaster('warning', mes)
    } else {

      setIsButonLoading(true)
      editTaskDetails(completeData).then(response => {
        if (response && response.data && response.data.status === "Success") {
          // toaster('success', response.data.description);
          // setUpdateFlag(false)
          setIsButonLoading(false)
          navigate(`/viewTask/${id}`)
          // routing('/viewTask/' + id)
        }
      })
        .catch(function (error) {
          setIsButonLoading(false)
        });
    }


  }

  const navigate = useNavigate();

  const routing = (path) => {
    navigate(path)
  }

  return (

    <Box className="taskEditComp" sx={{ flexGrow: 1 }}>
      {isLoading && <Loader />}


      <div className="taskEditHeading">
        <WestIcon style={{ 'cursor': 'pointer' }} onClick={() => routing('/viewTask/' + id)} />

        <p>
          Editing : {id}
        </p>
        <Button disabled={isButtonLoading || !updateFlag} variant="contained" onClick={handleSubmit}>Update
          {isButtonLoading && <CircularProgress style={{
            width: '19px',
            height: '19px',
            color: 'black'
          }} />}
        </Button>
      </div>
      <Grid className="taskEditBody" container spacing={2}>
        <Grid item xs={12} md={1.5} className={classes.forLabelGrid}>
          <label className={classes.forLabel}>Title:</label>
        </Grid>
        <Grid item xs={12} md={10.5}>
          <TextField
            required
            id="outlined-required"
            className={classes.forTextField}
            name='title'
            value={title}
            onChange={(event) => {
              handleChange(event)
              setTitleChar(160 - event.target.value.length)
            }}
            inputProps={{ maxLength: 160 }}
          />
          <p className="character-limit">{titleChar} characters</p>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 0
          }} className={classes.forsubContainer}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={{
                xs: 2,
                sm: 2,
                md: 3.5
              }}>
                <Grid item xs={12} md={3.5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Assigned BD:</label>
                </Grid>
                <Grid item xs={12} md={8.5}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allBdList && allBdList.map(bd => { return { id: bd['userId'], label: bd['name'] } })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    name='assignedBD'
                    value={assignedBD}
                    onChange={(event, value) => handleChangeNew(event, value, 'assignedBD')}
                  />
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Start Date:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    // disabled={role != 'GM' && role != 'AGM'}
                    className={classes.forTextField}
                    id="date"
                    name='startDate'
                    type="date"
                    value={startDate}
                    onChange={handleChange}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ max: endDate }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 0
          }} className={classes.forsubContainer}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={{
                xs: 2,
                sm: 2,
                md: 3.5
              }}>
                <Grid item xs={12} md={3.5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Reporter:</label>
                </Grid>
                <Grid item xs={12} md={8.5}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allReporters && allReporters.map(re => { return { id: re['reporterId'], label: re['reporterName'] } })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    name='reporter'
                    value={selectedReporter}
                    onChange={(event, value) => handleChangeNew(event, value, 'reporter')}
                  />
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>End Date:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    // disabled={role != 'GM' && role != 'AGM'}
                    className={classes.forTextField}
                    id="date"
                    type="date"
                    name='endDate'
                    value={endDate}
                    onChange={handleChange}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ min: startDate }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>


        <Grid item xs={12} md={12}>
          <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 0
          }} className={classes.forsubContainer}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={{
                xs: 2,
                sm: 2,
                md: 3.5
              }}>
                <Grid item xs={12} md={3.5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>GM:</label>
                </Grid>
                <Grid item xs={12} md={8.5}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allGmList && allGmList.map(gm => { return { id: gm['userId'], label: gm['name'] } })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    name='GM'
                    value={selectedGM}
                    onChange={(event, value) => handleChangeNew(event, value, 'GM')}
                  />
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Actual Start Date:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    className={classes.forTextField}
                    id="date"
                    type="date"
                    name='proposedStartDate'
                    value={proposedStartDate}
                    onChange={handleChange}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ max: proposedEndDate }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 0
          }} className={classes.forsubContainer}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={{
                xs: 2,
                sm: 2,
                md: 3.5
              }}>
                <Grid item xs={12} md={3.5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>AGM:</label>
                </Grid>
                <Grid item xs={12} md={8.5}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allAgmList && allAgmList.map(re => { return { id: re['userId'], label: re['name'] } })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    name='AGM'
                    value={selectedAGM}
                    onChange={(event, value) => handleChangeNew(event, value, 'AGM')}
                  />
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Actual End Date:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    className={classes.forTextField}
                    id="date"
                    type="date"
                    name='proposedEndDate'
                    value={proposedEndDate}
                    onChange={handleChange}
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ min: proposedStartDate }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 0
          }} className={classes.forsubContainer}>
            <Grid item xs={12} md={5}>
              <Grid container spacing={{
                xs: 2,
                sm: 2,
                md: 3.5
              }}>
                <Grid item xs={12} md={3.5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>AM:</label>
                </Grid>
                <Grid item xs={12} md={8.5}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allAmList && allAmList.map(re => { return { id: re['userId'], label: re['name'] } })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    name='AM'
                    value={selectedAM}
                    onChange={(event, value) => handleChangeNew(event, value, 'AM')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>City:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allCities && allCities.map(city => { return city })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    name='city'
                    value={selectedCity}
                    onChange={(event, value) => handleChangeNew(event, value, 'city')}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 0
          }} className={classes.forsubContainer}>
            <Grid item xs={12} md={7}>
              <Grid container
                spacing={{
                  xs: 2,
                  sm: 2,
                  md: 5
                }} >
                <Grid item xs={12} md={2.5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Task Type:</label>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={taskNamesList && taskNamesList.map(task => { return task })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    name='Task Type'
                    value={taskType}
                    onChange={(event, value) => handleChangeNew(event, value, 'Task Type')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Hospital:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filteredHospData.map(el => { return { id: el['_id'], label: el['hospitalName'] } })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    value={selectedHospital}
                    onChange={(event, value) => handleChangeNew(event, value, "hospital")}
                    name='hospital'
                    disabled={selectedClinic ? true : false}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          <Grid container spacing={{
            xs: 2,
            sm: 2,
            md: 0
          }} className={classes.forsubContainer}>
            <Grid item xs={12} md={7}>
              <Grid container
                spacing={{
                  xs: 2,
                  sm: 2,
                  md: 5
                }} >
              </Grid>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} className={classes.forLabelGrid}>
                  <label className={classes.forLabel}>Clinic:</label>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={filteredClinicData.map(el => { return { id: el['_id'], label: el['hospitalName'] } })}
                    renderInput={(params) => <TextField {...params} />}
                    className="selectLeftColWid"
                    value={selectedClinic}
                    onChange={(event, value) => handleChangeNew(event, value, "clinic")}
                    name='hospital'
                    disabled={selectedHospital ? true : false}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>



        <Grid item xs={12} md={1.5} className={classes.forLabelGrid}>
          <label className={classes.forLabel}>Description:</label>
        </Grid>
        <Grid item xs={12} md={10.5}>
          <TextField

            required
            id="outlined-required"
            multiline
            rows={4}
            className={classes.forTextField}
            name='description'
            value={description}
            onChange={(event) => {
              handleChange(event)
              setDescriptionChar(1000 - event.target.value.length)
            }}
            inputProps={{ maxLength: 1000 }}
          />
          <p className="character-limit">{descriptionChar} characters</p>
        </Grid>

      </Grid>
    </Box>
  )
}