import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, MenuItem, Tooltip, FormControl, InputLabel, ClickAwayListener } from "@mui/material";
import { DatePkr } from "../index";
import Select from '@mui/material/Select';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { windowSize, filterListDisplay, toaster, timeToString } from "../../../utility";
import closeFilter from "../../../assets/closeFilter.svg";
import { downloadTasksListCSVFile, getSignedUrl } from "../../../services";
import { DateRangePicker } from "react-date-range";
import moment from "moment";

export default function FilterArea({ filterCallBack, filtered, removeFilterCallBack, mobileFiltered, setMobileFiltered, desktopFilter, dateRangeSelected, setDateRangeSelection }) {
    // const initialDate = new Date()
    const [key, setKey] = useState([])
    const { isMobile } = windowSize();
    const [visitDateFlag, setVisitDateFlag] = useState(false)
    const [dateFlag, setDateFlag] = useState({ visitDate: false, startDate: false, endDate: false })
    const [startRange, setStartRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    const [visitRange, setVisitRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    const [endRange, setEndRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])
    // const [filterArray,setFilterArray] = useState({'visitDate':[initialDate],'startDate':[initialDate],"endDate":[initialDate]})
// console.log("visit garima ",mobileFiltered)
    useEffect(() => {
        let mobileFilteredTmp = JSON.parse(localStorage.getItem('mobileFiltered'))
        if (mobileFilteredTmp) {
            setMobileFiltered(mobileFilteredTmp)
        }
        let dateRangeSelectedTmp = JSON.parse(localStorage.getItem('dateRangeSelected'))
        if (dateRangeSelectedTmp) {
            setDateRangeSelection(dateRangeSelectedTmp)
        }
        let visitDateFlagTmp = JSON.parse(localStorage.getItem('visitDateFlag'))
        if (visitDateFlagTmp) {
            setVisitDateFlag(visitDateFlagTmp)
            // clearDateRangeForRefresh('visitDate', setVisitRange, 'visitDateFrom', 'visitDateTo')
            let obj = {
                'startDate': new Date(),
                'endDate': new Date(),
                'key': 'selection'
            }
            setVisitRange([obj])
            setMobileFiltered1(mobileFilteredTmp)
            setDateRangeSelection1(dateRangeSelectedTmp)
            setDateFlag(dateFlag)
        }
    }, [])
   

    const setMobileFiltered1 = (event) => {
        
        setMobileFiltered(event)
        if (event) {
            localStorage.setItem('mobileFiltered', JSON.stringify(event))
        }
    }
    const setDateRangeSelection1 = (event) => {
        
        setDateRangeSelection(event)
        if (event) {
            localStorage.setItem('dateRangeSelected', JSON.stringify(event))
        }
    }

    // useEffect(() => {
    //     if (mobileFiltered) {
    //         localStorage.setItem('mobileFiltered', JSON.stringify(mobileFiltered))
    //     }
    // }, [mobileFiltered])

    useEffect(() => {
        setKey(Object.keys(filtered))
    }, [filtered])

    useEffect(() => {
        ({ ...mobileFiltered, visitDateFlag: visitDateFlag })
    }, [visitDateFlag])

    const navigate = useNavigate();

    const routing = (path) => {
        navigate(path)
    }

    const handleStatus = (event) => {
        if (mobileFiltered['status']) {
            let something = { ...mobileFiltered }
            something['status'] = event.target.value
            setMobileFiltered1(something)
        }
        else {
            setMobileFiltered1({ ...mobileFiltered, "status": event.target.value })
        }
    }

    const handleDate = (name, dateValue) => {

        let m = dateValue['$M'] + 1
        let d = dateValue['$D']
        if (m < 10) {
            m = '0' + m
        }
        if (d < 10) {
            d = '0' + d
        }
        let date = {
            [name]: dateValue['$y'] + '-' + m + '-' + d
        }

        setMobileFiltered1({ ...mobileFiltered, ...date })
    }

    const downloadData = () => {
        let tmpData = desktopFilter
        delete tmpData.page
        downloadTasksListCSVFile(tmpData).then(res => {
            if (res.data.status === "Success") {
                toaster('success', res.data.description)
                var data = { "urls": [res.data.result], "isDownload": false };
                getSignedUrl(data).then(el => {
                    window.open(el.data.result, '_blank');
                })
            } else {
                toaster('error', res.data.description)
            }
        })
    }

    const handleDateFormat = (d) => {
        // return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
        return moment(d).format('YYYY-MM-DD')
    }

    const handleDateRange = (name, currentDate, setDateRange) => {
        let startDate = handleDateFormat(currentDate.startDate)
        let endDate = handleDateFormat(currentDate.endDate)
        let obj = {
            'startDate': startDate,
            'endDate': endDate,
            'key': 'selection'
        }
        setDateRange([currentDate])
    }

    const clearDateRange = (name, setDateRange, dateFrom, dateTo) => {
        
        let obj = {
            'startDate': new Date(),
            'endDate': new Date(),
            'key': 'selection'
        }
        setDateRange([obj])
        setMobileFiltered1({ ...mobileFiltered, [dateFrom]: '', [dateTo]: '' })
        setDateRangeSelection1({ ...dateRangeSelected, [name]: false })
        setDateFlag({ ...dateFlag, [name]: false })
    }

    const confirmDateRange = (name, dateRange, dateFrom, dateTo) => {

        dateRange = dateRange[0]
        let startDate = moment(dateRange.startDate).format('YYYY-MM-DD')
        // `${dateRange.startDate.getFullYear()}-${dateRange.startDate.getMonth() + 1}-${dateRange.startDate.getDate()}`
        let endDate = moment(dateRange.endDate).format('YYYY-MM-DD')
        //`${dateRange.endDate.getFullYear()}-${dateRange.endDate.getMonth() + 1}-${dateRange.endDate.getDate()}`

        setMobileFiltered1({ ...mobileFiltered, [dateFrom]: startDate, [dateTo]: endDate })
        setDateRangeSelection1({ ...dateRangeSelected, [name]: true })
        setDateFlag({ ...dateFlag, [name]: false })
    }

    const handleChange = (event) => {
        
        setVisitDateFlag(!visitDateFlag)
        localStorage.setItem('visitDateFlag', !visitDateFlag)
        clearDateRange('visitDate', setVisitRange, 'visitDateFrom', 'visitDateTo')
    }

    const filterViewButton = (name) => {
        return (
            <>
                {filtered[name].length > 0 && name != 'startDate' && name != 'endDate' && name != 'visitDate' && name != 'status' && name != 'taskType' && name != 'department' && name != 'city' && name != 'creationDate' &&
                    <>
                        <Button key={name} variant="outlined" sx={{ width: '160px', marginRight: '10px', backgroundColor: '#F7F7F7', 'cursor': 'default!important', position: 'relative', marginTop: '15px' }}>
                            <span className="filterListDisplay">{filterListDisplay[name]}</span>
                            <Grid container>
                                <Grid item xs={8} className="filterWrap" sx={{ textAlign: "left" }}>
                                    <Tooltip title={filtered[name].map(item => item.value).toString()} arrow>
                                        <Button style={{ padding: '0' }}>
                                            {filtered[name][0]['value']}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid xs={2}>
                                    {filtered[name].length > 1 && `+${filtered[name].length - 1}`}
                                </Grid>
                                <Grid onClick={() => removeFilterCallBack(name)} item xs={2} sx={{ cursor: "pointer" }}>
                                    <img src={closeFilter} height="10px" width="10px" />
                                </Grid>
                            </Grid>
                        </Button>
                    </>
                }
                {filtered[name].length > 0 && (name == 'status' || name == 'taskType' || name == 'department' || name == 'city') &&
                    <>
                        <Button key={name} variant="outlined" sx={{ width: '160px', marginRight: '10px', backgroundColor: '#F7F7F7', 'cursor': 'default!important', position: 'relative', marginTop: '15px' }}>
                            <span className="filterListDisplay">{filterListDisplay[name]}</span>
                            <Grid container>
                                <Grid item xs={8} className="filterWrap" sx={{ textAlign: "left" }}>
                                    <Tooltip title={filtered[name].map(item => item).toString()} arrow>
                                        <Button style={{ padding: '0' }}>
                                            {filtered[name][0]}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid xs={2}>
                                    {filtered[name].length > 1 && `+${filtered[name].length - 1}`}
                                </Grid>
                                <Grid onClick={() => removeFilterCallBack(name)} item xs={2} sx={{ cursor: "pointer" }}>
                                    <img src={closeFilter} height="10px" width="10px" />
                                </Grid>
                            </Grid>
                        </Button>
                    </>
                }
                {filtered[name].length > 0 && (name == 'startDate' || name == 'endDate' || name == 'visitDate') &&
                    <>
                        <Button key={name} variant="outlined" sx={{ width: '160px', marginRight: '10px', backgroundColor: '#F7F7F7', 'cursor': 'default!important', position: 'relative', marginTop: '15px' }}>
                            <span className="filterListDisplay">{filterListDisplay[name]}</span>

                            <Grid container>
                                <Grid item xs={10} className="filterWrap" sx={{ textAlign: "left" }}>
                                    <Tooltip title={filtered[name][0]['startDate'] + " - " + filtered[name][0]['endDate']} arrow>
                                        <Button style={{ padding: '0' }}>
                                            {filtered[name][0]['startDate']} - {filtered[name][0]['endDate']}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid onClick={() => removeFilterCallBack(name)} item xs={2} sx={{ cursor: "pointer" }}>
                                    <img src={closeFilter} height="10px" width="10px" />
                                </Grid>
                            </Grid>
                        </Button>
                    </>
                }
                {filtered[name].length > 0 && (name == 'creationDate') &&
                    <>
                        <Button key={name} variant="outlined" sx={{ width: '160px', marginRight: '10px', backgroundColor: '#F7F7F7', 'cursor': 'default!important', marginTop: '15px' }}>
                            <span className="filterListDisplay">{filterListDisplay[name]}</span>

                            <Grid container>
                                <Grid item xs={10} className="filterWrap" sx={{ textAlign: "left" }}>
                                    <Tooltip title={filtered[name][0]} arrow>
                                        <Button style={{ padding: '0' }}>
                                            {filtered[name][0]}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                <Grid onClick={() => removeFilterCallBack(name)} item xs={2} sx={{ cursor: "pointer" }}>
                                    <img src={closeFilter} height="10px" width="10px" />
                                </Grid>
                            </Grid>
                        </Button>
                    </>
                }
            </>
        )
    }

    return (
        <>
            {!(isMobile) &&
                <Grid container className="filterTab" spacing={2} sx={{ marginTop: '10px', textAlign: 'center', marginBottom: '10px' }}>
                    <Grid item xs={2}>
                        <Button onClick={() => filterCallBack()} variant="outlined" sx={{ width: '160px' }}>Filter</Button>
                    </Grid>
                    <Grid item xs={5}>
                        <div className="horizontalScroll">
                            {key.length > 0 && key.map(name => (
                                filterViewButton(name)
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={1.5} sx={{ textAlign: 'right' }}>
                        <Button onClick={() => removeFilterCallBack("All")} variant="contained">Clear Filter</Button>
                    </Grid>
                    <Grid item xs={1.5} sx={{ textAlign: 'right' }}>
                        <Button onClick={() => routing('/createTask')} variant="contained">Create Task</Button>
                    </Grid>
                    <Grid item xs={2} sx={{ textAlign: 'left' }}>
                        <Button onClick={() => downloadData()} variant="contained">Download</Button>
                    </Grid>
                </Grid>
            }
            {isMobile &&
                <Grid container sx={{ marginTop: '10px' }}>
                    <Grid item xs={12}>
                        <Typography className="filterHead">
                            Filter By -
                        </Typography>
                    </Grid>
                    <Grid item xs={6} style={{ display: 'block', paddingLeft: '4%' }} className="dateContainer dateContainerLeft">
                        {/* <DatePkr filterArray={mobileFiltered} handleDate={handleDate} name={"Visit Date"} value={"visitDate"} /> */}
                        <div>
                            <button className="datePkr-btn datePkr" onClick={() => { setDateFlag({ startDate: false, endDate: false, visitDate: !dateFlag.visitDate }) }} disabled={visitDateFlag}>
                                {dateRangeSelected.visitDate ? <p Tooltip={`${mobileFiltered.visitDateFrom} to ${mobileFiltered.visitDateTo}`} style={{ color: 'grey' }}> {mobileFiltered.visitDateFrom} ... </p> : <p> Visit Date </p>}
                                <CalendarMonthIcon style={{ color:' #3D7CC9'}} />
                            </button>
                            {dateFlag.visitDate &&
                                <div className="mobileLeftDateRange">
                                    <DateRangePicker
                                        ranges={visitRange}
                                        showDateDisplay={false}
                                        onChange={item => handleDateRange('visitDate', item.selection, setVisitRange)}
                                        dateDisplayFormat={`dd-mmmm-yyy`}
                                        showMonthArrow={false}
                                        className="patientFilterDatePicker"
                                    />
                                    <div className="mobileDateRangeBtn">
                                        {dateRangeSelected.visitDate && <button onClick={() => clearDateRange('visitDate', setVisitRange, 'visitDateFrom', 'visitDateTo')}> Clear </button>}
                                        <button onClick={() => confirmDateRange('visitDate', visitRange, 'visitDateFrom', 'visitDateTo')}> Confirm </button>
                                    </div>
                                </div>

                            }
                        </div>
                        <div style={{ marginTop: '5px',display:'flex',alignItems:'baseline',gap:'5px'}}>
                            <input type='checkbox' checked={visitDateFlag} name='visitDateFlag' value={visitDateFlag} onChange={() => handleChange()} />
                            <label>No Visit Date{visitDateFlag}</label>
                        </div>
                    </Grid>
                    <Grid item xs={6} className="dateContainer dateContainerRight">
                        <FormControl className='input-field datePkr forstatusSelector'>
                            <InputLabel id="demo-simple-select-helper-label" style={{ color: "#3c7cc9"}}>Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={mobileFiltered.status}
                                label="Status"
                                onChange={handleStatus}
                                className="selectLeftColWidSelector"
                                style={{ width: "100%"}}
                            >
                                <MenuItem value='All' name='status'>All</MenuItem>
                                <MenuItem value='To Do' name='status'>To Do</MenuItem>
                                <MenuItem value='In Progress' name='status'>In Progress</MenuItem>
                                <MenuItem value='On Hold' name='status'>On Hold</MenuItem>
                                <MenuItem value='Done' name='status'>Done</MenuItem>
                                <MenuItem value='Invalid' name='status'>Invalid</MenuItem>
                            </Select>

                        </FormControl>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: '4%' }} className="dateContainer dateContainerLeft">
                        {/* <DatePkr filterArray={mobileFiltered} handleDate={handleDate} name={"Start Date"} value={'startDate'} /> */}
                        <button className="datePkr-btn datePkr" onClick={() => { setDateFlag({ endDate: false, visitDate: false, startDate: !dateFlag.startDate }) }}>
                            {dateRangeSelected.startDate ? <p Tooltip={`${mobileFiltered.startDateFrom} to ${mobileFiltered.startDateTo}`} style={{ color: 'grey' }}> {mobileFiltered.startDateFrom} ...</p> : <p> Start Date </p>}
                            <CalendarMonthIcon style={{ color:' #3D7CC9'}} />
                        </button>
                        {dateFlag.startDate &&
                            <div className="mobileLeftDateRange">
                                <DateRangePicker
                                    ranges={startRange}
                                    showDateDisplay={false}
                                    onChange={item => handleDateRange('startDate', item.selection, setStartRange)}
                                    dateDisplayFormat={`dd-mmmm-yyy`}
                                    showMonthArrow={false}
                                    className="patientFilterDatePicker"
                                    label='Date'
                                />
                                <div className="mobileDateRangeBtn">
                                    {dateRangeSelected.startDate && <button onClick={() => clearDateRange('startDate', setStartRange, 'startDateFrom', 'startDateTo')}> Clear </button>}
                                    <button onClick={() => confirmDateRange('startDate', startRange, 'startDateFrom', 'startDateTo')}> Confirm </button>
                                </div>
                            </div>

                        }

                    </Grid>
                    <Grid item xs={6} className="dateContainer dateContainerRight">
                        {/* <DatePkr filterArray={mobileFiltered} handleDate={handleDate} name={"End Date"} value={'endDate'} /> */}
                        <button style={{ width: '100%' }} className="datePkr-btn datePkr" onClick={() => { setDateFlag({ startDate: false, visitDate: false, endDate: !dateFlag.endDate }) }}>
                            {dateRangeSelected.endDate ? <p Tooltip={`${mobileFiltered.endDateFrom} to ${mobileFiltered.endDateTo}`} style={{ color: 'grey' }}> {mobileFiltered.endDateFrom} ... </p> : <p> End Date </p>}
                            <CalendarMonthIcon style={{ color:' #3D7CC9'}} />
                        </button>
                        {dateFlag.endDate &&
                            <div className="mobileRightDateRange">
                                <DateRangePicker
                                    ranges={endRange}
                                    showDateDisplay={false}
                                    onChange={item => handleDateRange('endDate', item.selection, setEndRange)}
                                    dateDisplayFormat={`dd-mmmm-yyy`}
                                    showMonthArrow={false}
                                    className="patientFilterDatePicker"
                                />
                                <div className="mobileDateRangeBtn">
                                    {dateRangeSelected.endDate && <button onClick={() => clearDateRange('endDate', setEndRange, 'endDateFrom', 'endDateTo')}> Clear </button>}
                                    <button onClick={() => confirmDateRange('endDate', endRange, 'endDateFrom', 'endDateTo')}> Confirm </button>
                                </div>
                            </div>

                        }
                    </Grid>
                    <Grid item xs={11} sx={{ textAlign: 'right',"marginTop":"10px" }}>
                        <Button onClick={() =>{ 
                            setVisitDateFlag(false)
                            removeFilterCallBack("Mobile")}} variant="contained">Clear Filter</Button>
                    </Grid>
                </Grid>
            }
        </>
    )
}