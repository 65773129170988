import React, { useEffect, useState } from 'react'
import { DateRangePicker } from "react-date-range";
import CancelIcon from '@mui/icons-material/Cancel';
import "./index.css";

export default function SecondSidebar({ filterTitle, activeItem, filterData, filterPassBack, filtered, setSidebar, setBar }) {
    const [activeStatus, setActiveStatus] = useState('')
    const [creationDate, setCreationDate] = useState(null)
    const [filterArray, setFilterArray] = useState({})
    const [startDate, setStartDate] = useState(null)

    const [creationRange, setCreationRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    const [taskRange, setTaskRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    const [visitRange, setVisitRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    const [startRange, setStartRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    const [endRange, setEndRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }])

    useEffect(() => {
        setFilterArray({ ...filtered })
        if (filtered['status'] && activeItem === 'status') {
            setActiveStatus(filtered['status'][0])
        }
        else if (filtered['visitDate'] && activeItem === 'visitDate') {
            setVisitRange([{
                startDate: filtered['visitDate'][0] ? new Date(filtered['visitDate'][0].startDate) : new Date(),
                endDate: filtered['visitDate'][0] ? new Date(filtered['visitDate'][0].endDate) : new Date(),
                key: 'selection'
            }])
        }
        else if (filtered['creationDate'] && activeItem === 'creationDate') {
            setCreationDate({ ...creationRange, startDate: new Date(filtered.creationDate[0]), endDate: new Date(filtered.creationDate[0]) })
        }
        else if (filtered['startDate'] && activeItem === 'startDate') {
            setStartRange([{
                startDate: filtered['startDate'][0] ? new Date(filtered['startDate'][0].startDate) : new Date(),
                endDate: filtered['startDate'][0] ? new Date(filtered['startDate'][0].endDate) : new Date(),
                key: 'selection'
            }])
        }
        else if (filtered['endDate'] && activeItem === 'endDate') {
            setEndRange([{
                startDate: filtered['endDate'][0] ? new Date(filtered['endDate'][0].startDate) : new Date(),
                endDate: filtered['endDate'][0] ? new Date(filtered['endDate'][0].endDate) : new Date(),
                key: 'selection'
            }])

        }
    }, [activeItem, filtered])

    const handleStatus = (value) => {
        setActiveStatus(value)
        if (filterArray['status']) {
            let something = { ...filterArray }
            something['status'] = [value]
            setFilterArray(something)
        }
        else {
            setFilterArray({ ...filterArray, status: [value] })
        }
    }

    const handleDateRange = (currentDateRange, currentDate, prevDate, setDate, setDateRange) => {
        if (currentDate.startDate !== prevDate) {
            setDate(currentDate.startDate)
            currentDateRange[0].startDate = currentDate.startDate
            currentDateRange[0].endDate = currentDate.startDate
        }
        else if (currentDate.endDate !== prevDate) {
            setDate(currentDate.endDate)
            currentDateRange[0].startDate = currentDate.endDate
            currentDateRange[0].endDate = currentDate.endDate
        }
        setDateRange(currentDateRange)
        let date = new Date(currentDateRange[0].startDate)
        date.setDate(date.getDate() + 1)
        date = date.toISOString().split('T')[0]
        if (filterArray[activeItem]) {
            let something = { ...filterArray }
            something[activeItem] = [date]
            setFilterArray(something)
        }
        else {
            setFilterArray({ ...filterArray, [activeItem]: [date] })
        }
    }

    const handleDate = (currentDate, setDateRange) => {
        let startDate = new Date(currentDate.startDate)
        startDate.setDate(startDate.getDate() + 1)
        startDate = startDate.toISOString().split('T')[0]

        let endDate = new Date(currentDate.endDate)
        endDate.setDate(endDate.getDate() + 1)
        endDate = endDate.toISOString().split('T')[0]
        let obj = {
            'startDate': startDate,
            'endDate': endDate,
            'key': 'selection'
        }
        setDateRange([currentDate])
        setFilterArray({ ...filterArray, [activeItem]: [obj] })
    }

    const handleChange = (value, type) => {
        handleFilterArray(value, type)
    }

    const handleFilterArray = (value, type) => {
        let found = false
        let arr = { ...filterArray }

        if (arr[type]) {
            let index
            if (type === 'bdName' || type === 'am' || type === 'gm' || type === 'reporter') {
                index = arr[type].map(el => el.userId).indexOf(value.userId)
            }
            else {
                index = arr[type].indexOf(value)
            }
            if (index > -1) {
                arr[type].splice(index, 1)
            }
            else {
                arr[type].push(value)
            }
            found = true
        }

        if (found === false) {
            arr[type] = [value]
        }
        setFilterArray(arr)
    }

    const handleSubmit = (value) => {
        filterPassBack(value);
        setSidebar(false)
    }

    const cancelFilter = () => {
        setBar(false)
    }

    return (
        <div className='secondSidebarContainer'>
            <h4 className='second-sidebar-title'>
                {filterTitle}
                <CancelIcon onClick={() => cancelFilter()} className='cancelFilter' />
            </h4>
            {
                activeItem === 'creationDate' ?
                    <DateRangePicker
                        ranges={creationRange}
                        showDateDisplay={false}
                        onChange={item => handleDateRange(creationRange, item.selection, creationDate, setCreationDate, setCreationRange)}
                        dateDisplayFormat={`dd-mmmm-yyy`}
                        showMonthArrow={false}
                        className="patientFilterDatePicker"
                    /> :
                    // activeItem === 'taskDate' ?
                    //     <DateRangePicker
                    //         ranges={taskRange}
                    //         showDateDisplay={false}
                    //         // onChange={item => handleDate(startRange, item.selection, startDate, setStartDate, setStartRange)}
                    //         onChange={item => handleDate(item.selection,setTaskRange)}
                    //         dateDisplayFormat={`dd-mmmm-yyy`}
                    //         showMonthArrow={false}
                    //         className="patientFilterDatePicker"
                    //     /> :
                    activeItem === 'startDate' ?
                        <DateRangePicker
                            ranges={startRange}
                            showDateDisplay={false}
                            // onChange={item => handleDateRange(startRange, item.selection, startDate, setStartDate, setStartRange)}
                            onChange={item => handleDate(item.selection, setStartRange)}
                            // onChange={item => handleDate(item.selection,setTaskRange)}
                            dateDisplayFormat={`dd-mmmm-yyy`}
                            showMonthArrow={false}
                            className="patientFilterDatePicker"
                        /> :
                        activeItem === 'endDate' ?
                            <DateRangePicker
                                ranges={endRange}
                                showDateDisplay={false}
                                onChange={item => handleDate(item.selection, setEndRange)}
                                // onChange={item => handleDate(item.selection,setTaskRange)}
                                dateDisplayFormat={`dd-mmmm-yyy`}
                                showMonthArrow={false}
                                className="patientFilterDatePicker"
                            /> :
                            activeItem === 'visitDate' ?
                                <DateRangePicker
                                    ranges={visitRange}
                                    showDateDisplay={false}
                                    onChange={item => handleDate(item.selection, setVisitRange)}
                                    dateDisplayFormat={`dd-mmmm-yyy`}
                                    showMonthArrow={false}
                                    className="patientFilterDatePicker"
                                /> :
                                activeItem === 'status' ?
                                    <>
                                        <div className={'statusItem ' + (activeStatus === 'To Do' ? ' active-filter' : '')}><p id='To Do' onClick={() => handleStatus('To Do')}>To Do</p></div>
                                        <div className={'statusItem ' + (activeStatus === 'In Progress' ? ' active-filter' : '')}><p id='In Progress' onClick={() => handleStatus('In Progress')}>In Progress</p></div>
                                        <div className={'statusItem ' + (activeStatus === 'On Hold' ? ' active-filter' : '')}><p id='On Hold' onClick={() => handleStatus('On Hold')} >On Hold</p></div>
                                        <div className={'statusItem ' + (activeStatus === 'Done' ? ' active-filter' : '')}><p id='Done' onClick={() => handleStatus('Done')}>Done</p></div>
                                        <div className={'statusItem ' + (activeStatus === 'All' ? ' active-filter' : '')}><p id='All' onClick={() => handleStatus('All')}>All</p></div>
                                        <div className={'statusItem ' + (activeStatus === 'Invalid' ? ' active-filter' : '')}><p id='Invalid' onClick={() => handleStatus('invalid')}>Invalid</p></div>
                                    </> :
                                    activeItem === 'bdName' ?
                                        <div className='bdContainer'>
                                            <form>
                                                {filterData.bdList.map(bd => {
                                                    return (
                                                        bd.name !== '' &&
                                                        <div key={bd.userId}>
                                                            <input
                                                                checked={filterArray['bdName'] && filterArray['bdName'].map(el => el.userId)?.includes(bd.userId)}
                                                                onChange={(event) => handleChange({ userId: bd.userId, value: bd.name }, 'bdName')} type='checkbox' id={bd.userId} value={bd.name} />
                                                            <label> {bd.name} </label>
                                                        </div>
                                                    )
                                                })}
                                            </form>
                                        </div> :
                                        activeItem === 'taskType' ?
                                            <div className='bdContainer'>
                                                <form>
                                                    {filterData.taskNamesList.map(task => {
                                                        return (
                                                            task !== '' &&
                                                            <div key={task}>
                                                                <input
                                                                    checked={filtered && filtered['taskType']?.includes(task)}
                                                                    onChange={(event) => handleChange(event.target.value, 'taskType')} type='checkbox' id={task} name='' value={task} />
                                                                <label> {task} </label>
                                                            </div>
                                                        )

                                                    })}
                                                </form></div> :
                                            activeItem === 'department' ?
                                                <div className='bdContainer'>
                                                    <form>
                                                        {filterData.departmentNames.map(dep => {
                                                            return (
                                                                dep !== '' &&
                                                                <div key={dep}>
                                                                    <input checked={filtered && filtered['department']?.includes(dep)} onChange={(event) => handleChange(event.target.value, 'department')} type='checkbox' id={dep} name='' value={dep} />
                                                                    <label> {dep} </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </form></div> :
                                                activeItem === 'reporter' ?
                                                    <div className='bdContainer'>
                                                        <form>
                                                            {filterData.reporters.map(rep => {
                                                                return (
                                                                    rep.reporterName !== '' &&
                                                                    <div key={rep.reporterId}>
                                                                        <input
                                                                            checked={filterArray['reporter'] && filterArray['reporter'].map(el => el.userId)?.includes(rep.reporterId)}
                                                                            onChange={(event) => handleChange({ userId: rep.reporterId, value: rep.reporterName }, 'reporter')} type='checkbox' id={rep.reporterId} name='' value={rep.reporterName} />
                                                                        <label> {rep.reporterName} </label>
                                                                    </div>
                                                                )
                                                            })}
                                                        </form></div> :
                                                    activeItem === 'city' ?
                                                        <div className='bdContainer'>
                                                            <form>
                                                                {filterData.citiesList.map(city => {
                                                                    return (
                                                                        city !== '' &&
                                                                        <div key={city}>
                                                                            <input checked={filtered && filtered['city']?.includes(city)} onChange={(event) => handleChange(event.target.value, 'city')} type='checkbox' id={city} name='' value={city} />
                                                                            <label> {city} </label>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </form></div> :
                                                        activeItem === 'gm' ?
                                                            <div className='bdContainer'>
                                                                <form>
                                                                    {filterData.gmList.map(gm => {
                                                                        return (
                                                                            gm.name !== '' &&
                                                                            <div key={gm.userId}>
                                                                                <input
                                                                                    checked={filterArray['gm'] && filterArray['gm'].map(el => el.userId)?.includes(gm.userId)}
                                                                                    onChange={(event) => handleChange({ userId: gm.userId, value: gm.name }, 'gm')} type='checkbox' id={gm.userId} value={gm.name} />
                                                                                <label> {gm.name} </label>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </form></div> :
                                                            activeItem === 'am' ?
                                                                <div className='bdContainer'>
                                                                    <form>
                                                                        {filterData.amList.map(am => {
                                                                            return (
                                                                                am.name !== '' &&
                                                                                <div key={am.userId}>
                                                                                    <input
                                                                                        checked={filterArray['am'] && filterArray['am'].map(el => el.userId)?.includes(am.userId)}
                                                                                        onChange={(event) => handleChange({ userId: am.userId, value: am.name }, 'am')} type='checkbox' id={am.userId} name='' value={am.name} />
                                                                                    <label> {am.name} </label>
                                                                                </div>
                                                                            )

                                                                        })}
                                                                    </form></div> :
                                                                ""
            }
            <button className='confirmBtn' type='submit' onClick={() => handleSubmit(filterArray)}>Confirm</button>
        </div>
    )
}