import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./index.css";
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Button,
  Autocomplete,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { DropImage } from "../../common";
import { useNavigate } from "react-router-dom";
import { createTask } from "../../../services";
import { toaster, getSystemRole } from "../../../utility";
import Loader from "../../common/Loader/loader";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';



const BdTaskCreate = ({ filterData, hospitalData, clinicData }) => {
  const [role, setRole] = useState("");
  const [titleChar, setTitleChar] = useState(160);
  const [descriptionChar, setDescriptionChar] = useState(1000);

  useEffect(() => {
    let systemRole = localStorage.getItem('roles')
    systemRole = JSON.parse(systemRole)
    if (systemRole) {
        if (systemRole['role'] == 'cityOps' && (systemRole['otherRoles'] && systemRole['otherRoles'].length && systemRole['otherRoles'].includes("cityOps"))) {
            setRole("cityOps")
        }
        else{
            setRole(systemRole['role'])
        }
    }
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  const [completeData, setCompleteData] = useState(null);
  const [files, setFiles] = useState([]);
  const [imageArray, setImages] = useState([]);
  const [AllData, setAllData] = useState({
    title: "",
    taskType: "",
    description: "",
    city: "",
    priority: "",
    agmId: "",
    agmName: "",
    gmId: "",
    gmName: "",
    startDate: "",
    endDate: "",
    bdId: "",
    hospId: "",
    hospName: "",
    clinicId: "",
    clinicName: "",
    visitDate: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [isButtonLoading, setIsButonLoading] = useState(false);
  const [filteredHospData, setFilteredHospData] = useState([]);
  const [filteredClinicData, setFilteredClinicData] = useState([]);
  const [selectedVal, setSelectedVal] = React.useState("clinic");
  useEffect(() => {
    
    if (filterData) {
      
      setCompleteData(filterData);
      let bdCityData =localStorage.getItem('OpsCity')
      if(bdCityData && bdCityData.length>0){
      bdCityData=bdCityData.split(',')
      
        setCompleteData({...filterData,citiesList:[...bdCityData]});
      }
      setIsLoading(false);
    }
  }, [filterData]);

  useEffect(() => {
    toaster("warning", errorMessage);
    setErrorMessage("");
  }, [errorMessage]);

  const handleChangeHosp = (event, value) => {
    if (value == null) {
      setAllData({ ...AllData, hospId: "", hospName: "" });
    } else {
      setAllData({ ...AllData, hospId: value.id, hospName: value.label });
    }
  };

  const handleChangeClinic = (event, value) => {
    if (value == null) {
      setAllData({ ...AllData, clinicId: "", clinicName: "" });
    } else {
      setAllData({ ...AllData, clinicId: value.id, clinicName: value.label });
    }
  };

  const handleChange = (event) => {
    setAllData({ ...AllData, [event.target.name]: event.target.value });
  };

  const handleTask = (event, value) => {
    setAllData({ ...AllData, taskType: value });
  };

  // const handleGM = (event, value) => {
  //     setAllData({ ...AllData, gmName: value.label, gmId: value.id })
  // }

  // const handleAGM = (event, value) => {
  //     setAllData({ ...AllData, agmName: value.label, agmId: value.id })
  // }

  // const handleBD = (event, value) => {
  //     setAllData({ ...AllData, bdName: value.label, bdId: value.id })
  // }

  const handleCity = (event, value) => {
    if (hospitalData && hospitalData.length) {
      let hospitalDataTmp = hospitalData.filter(function (el) {
        return el.city == value;
      });
      console.log(hospitalDataTmp);
      setFilteredHospData(hospitalDataTmp);
    }
    if (clinicData && clinicData.length) {
      let clinicDataTmp = clinicData.filter(function (el) {
        return el.city == value;
      });
      setFilteredClinicData(clinicDataTmp);
    }
    setAllData({ ...AllData, city: value });
  };

  const addImage = (acceptedFiles) => {
    const fileTypeRegex = new RegExp(
      ".(pdf|csv|doc|docx|xls?|gif|jpe?g|tiff?|png|webp|bmp)"
    );
    let fileArray = [];
    let total = totalSize;
    acceptedFiles.forEach((file) => {
      if (!fileTypeRegex.test(file.name)) {
        toaster("info", `${file.name} is not correct format!`);
        return;
      }
      total = file.size + total;
      if (total >= 5242880) {
        toaster("info", `total size exceed 5 Mb`);
        return;
      }
      fileArray.push({
        // preview: acceptedFiles[0].type.includes('image') ?URL.createObjectURL(file):documentImage,
        preview: URL.createObjectURL(file),
        name: file.name,
        timestamp: new Date(),
        type: file.type,
      });
    });
    setFiles((prevState) => [...prevState, ...acceptedFiles]);
    setImages((prevState) => [...prevState, ...fileArray]);
    setTotalSize(total);
  };

  const changeDateFormat = (date) => {
    if (!date) return;
    let d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toISOString().split("T")[0];
  };

  const isValidation = () => {
    let message = "";
    let flag = true;
    if (AllData.taskType.trim() === "") {
      flag = false;
      message = "Please Select Task Type";
    }
    // else if (AllData.gmName.trim() === '' && role != "cityGM" && role != "cityAGM" && role != "cityAM") {
    //     flag = false
    //     message = 'Please Select GM'
    // }
    else if (AllData.city.trim() === "") {
      flag = false;
      message = "Please select the city";
    }
    else if (AllData.clinicId.trim() === "" && AllData.hospId.trim() === "" ) {
      flag = false;
      message = `Please Select the ${selectedVal}`;
    } 
    else if (AllData.title.trim() === "") {
      flag = false;
      message = "Please Enter the Task Title";
    } else if (AllData.description.trim() === "") {
      flag = false;
      message = "Please Enter the description";
    } else if (AllData.priority.trim() === "") {
      flag = false;
      message = "Please Select the Priority";
    }
    else if (AllData.startDate === "") {
      flag = false;
      message = "Please Select the Start Date";
    }
    else if (AllData.endDate === "") {
      flag = false;
      message = "Please Select the End Date";
    }
    else if (AllData.visitDate === "") {
      flag = false;
      message = "Please Select the Visit Date";
    }
  
    // else if (AllData.hospId.trim() === '') {
    //     flag = false
    //     message = 'Please Select the Hospital'
    // }
    setErrorMessage(message);
    return flag;
  };
  const submitDetail = (event) => {
    event.preventDefault();
    let data = new FormData();
    let isBd=true
    data.append("title", AllData.title);
    data.append("description", AllData.description);
    data.append("priority", AllData.priority);
    data.append("taskType", AllData.taskType);
    data.append("startDate", changeDateFormat(AllData.startDate) || "");
    data.append("endDate", changeDateFormat(AllData.endDate) || "");
    data.append("city", AllData.city);
    data.append("hospId", AllData.hospId);
    data.append("hospName", AllData.hospName);
    data.append("clinicId", AllData.clinicId);
    data.append("clinicName", AllData.clinicName);
    data.append("visitDate", changeDateFormat(AllData.visitDate) || "");
    let department = localStorage.getItem("department") || "Supply Team";
    if (!department) {
      if (role == "cityOps") {
        toaster("warn", "BD not allowed to create Task!");
      }
      routing("/");
    }
    data.append("department", department);
    // if (role == "cityGM") {
    //   data.append("gmId", localStorage.getItem("userId") || "");
    //   data.append("gmName", localStorage.getItem("name") || "");
    // } else {
    //   if (AllData.gmId) {
    //     data.append("gmId", AllData.gmId || "");
    //     data.append("gmName", AllData.gmName || "");
    //   } else {
    //     data.append("gmId", "");
    //     data.append("gmName", "");
    //   }
    // }
    // if (role == "cityAGM") {
    //   data.append("agmId", localStorage.getItem("userId") || "");
    //   data.append("agmName", localStorage.getItem("name") || "");
    // } else {
    //   data.append("amId", "");
    //   data.append("amName", "");
    // }
    if (role=="cityOps") {
      data.append("bdId", localStorage.getItem("userId") || "");
      data.append("bdName", localStorage.getItem("name") || "");
     
    } else {
      data.append("bdId", "");
      data.append("bdName", "");
    }
    
    // Finance
    // Insurance
    // Supply Team
    files.forEach((file) => {
      const newName = file.name.slice(
        file.name.lastIndexOf("."),
        file.name.length
      );
      data.append("files", file, newName);
    });

    if (isValidation()) {
      setIsLoading(true);
      createTask(data,isBd)
        .then(function (response) {
          if (response && response.data && response.data.status === "Success") {
            toaster("success", response.data.description);
            routing("/");
          } 
          else {
            if (
              response &&
              response.data &&
              response.data.status === "Failed"
            ){
              toaster("error", response.data.description);
            }
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          setIsLoading(false);
        });
    }
  };

  const navigate = useNavigate();

  const routing = (path) => {
    navigate(path);
  };

  return (
    <div>
      {isLoading && <Loader />}
      <header className="screen-header">
        <Grid container>
          <Grid item md={2} className="left-arrow">
            <ArrowBackIcon onClick={() => routing("/")} />
          </Grid>
          <Grid item md={10} className="header-title">
            <h4> Creating Task</h4>
          </Grid>
        </Grid>
      </header>

      <form className="input-field-container" onSubmit={submitDetail}>
        <Grid container className="formContainer" style={{paddingTop:'10px'}}>
          <Grid item md={6} xs={11}>
            <Grid container spacing={2} className="input-field">
              <Grid item xs={4} md={5} className="labelGrid">
                <label>Task Type : </label>
              </Grid>
              <Grid item xs={8} md={5}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    completeData &&
                    completeData.taskNamesList.map((task) => {
                      return task;
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Task Type"  />
                  )}
                  className="selectLeftColWid"
                  name="taskType"
                  onChange={(event, value) => handleTask(event, value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={11}>
            <Grid container spacing={2} className="input-field">
              <Grid item xs={4} md={5} className="labelGrid">
                <label>City : </label>
              </Grid>
              <Grid item xs={8} md={5}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    completeData &&
                    completeData.citiesList.map((city) => {
                      return city;
                    })
                  }
                  renderInput={(params) => (
                    <TextField {...params} placeholder="City"  />
                  )}
                  className="selectLeftColWid"
                  name="city"
                  onChange={(event, value) => handleCity(event, value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} xs={11}>
            <FormControl sx={{    width: '100%'}}>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    className="RadioBtnLabel"
                    style={{
    marginTop: '12px'
}}
                  >
                    Hospital & Clinic :
                  </FormLabel>
                </Grid>
                <Grid item xs={8} md={6}>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={selectedVal}
                    onChange={(event) => {
                      setSelectedVal(event.target.value);
                      setAllData({ ...AllData, clinicId: "", clinicName: "", hospId: "", hospName: "" });
                      console.log(event.target.value);
                    }}
                  >
                    {" "}
                    <FormControlLabel
                      value="clinic"
                      control={<Radio />}
                      label="Clinic"
                      className="RadioBtnLabel"
                    />
                    <FormControlLabel
                      value="hospital"
                      control={<Radio />}
                      label="Hospital"
                      className="RadioBtnLabel"
                    />
                  </RadioGroup>

                  {selectedVal == "hospital" ? (
                    <div className="input-field">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={AllData.hospName}
                        options={filteredHospData.map((el) => {
                          return { id: el["_id"], label: el["hospitalName"] };
                        })}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select Hospital"  />
                        )}
                        className="selectLeftColWid"
                        onChange={(event, value) =>
                          handleChangeHosp(event, value)
                        }
                        name="hospId"
                        disabled={AllData.clinicName ? true : false}
                      />
                    </div>
                  ) : (
                    <div className="input-field">
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={AllData.clinicName}
                        options={filteredClinicData.map((el) => {
                          return { id: el["_id"], label: el["hospitalName"] };
                        })}
                        renderInput={(params) => (
                          <TextField {...params} placeholder=" Select Clinic" />
                        )}
                        className="selectLeftColWid"
                        onChange={(event, value) =>
                          handleChangeClinic(event, value)
                        }
                        name="clinicId"
                        disabled={AllData.hospName ? true : false}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={11}>
            <div className="input-field">
              <label>Title : </label> <br />
              <textarea
                name="title"
                value={AllData.title}
                onChange={(event) => {
                  handleChange(event);
                  setTitleChar(160 - event.target.value.length);
                }}
                type="textarea"
                className="text-field title-h text-area-input-field"
                maxLength="160"
                placeholder="Add Title"
              />
              <p className="character-limit">{titleChar} characters</p>
            </div>
          </Grid>
          <Grid item md={12} xs={11}>
            <div className="input-field">
              <label>Description :</label> <br />
              <textarea
                name="description"
                onChange={(event) => {
                  handleChange(event);
                  setDescriptionChar(1000 - event.target.value.length);
                }}
                value={AllData.description}
                className="text-field description-h text-area-input-field"
                maxLength="1000"
                placeholder="Add Description"
              />
              <p className="character-limit">{descriptionChar} characters</p>
            </div>
          </Grid>
          <Grid item md={6} xs={11}>
            <Grid container spacing={2} className="input-field">
              <Grid item xs={3.5} md={5} className="labelGrid">
                <label> Priority : </label>
              </Grid>
              <Grid item xs={8.5} md={5}>
                <Select
                  displayEmpty
                  className={
                    AllData?.priority == ""
                      ? "selectInput selectRightColWid"
                      : "selectRightColWid selectInputToggle"
                  }
                  name="priority"
                  onChange={handleChange}
                  sx={{width:"100%"}}
                >
                  <MenuItem>Select Priority based on urgency</MenuItem>
                  <MenuItem value="high" name="priority">
                    {" "}
                    <p style={{ color: "#00263E", margin: "0px" }}>
                      {" "}
                      High{" "}
                    </p>{" "}
                  </MenuItem>
                  <MenuItem value="medium" name="priority">
                    {" "}
                    <p style={{ color: "#00263E", margin: "0px" }}>
                      {" "}
                      Medium{" "}
                    </p>{" "}
                  </MenuItem>
                  <MenuItem value="low" name="priority">
                    {" "}
                    <p style={{ color: "#00263E", margin: "0px" }}> Low </p>
                  </MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} xs={11}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={5}>
                <div className="input-field">
                  <label> Start Date :</label>
                  <br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      slotProps={{ textField: { size: "small" } }}
                      inputFormat="DD-MM-YY"
                      maxDate={AllData.endDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Select Date",
                          }}
                          size="small"
                          style={{ color: "rgb(0, 38, 62)", }}
                        />
                      )}
                      value={
                        AllData.startDate !== "" ? AllData.startDate : null
                      }
                      onChange={(newValue) => {
                        setAllData({ ...AllData, startDate: newValue });
                      }}
                      components={{
                        CalendarIcon: CalendarMonthOutlinedIcon, 
                        OpenPickerIcon: CalendarMonthOutlinedIcon,
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>

              <Grid item xs={6} md={5}>
                <div className="input-field">
                  <label> End Date : </label>
                  <br />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD-MM-YY"
                      minDate={AllData.startDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "End Date",
                          }}
                          size="small"
                        />
                      )}
                      value={AllData.endDate !== "" ? AllData.endDate : null}
                      onChange={(newValue) => {
                        setAllData({ ...AllData, endDate: newValue });
                      }}
                      components={{
                        OpenPickerIcon: CalendarMonthOutlinedIcon,
                        CalendarIcon: CalendarMonthOutlinedIcon, 
                      }}
                    />
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={3} xs={11}>
            <Grid container spacing={2} className="input-field">
              <Grid item xs={4} md={5} className="labelGrid">
                <label> Visit Date :</label>
              </Grid>
              <Grid item xs={8} md={5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD-MM-YY"
                    minDate={AllData.startDate}
                    maxDate={AllData.endDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Visit Date",
                        }}
                        size="small"
                      />
                    )}
                    value={AllData.visitDate !== "" ? AllData.visitDate : null}
                    onChange={(newValue) => {
                      setAllData({ ...AllData, visitDate: newValue });
                    }}
                    components={{
                      OpenPickerIcon: CalendarMonthOutlinedIcon,
                      CalendarIcon: CalendarMonthOutlinedIcon,
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={11}>
            <div className="dropImageDiv">
              <DropImage
                files={imageArray}
                addImage={addImage}
                savedFiles={[]}
              />
            </div>
          </Grid>

          <Grid item md={12} xs={12} className="create-btn-container">
            <Button
              variant="contained"
              type="submit"
              style={{ paddingInline: "26%" }}
            >
              Create Task
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default BdTaskCreate;
