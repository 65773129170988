import React, { useState, useEffect } from "react";
import { ArrowBack, Cancel } from "@mui/icons-material";
import { Grid, Button, Dialog, DialogTitle, DialogActions, DialogContent, Tabs, Tab, Typography, Box } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import editIcon from "../../../assets/editIcon.svg";
import location from "../../../assets/location.svg";
import PropTypes from 'prop-types';
import { fetchTaskDetails, getTaskHistory, getSignedUrl, uploadFiles, checkInTask, checkOutTask } from "../../../services";
import Comments from "./comment";
import History from "./history";
import { DropImage } from "../../common";
import TaskDetail from "./taskDetail";
import { windowSize, toaster } from "../../../utility";
import AllImageView from "./AllImagesView"
import { makeStyles } from "@material-ui/core/styles";
import documentImage from '../../../assets/document.svg';
import './index.css'
import Loader from "../../common/Loader/loader";
import { geolocated } from "react-geolocated";
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
const useStyles = makeStyles({
    AttachmentsHeading: {
        fontSize: "2.4rem",
    },
    mobileHeading: {
        fontSize: "2rem",
    },
    imageClass: {
        width: "100%",
        height: "100%"
    },

    forDialogBox: {
        '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            maxHeight: 'calc(100% - 1%)',
            minWidth: "100%"
        }
    }

});
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function ViewTask(props) {

    let { id } = useParams();
    const { isMobile } = windowSize();
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isCheckedIn, setIsCheckedIn] = useState(false);
    const navigate = useNavigate();
    const isChecked = false;


    const routing = (path) => {
        navigate(path)
    }

    const checkIn = (task, id, name, isClinic) => {
        console.log("isClinic", isClinic)
        if (navigator?.geolocation) {
            setIsLoading(true)
            navigator.geolocation.getCurrentPosition((location) => {
                if (location) {
                    let data = location.coords;
                    checkInTask(task.taskId, location.coords.latitude, location.coords.longitude, id, name, isClinic).then(res => {
                        // checkInTask(task.taskId,28.660018,77.189996, '1AdgXB7DmV', name, false).then(res => {
                        console.log(res)
                        if (res && res.data.status === "Success") {
                            setIsCheckedIn(true);
                            toaster("success", res.data.description)
                        } else {
                            toaster("warn", res.data.description)
                        }
                    })
                        .catch(() => { })
                        .finally(() => {
                            setIsLoading(false)
                        })
                } else {
                    toaster("info", `Please enable location!`)
                }
            });
        }
    }

    const checkOutStatus = (task, id, name, isClinic) => {
        if (navigator?.geolocation) {
            setIsLoading(true)
            navigator.geolocation.getCurrentPosition((location) => {
                if (location) {
                    let data = location.coords;
                    checkOutTask(task.taskId, location.coords.latitude, location.coords.longitude, id, name, isClinic).then(res => {
                        if (res.data.status === "Success") {
                            setIsCheckedIn(false);
                            toaster("success", res.data.description)
                        } else {
                            toaster("warn", res.data.description)
                        }
                    })
                        .catch(() => { })
                        .finally(() => {
                            setIsLoading(false)
                        })
                } else {
                    toaster("info", `Please enable location!`)
                }
            });
        }
    };

    const [value, setValue] = React.useState(0);
    const [totalSize, setTotalSize] = useState(0)
    const [viewTask, setViewTask] = useState({});
    const [activeId, setActiveId] = useState(0)
    const [imageArray, setImages] = useState([])
    const [displayFile, setDisplayFile] = useState([])
    const [displayurl, setDisplayurl] = useState([])
    const [files, setFiles] = useState([])
    const [savedFileArr, setSavedFileArr] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [newFile, setNewFile] = useState([])
    // const {isMobile}=windowSize()
    const hiddenFileInput = React.useRef(null);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        if (props.comingMessage.length > 0 && props.comingMessage[0].event == "onStatusChange" && props.comingMessage[0].messageData.status && viewTask && props.comingMessage[0].messageData.taskId == viewTask.taskId) {
            let newDetails = { ...viewTask }
            newDetails.status = props.comingMessage[0].messageData.status
            setViewTask(newDetails)
            // viewTask.
        }
    }, [props.comingMessage])


    function initialize() {
        fetchTaskDetails(id).then(function (res) {

            if (res.data && res.data['result']) {
                setViewTask(res.data.result)
                let arr = res.data.result.urls
                let totalUrl = []

                arr && arr.length && arr.forEach((file, index) => {
                    if (file.multiUrl) {
                        file.multiUrl.forEach(multiFile => {
                            let fileObject = {
                                timestamp: multiFile.timestamp,
                                message_media: multiFile.url,
                                userId: file.userId,
                                userName: file.userName,
                                message_media_size: file.message_media_size,
                                fileName: multiFile.fileName,
                                filetype: multiFile.filetype,
                            }
                            totalUrl.push(fileObject)
                        })
                    }
                    else {
                        totalUrl.push(file)
                    }
                })
                setDisplayFile(totalUrl)
                // let toSignArr = []

            }
            if (res.data && res.data['result'] && res.data['result']["checkedInStatus"] === true) {
                // console.log(es.data && res.data['result']&& res.data['result']["checkedInStatus"])
                // console.log("helo",)
                setIsCheckedIn(true);
            }
        })
            .catch(() => { })
            .finally(() => {
                setIsLoading(false)
            })
    }

    useEffect(() => {

        initialize()

        activeTab(1)
    }, [])
    useEffect(() => {

        const tempUrlArr = displayFile.map(x => x && x.message_media)
        const tempObj = {
            urls: tempUrlArr,
            isDownload: false
        }
        getSignedUrl(tempObj)
            .then(res => {

                if (res.data && res.data.status === "Success") {
                    const temp = res.data.result.map((x, i) => {
                        const obj = displayFile[i]
                        obj['url'] = x
                        return obj
                    })
                    setDisplayurl(temp)
                }
            })
            .catch(err => {
            })
    }, [displayFile])

    const activeTab = (id) => {
        if (activeId !== 0) {
            document.getElementById(activeId).classList.remove('tab-active')
        }
        document.getElementById(id).classList.add('tab-active')
        setActiveId(id)
    }

    const addImage = (acceptedFiles) => {
        const fileTypeRegex = new RegExp(".(pdf|csv|doc|docx|xls?|gif|jpe?g|tiff?|png|webp|bmp)")
        let fileArray = []
        let tmpFileArray = []
        let total = totalSize
        acceptedFiles.forEach(file => {
            if (!fileTypeRegex.test(file.name)) {
                toaster("info", `${file.name} is not correct format!`)
                return
            }
            total = file.size + total
            if (total >= 5242880) {
                return
            }
            fileArray.push({
                preview: file.type.includes('image') ? URL.createObjectURL(file) : documentImage,
                name: file.name,
                timestamp: new Date()
            })
            tmpFileArray.push(file)
            setTotalSize(total)
        })
        if (total >= 5242880) {
            toaster("info", `total size exceed 5 Mb`)
            return
        }
        if (tmpFileArray && tmpFileArray.length) {
            saveFile(tmpFileArray)
            setNewFile([...tmpFileArray, ...newFile])
            setFiles(prevState => [
                ...prevState,
                ...acceptedFiles
            ])
            setImages(prevState => [
                ...prevState,
                ...fileArray
            ])
        }
    }

    const saveFile = (files) => {
        let data = new FormData()
        data.append('taskId', id)
        files.forEach(file => {
            const newName = file.name
            data.append('files', file, newName)
        })
        uploadFiles(data).then((res) => {
            if (res.data.status == "Success") {
                setNewFile([])
                toaster('success', res.data.status);
                initialize()
            }
            else {
                if (res.data.status == "Failed") {
                    toaster('error', res.data.description);
                }
            }
        });

    }

    const addFile = (files) => {
        let data = new FormData()
        data.append('taskId', id)
        let file = files[0]
        const newName = file.name.slice(file.name.lastIndexOf("."), file.name.length)
        data.append('files', file, newName)
        uploadFiles(data).then((res) => {
            if (res.data.status == "Success") {
                setNewFile([])
                toaster('success', res.data.status);
                initialize()
            }
        });

    }

    const addMore = () => {
        hiddenFileInput.current.click();
    }

    const openAllImages = () => {
        savedFileArr.forEach((file) => {
            window.open(file.url, '_blank');
        })
    }

    const rolesData = JSON.parse(localStorage.getItem('roles'));
    const role = rolesData.role;
    const shouldHideButton = () => {
        if (["cityAGM", "cityGM"].includes(rolesData.role) || (rolesData.otherRoles && rolesData.otherRoles.length > 0 && rolesData.otherRoles.some(ele => ["cityAGM", "cityGM"].includes(ele)))
        ) {
            return true;
        } else if (["cityOps", "BD"].includes(rolesData.role) && (rolesData.otherRoles && rolesData.otherRoles.length > 0 && rolesData.otherRoles.some(ele => ["cityOps", "BD"].includes(ele)))) {
            return false;
        }
        return false;
    };

    return (
        <div style={{
            height: "calc(100vh - 73px)",
            overflow: "auto"
        }}>

            <div>
                {isLoading && <Loader />}
                <header className='screen-header'>
                    <Grid container sx={{ textAlign: 'center' }}>
                        <Grid item xs={1} sx={{ paddingTop: '20px', cursor: 'pointer' }}>
                            <ArrowBack onClick={() => routing('/')} /> &nbsp; &nbsp;
                        </Grid>
                        <Grid item xs={9}>
                            <h4 style={{ textOverflow: "ellipsis", whiteSpace: 'nowrap', overflow: 'hidden' }}> {viewTask.taskId} {viewTask.title}</h4>
                        </Grid>
                        <Grid item xs={1}>
                            {(viewTask.hospId || viewTask.clinicId) &&
                                (!isCheckedIn || isCheckedIn || shouldHideButton()) && (
                                    <div>
                                        {!isCheckedIn && !shouldHideButton() && (
                                            <div className="buttonCheckIn">
                                                <Button onClick={() => checkIn(viewTask, viewTask.hospId || viewTask.clinicId, viewTask.hospName || viewTask.clinicName, viewTask.clinicId ? true : false)}>
                                                    <span>
                                                        <AddLocationOutlinedIcon />
                                                    </span>
                                                </Button>
                                                <span>Check in</span>
                                            </div>
                                        )}
                                        {isCheckedIn && (
                                            <div className="CheckoutButton">
                                                <Button onClick={() => checkOutStatus(viewTask, viewTask.hospId || viewTask.clinicId, viewTask.hospName || viewTask.clinicName, viewTask.clinicId ? true : false)}>
                                                    <span>
                                                        <img src="https://img.pristyncare.com/Mask-Group-6.svg" />
                                                    </span>
                                                </Button>
                                                <span>Check Out</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                        </Grid>
                        {!isMobile &&
                            <Grid item xs={1}>
                                <Button onClick={() => routing('/editTask/' + viewTask.taskId)} variant="contained" sx={{ fontSize: '10px', height: '50px', width: '50px', padding: 'unset', marginTop: '5px' }}>
                                    <div>
                                        <img src={editIcon} height='20px' /><br />Edit Task
                                    </div>
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </header>
            </div>

            <form className='input-field-container'>
                <Grid container>
                    <>
                        <div class='tab mobile-screen'>
                            <ul className="tabContainer">
                                <li id={1} onClick={() => activeTab(1)} className="tabItem">About</li>
                                <li id={2} onClick={() => activeTab(2)} className="tabItem">Comments</li>
                                <li id={3} onClick={() => activeTab(3)} className="tabItem">History</li>
                            </ul>
                        </div>
                    </>

                    {activeId === 1 &&
                        <Grid item md={4} xs={12} className='dataContainer'>
                            <TaskDetail isMobile={isMobile} viewTask={viewTask} filterData={props.filterData} />
                            <Grid container className="fieldContainer">
                                {isMobile &&
                                    <>
                                        <Grid item sm={12} sx={{ width: '100%' }}>
                                            <DropImage isMobile={isMobile} files={displayurl} addImage={addImage} savedFiles={savedFileArr} />
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            width: '100%', display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <Button className="bottomButton" onClick={() => handleClickOpen()} variant="contained">View All</Button>
                                        </Grid>
                                        {/* <Grid item xs={6} sx={{ width: '100%' }}>
                                            <Button className="bottomButton" onClick={() => saveFile()} variant="contained">Add More</Button>
                                        </Grid> */}
                                    </>
                                }
                            </Grid>
                        </Grid>
                    }
                    {isMobile && activeId === 2 && <Comments filterData={props.filterData} comingMessage={props.comingMessage} />}
                    {isMobile && activeId === 3 && <History />}

                    {isMobile === false &&
                        <Grid item md={8} className='large-screen'>
                            <Box sx={{ width: '100%', height: 'calc(100vh - 65px)' }}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                        <Tab label="Comments" {...a11yProps(0)} />
                                        <Tab label="History" {...a11yProps(1)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0} className='myTab'>
                                    <Comments filterData={props.filterData} comingMessage={props.comingMessage} />
                                </TabPanel>
                                <TabPanel value={value} index={1} className='myTab'>
                                    <History />
                                </TabPanel>
                                <Grid container>
                                    <Grid item md={10}>
                                        <DropImage isMobile={isMobile} files={displayurl} addImage={addImage} />
                                    </Grid>
                                    <Grid item md={2} className='btn-container' sx={{ paddingTop: "20px" }}>
                                        <Grid container>
                                            <Grid className="addButton" item xs={12}>
                                                <Button onClick={() => handleClickOpen()} variant="contained">View All</Button>
                                            </Grid>
                                            {/* <Grid className="addButton" item xs={12}>
                                                <input type="file" id="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={(event) => addFile(event.target.files)} />
                                                <Button onClick={() => addMore()} variant="contained">Add More</Button>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    }


                </Grid>
            </form>


            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.forDialogBox}
            >
                <DialogTitle id="alert-dialog-title" className="viewAllBar">
                    <Grid container style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
                        <Grid xs={11} className="viewAllHead">
                            Documents for Task - {id}
                        </Grid>
                        <Grid xs={1} style={{
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            <Button onClick={handleClose}><Cancel className="cancelIcon" /></Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <AllImageView savedFileArr={displayurl} isMobile={isMobile} />
                </DialogContent>
                {/* <DialogActions>
          
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
            </Dialog>
        </div>
    )
}

export default ViewTask