import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {  getTaskHistory  } from "../../../services";
function History(){
    let { id } = useParams();
    const [historyData, setHistoryData] = useState(null)


    function initialize() {
        getTaskHistory(id).then(res => {
            if (res.data.result) {
                setHistoryData(res.data.result)
            }
        })
    }

    useEffect(() => {
        initialize()  
    }, [])

    const generateHistoryTime=(t)=>{
        let timestamp = new Date(t)
        let time = createDate(timestamp.toDateString().split(" ")) +" "+ createTime(timestamp.toTimeString().split(" ")[0].split(":"))
        return(time)
    }

    const createDate=(dateString)=>{
        return(dateString[1]+" "+dateString[2])
    }

    const createTime=(timeString)=>{
        let hr = parseInt(timeString[0])
        if(hr>12){
            return((hr-12)+":"+timeString[1]+" PM")
        }
        else{
            return(hr+":"+timeString[1]+" AM")
        }
    }
    return(
        <Box className='myBoxView'>
                {historyData && historyData.history && historyData.history.length && historyData.history.map(his=>{
                return(
                    <Grid className="historyContainer" container key={his.key}>
                        <Grid item md={2} xs={4}>{generateHistoryTime(his.timestamp)}</Grid>
                        <Grid item md={10} xs={8}>{his.message}</Grid>
                    </Grid>
                )
                })}                               
        </Box>
    )
}

export default History