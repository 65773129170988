import { ClickAwayListener, Grid } from '@mui/material'
import React, { useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import './index.css'
import SecondSidebar from './secondSidebar'

export default function Filter({ display, filterData, handelFilterTab, filtered, clickAwayFilter }) {
    const filterList = {
        'creationDate': 'Date',
        // 'taskDate': 'Date',
        'startDate': 'Date',
        'endDate': 'Date',
        'visitDate':'Date',
        'status': 'Status',
        'bdName': 'BD Names',
        'taskType': 'Task Type',
        'department': 'Department',
        'reporter': 'Reporter Names',
        'city': 'Cities',
        'gm': 'GM/AGM',
        'am': 'Assigned AM'
    }
    const [filterTitle, setFilterTitle] = useState('')
    const [activeClass, setActiveClass] = useState('')
    const [toggleSidebar, setSidebar] = useState(false)

    const handleActive = (id) => {
        if (activeClass) {
            document.getElementById(activeClass).classList.remove('active-filter')
        }
        document.getElementById(id).classList.add('active-filter')
        setActiveClass(id)
        setFilterTitle('Select ' + filterList[id])
        setSidebar(true)
    }

    const handleFilter = (value, type) => {
        handelFilterTab(value, type);
    }

    const closeFilter = () => {
        setSidebar(false)
        clickAwayFilter(false)
    }

    const setBar = () => {
        setSidebar(false)
        clickAwayFilter(false)
    }

    return (display &&
        <div className='firstFilterContainer'>
            <ClickAwayListener onClickAway={() => closeFilter(false)}>
                <Grid container className='filter-content'>
                    <Grid item >
                        <div className='filter-container'>
                            <h4 className='first-sidebar-title'>
                                Filter By
                                {!toggleSidebar && 
                                    <CancelIcon onClick={() => setBar()} className='cancelFilter1' />
                                }
                            </h4>
                            <div id='creationDate' onClick={() => handleActive('creationDate')}>Creation Date</div>
                            {/* <div id='taskDate' onClick={() => handleActive('taskDate')}>Task Date</div> */}
                            {/* <div id='endDate' onClick={() => handleActive('endDate')}>End Date</div> */}
                            <div id='startDate' onClick={() => handleActive('startDate')}>Start Date</div>
                            <div id='endDate' onClick={() => handleActive('endDate')}>End Date</div>
                            <div id='visitDate' onClick={() => handleActive('visitDate')}>Visit Date</div>
                            <div id='status' onClick={() => handleActive('status')}>Status</div>
                            <div id='bdName' onClick={() => handleActive('bdName')}>BD Name</div>
                            <div id='taskType' onClick={() => handleActive('taskType')}>Task Type</div>
                            <div id='department' onClick={() => handleActive('department')}>Department</div>
                            <div id='reporter' onClick={() => handleActive('reporter')}>Reporter</div>
                            <div id='city' onClick={() => handleActive('city')}>City</div>
                            <div id='gm' onClick={() => handleActive('gm')}>GM/AGM Assigned</div>
                            <div id='am' onClick={() => handleActive('am')}>AM Assigned</div>
                        </div>
                    </Grid>
                    {toggleSidebar &&
                        <Grid item >
                            <SecondSidebar filterData={filterData} toggleSidebar={toggleSidebar} setBar={setBar} setSidebar={setSidebar} activeItem={activeClass} filterTitle={filterTitle} filterPassBack={handleFilter} filtered={filtered} />
                        </Grid>
                    }

                </Grid></ClickAwayListener>
        </div>
    )
}