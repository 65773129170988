import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from "@mui/material";

export default function Accordian(props) {
    if (props.count == 0) return <></>
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className="accordIcon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                xs={{ 'padding': '5px' }}
            >
                <Typography className="accordHead">{props.title} ({props.count})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {props.card}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}