import MyCard from "./Card";
import Accordian from "./Accordian";
import TabPanel from "./TabPanel";
import FilterArea from "./FilterArea";
import DatePkr from "./DatePkr";
import Filter from "./Filter";
import DropImage from "./DropFile";
import DocUploadPop from "./DocUploadPop";
import PrimarySearchAppBar from "./AppBar"
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#FFFFFF',
    marginLeft: 0,
    width: '300px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#818181'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '300px',
        color: '#818181',
        border:'none',
    }
}));


export {
    MyCard,
    Accordian,
    TabPanel,
    FilterArea,
    DatePkr,
    Filter,
    DropImage,
    DocUploadPop,
    Search,
    SearchIconWrapper,
    StyledInputBase,
    PrimarySearchAppBar
}