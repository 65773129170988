import React, { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import videoIcon from "../../../assets/videoIcon.png"
import galleryIcon from "../../../assets/gallery.png";
import xlsIcon from "../../../assets/XLS.svg";
import pdfIcon from "../../../assets/pdfIcon.png";
import docIcon from "../../../assets/docIcon.png";
import csvIcon from "../../../assets/csvIcon.png";
import crossIcon from "../../../assets/cross.png";
import "./style.css";

export default function DocUploadPop({ visible, openDocPop, handleDocPop }) {

    return (
        <>
            {visible &&
                <>
                    <div className="overlay"></div>
                    <Box className="docUploadPop">
                        <Grid container>
                            <Grid item xs={12} className='uploaderHeader'>
                                Select File type
                                <img onClick={() => openDocPop(false)} src={crossIcon} className='crossIcon' />
                            </Grid>
                            <Grid item xs={12}>
                                <Box>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <label for='videoIcon'>
                                                <img src={videoIcon} className="uploadIcon" />
                                            </label>
                                            <input type="file" accept="any" id="videoIcon" className="fileInput" onChange={(event) => handleDocPop({ event: event.target.files[0], key: 1 }, ".*")} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label for='galleryIcon'>
                                                <img src={galleryIcon} className="uploadIcon" />
                                            </label>
                                            <input type="file" accept="image/*" id="galleryIcon" className="fileInput" onChange={(event) => handleDocPop({ event: event.target.files[0], key: 1 }, ".(gif|jpe?g|tiff?|png|webp|bmp)$")} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label for='xlsIcon'>
                                                <img src={xlsIcon} className="uploadIcon" />
                                            </label>
                                            <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" id="xlsIcon" className="fileInput" onChange={(event) => handleDocPop({ event: event.target.files[0], key: 1 }, ".(xls?|sheet)$")} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label for='pdfIcon'>
                                                <img src={pdfIcon} className="uploadIcon" />
                                            </label>
                                            <input type="file" accept=".pdf" id="pdfIcon" className="fileInput" onChange={(event) => handleDocPop({ event: event.target.files[0], key: 1 }, ".pdf")} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label for='docIcon'>
                                                <img src={docIcon} className="uploadIcon" />
                                            </label>
                                            <input type="file" accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" id="docIcon" className="fileInput" onChange={(event) => handleDocPop({ event: event.target.files[0], key: 1 }, ".(doc|docx|xml)")} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <label for='csvIcon'>
                                                <img src={csvIcon} className="uploadIcon" />
                                            </label>
                                            <input type="file" accept=".csv" id="csvIcon" className="fileInput" onChange={(event) => handleDocPop({ event: event.target.files[0], key: 1 }, ".csv")} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </>
    )
}