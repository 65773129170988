export default function getSystemRole() {

    let systemRole = localStorage.getItem('roles')
    systemRole = JSON.parse(systemRole)
    let getRole = ''
    if (systemRole) {
        if (systemRole['role'] == 'cityOps' || (systemRole['otherRoles'] && systemRole['otherRoles'].length && systemRole['otherRoles'].includes("cityOps"))) {
            getRole = "cityOps"
        }
        if (systemRole['role'] == 'cityAM' || (systemRole['otherRoles'] && systemRole['otherRoles'].length && systemRole['otherRoles'].includes("cityAM"))) {
            getRole = "cityAM"
        }
        if (systemRole['role'] == 'cityAGM' || (systemRole['otherRoles'] && systemRole['otherRoles'].length && systemRole['otherRoles'].includes("cityAGM"))) {
            getRole = "cityAGM"
        }
        if (systemRole['role'] == 'cityGM' || (systemRole['otherRoles'] && systemRole['otherRoles'].length && systemRole['otherRoles'].includes("cityGM"))) {
            getRole = "cityGM"
        }
        if (systemRole['role'] == 'taskHead' || (systemRole['otherRoles'] && systemRole['otherRoles'].length && systemRole['otherRoles'].includes("taskHead"))) {
            getRole = "taskHead"
        }
    }

    return getRole

}

