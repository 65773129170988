import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Button, Box, Paper, InputBase, IconButton, Badge } from '@mui/material';
import clip from "../../../assets/clip.svg"
import send from '../../../assets/send.svg'
import downloadIcon from "../../../assets/downloadIcon.svg";
import { getTaskMessages, sendMessage, getSignedUrl } from "../../../services";
import { DocUploadPop } from "../../common";
import { generateChatTime, toaster, windowSize } from "../../../utility"
import ReactHtmlParser from "react-html-parser";
import TextareaAutosize from '@mui/base/TextareaAutosize';
import "./style.css";
import { indexOf } from "lodash";

function Comments({ filterData, comingMessage }) {

    let { id } = useParams();
    const { isMobile } = windowSize();
    const [messageData, setMessageData] = useState([])
    const [message, setMessage] = useState("")
    const [messageArr, setMessageArr] = useState([])
    const [messageRaw, setMessageRaw] = useState("")
    const [docPop, setDocPop] = useState(false)
    const [files, setFiles] = useState("")
    const [atTag, setAtTag] = useState(false)
    const [currTagName, setCurrTagName] = useState("")
    const [allTag, setAllTag] = useState([])
    const commentInput = useRef(null)
    // const commentInput1 = document.getElementById("commentInput1")
    // const [tagList, setTagList] = useState(filterData.allList)


    const messagesEndRef = useRef(null);

    const fetch = () => {
        getTaskMessages(id)
            .then((res) => {
                setMessageData(res.data.result);
            })
            .catch(() => { })
    }

    useEffect(() => {
        fetch()
    }, [])

    useEffect(() => {
        document.getElementById("commentInput1").addEventListener("input", (event) => {
            let commentInput1 = document.getElementById("commentInput1").innerHTML
            let newInput = commentInput1[commentInput1.length - 1]
            let myAtTag = atTag
            let myTagging = commentInput1.split("@")
            if (newInput == "@") {
                if (myAtTag) {
                    setCurrTagName("")
                }
                setAtTag(true)
            } 
            else {
                if (myAtTag) {
                    setCurrTagName(myTagging[myTagging.length - 1])
                }
            }
            if (commentInput1.indexOf("@") == -1) {
                setAtTag(false)
                setCurrTagName("")
            }
        })
    }, [])

    // useEffect(() => {
    //     var el = document.getElementById("commentInput1")
    //     if (el && messageArr.length <= 80) {
    //         // el.innerHTML = LocalPreParser(messageArr)
    //         el.focus();
    //         document.execCommand('selectAll', false, null);
    //         document.getSelection().collapseToEnd();
    //         el.focus();
    //     }
    // }, [document.getElementById("commentInput1")])

    // useEffect(() => {
    //     var el = document.getElementById("commentInput1")
    //     if (el) {
    //         if (el.innerHTML.length == 0) {
    //             setMessageArr([])
    //             setMessageRaw("")
    //         }
    //     }
    //     if (messageRaw.length == 0) {
    //         setMessageArr([])
    //     }
    // }, [messageRaw])


    useEffect(() => {
        if (comingMessage.length > 0 && comingMessage[0].event == "onMessageAdded") {
            let findTaskID=messageData.find((ele)=>{
                return ele.taskId==comingMessage[0].messageData.taskId
            })
            if(findTaskID && findTaskID?.taskId && findTaskID.taskId==comingMessage[0].messageData.taskId){
            // console.log(comingMessage,"checking garima message",messageData)
            if (messageData.length > 0) {
                setMessageData([...messageData, comingMessage[0].messageData,])
            }
            else {
                messageData.push(comingMessage[0].messageData)
                setMessageData([...messageData])
            }
        }
        }

    }, [comingMessage])


    useEffect(() => {
        const scrollHeight = messagesEndRef.current.scrollHeight;
        const height = messagesEndRef.current.clientHeight;
        const maxScrollTop = scrollHeight - height;
        messagesEndRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;

    }, [messageData]);

    const handleChangeAt = (event) => {
        
        let commentInput1 = document.getElementById("commentInput1")
        let newINputString = commentInput1.innerHTML
        let newInput = newINputString[newINputString.length - 1]
        let myAtTag = atTag
        let myTagging = newINputString.split("@")
        // if (event.key === 'Enter') {
        //     event.preventDefault();
        //     sendMsg()
        // }
        if(newInput == "@"){
            if (myAtTag) {
                setCurrTagName("")
            }
            setAtTag(true)
        } 
        else if(newINputString.includes('<div><br></div>') && newInput == ">"){
            event.preventDefault();
            let myNewTagSplit = newINputString.split("<div><br></div>")
            document.getElementById("commentInput1").innerText = myNewTagSplit[0]
            setAtTag(false)
             sendMsg()
            document.getElementById("commentInput1").innerText = ""
        }
        else if(newInput == ">" && myTagging.length>0){

            let myNewTagSplit = newINputString.split("<span")
            console.log("myNewTagSplit[myNewTagSplit.length - 1]",myNewTagSplit[myNewTagSplit.length - 1])
            myNewTagSplit.pop()
            let splitArr=  myNewTagSplit.length==1 && myNewTagSplit[0]==""? "" : myNewTagSplit.join('<span')
            commentInput1.innerHTML = splitArr==""?splitArr:splitArr.slice(0,splitArr.length-1);
            setCurrTagName(splitArr)
            setAtTag(false)
            var el1 = document.getElementById("commentInput1")
            // if (el && messageArr.length <= 80) 
            {
                // el.innerHTML = LocalPreParser(messageArr)
                el1.focus();
                document.execCommand('selectAll', false, null);
                document.getSelection().collapseToEnd();
                el1.selectionStart = commentInput1.innerHTML.length
                el1.focus();
                
            }
            // commentInput1.selectionStart = commentInput1.innerHTML.length
            // commentInput1.focus();
           

        }
        else{
            if (myAtTag) {
                setCurrTagName(myTagging[myTagging.length - 1])
            }
        }
        if (newINputString.indexOf("@") == -1) {
            setAtTag(false)
            setCurrTagName("")
        }
        // console.log("check now",newINputString,newInput,myAtTag,myTagging )
    }

    const selectAt = (name, id) => {
        // let myMessage = message
        let myMessageRaw = messageRaw
        let myMessageArr = messageArr

        if (atTag) {
            let commentInput1 = document.getElementById("commentInput1")
            let newINputString = commentInput1.innerHTML.split("@")[0]
            // commentInput1.innerHTML = newINputString + '<span style="color:blue" contenteditable="false">' + name + '</span>'
            commentInput1.innerHTML = newINputString + '<span style="color:blue" contenteditable="false">' + name + '</span>&nbsp;'
            setAtTag(false)
            setAllTag([...allTag, ...[{ name: name, id: id }]])
            var el = document.getElementById("commentInput1")
        // if (el && messageArr.length <= 80) 
        {
            // el.innerHTML = LocalPreParser(messageArr)
            el.focus();
            document.execCommand('selectAll', false, null);
            document.getSelection().collapseToEnd();
            el.selectionStart = commentInput1.innerHTML.length
            el.focus();
            
        } console.log(commentInput1,"commentInput1.innerHTML",newINputString,commentInput1.innerHTML)
        }

       
    }

    const handleChangeAt1 = (event) => {
        let myMessageArr = messageArr
        let myMessageRaw = messageRaw
        let key = event.nativeEvent.inputType
        let value = event.currentTarget.textContent
        let last = value.split("@")[1]
        let start = value.split("@")
        // if (value.length < messageRaw.length) {
        //     myMessageRaw = myMessageRaw.slice(0, -1)
        //     myMessageArr = myMessageArr.slice(0, -1)
        //     setMessageRaw(myMessageRaw)
        //     setMessageArr(myMessageArr)
        //     // event.preventDefault();
        //     return
        // }
        if (messageRaw.length <= 80) {
            if (value.indexOf("@") == -1) {
                setAtTag(false)
            }
            if (key == 'deleteContentBackward') {
                if (myMessageRaw[myMessageRaw.length - 1] == '@') {
                    setAtTag(false)
                }
                myMessageArr = myMessageArr.slice(0, -1)
                myMessageRaw = myMessageRaw.slice(0, -1)
                setCurrTagName(currTagName.slice(0, -1))
                setMessageRaw(myMessageRaw)
                setMessageArr(myMessageArr)
            } else {
                let element = value[value.length - 1]
                if (atTag == true) {
                    if (element == '@') {
                        setAtTag(true)
                        setCurrTagName("")
                    }
                    if (element == " ") {
                        myMessageArr.push('&nbsp;')
                    } else {
                        myMessageArr.push(element)
                    }
                }
                if (atTag == true && value.indexOf("@") != -1) {
                    setCurrTagName(start[start.length - 1])
                }
                if (atTag == true && value.indexOf("@") == -1) {
                    setAtTag(false)
                    setCurrTagName("")
                }
                if (atTag == false) {
                    if (element == '@') {
                        setAtTag(true)
                    }
                    if (!element.trim()) {
                        myMessageArr.push('&nbsp;')
                    } else {
                        myMessageArr.push(element)
                    }
                }
                // myMessageArr = myMessageRaw.split("")
                setMessageArr(myMessageArr)
                setMessageRaw(value)
            }
        }
    }

    const handleKey = (event) => {
        const currentTextLength = event.target.outerText.length;
        if (currentTextLength === 80 && event.keyCode != 8) {
            event.preventDefault();
        }

        var el = document.getElementById("commentInput1")
        if (el) {
            if (el.innerHTML.length == 0) {
                setMessageArr([])
                setMessageRaw("")
            }
        }

        if (messageArr.length <= 80) {
            // if (event.key === "Backspace") {
            //     let myMessageRaw = messageRaw
            //     let myMessageArr = messageArr
            //     let myCurrTagName = currTagName
            //     if (myMessageArr.length && myMessageArr[myMessageArr.length - 1].length > 1) {
            //         let name = myMessageArr[myMessageArr.length - 1]
            //         name = name.replace('<span style="color:blue" contenteditable="false">', '')
            //         name = name.replace('</span>', '')
            //         myMessageRaw = myMessageRaw.replace(name, ' ')
            //     }
            //     myMessageRaw = myMessageRaw.slice(0, -1)
            //     myMessageArr = myMessageArr.slice(0, -1)
            //     setMessageRaw(myMessageRaw)
            //     setMessageArr(myMessageArr)
            //     if (atTag) {
            //         myCurrTagName = myCurrTagName.slice(0, -1)
            //         setCurrTagName(myCurrTagName)
            //     }
            // }
           
            if (event.key === 'Enter') {
                event.preventDefault();
                sendMsg()
            }
            if(event.key=="@"){
setAtTag(true)
            }
            if (event.keyCode === 8 && atTag && event.key!=="Shift") {
                setCurrTagName(currTagName.slice(0, -1))
            } 
            else if (atTag && event.key!=="Shift") {
                    setCurrTagName(currTagName + event.key)
                }
                console.log( currTagName,"Garima Raghav",event.key,atTag,event.keyCode)
                console.log("filterData.allList",filterData.allList)
            // else if (atTag && event.key!=="Unidentified" && event.key!=="Shift") {
            //     setCurrTagName(currTagName + event.key)
            // }
            // else{
                
            //     if(event.key=="Unidentified"){
            //     const currentCode = event.which || event.code;
            //     let currentKey = event.key;
              
            //     if (!currentKey) {
            //         currentKey = String.fromCharCode(currentCode);
            //      }
            //      str += currentKey;
                 
            //      event.preventDefault();
            //     // el.innerHTML = str;
            //     setCurrTagName(currTagName + str)
            // }
        
    }

    }
    const selectAt1 = (name, id) => {
        // let myMessage = message
        let myMessageRaw = messageRaw
        let myMessageArr = messageArr

        if (atTag) {
            let index = myMessageArr.lastIndexOf("@");
            for (let i = myMessageArr.length; i > index; i--) {
                myMessageArr.pop()
            }
            myMessageRaw = myMessageRaw.split("@")[0]
            myMessageArr = [...myMessageArr, ...['<span style="color:blue" contenteditable="false">' + name + '</span>']]
            myMessageRaw = myMessageRaw + name
            setMessageArr(myMessageArr)
            setMessageRaw(myMessageRaw)
            setAllTag([...allTag, ...[{ name: name, id: id }]])
            setAtTag(false)
            setCurrTagName("")
        }
    }

    const sendMsg = () => {
        console.log("id---",id)
        let ciText = document.getElementById("commentInput1").innerText
        if (!ciText) {
            toaster('warn', "Please type something!");
            return
        }
        let media = false;
        var FormData = require('form-data');
        var data = new FormData();
        data.append('taskId', id);
        data.append('messageBody', ciText);
        data.append('messageType', media ? 'MEDIA' : 'TEXT');
        let attributes = '{}'
        console.log("ciText ciText ciText",ciText)
        if (files != "") {
            attributes = {
                "message_media_name": files.name,
                "has_media": true,
                "message_type": "MEDIA",
                "message_media_file_type": files.type,
                "message_media_size": files.size
            }
            attributes = JSON.stringify(attributes);
            data.append('files', files, files.name);
        }
        if (allTag.length) {
            let userIdArr = []
            allTag.forEach(el => {
                userIdArr.push({ "userId": el.id })
            })
            attributes = { "mention_data": userIdArr }
            attributes = JSON.stringify(attributes);
        }
        data.append('attributes', attributes);
        sendMessage(data).then(res => {
            setMessage("")
            setMessageRaw("")
            setFiles([])
            setAllTag([])
            setMessageArr([])
            setAtTag(false)
            document.getElementById("commentInput1").innerText = ""
            // fetch()
        })
    }

    const download = (media) => {
        var windowRef=window.open()
        var data = { "urls": [media], "isDownload": false };
        getSignedUrl(data).then(res => {
             windowRef.location= res.data.result[0]
        })
    }

    const openDocPop = (state) => {
        setDocPop(state);
    }

    const handleDocPop = (doc, type) => {
        const checkReg = new RegExp(type)
        if (!checkReg.test(doc.event.type)) {
            toaster('info', "File Format is not correct!");
            return
        }
        if (doc.event.size >= 5242880) {
            toaster('info', "File Limit exceed Please upload the File less tha 5Mb!");
            return
        }
        setFiles(doc.event)
        document.getElementById("commentInput1").innerText = ""
        setAtTag(false)
        let media = doc.event != "" ? true : false;
        var FormData = require('form-data');
        var data = new FormData();
        data.append('taskId', id);
        data.append('messageBody', doc.event.name);
        data.append('messageType', 'MEDIA');
        let attributes = '{}'
        if (doc.event != "") {
            attributes = {
                "message_media_name": doc.event.name,
                "has_media": true,
                "message_type": "MEDIA",
                "message_media_file_type": doc.event.type,
                "message_media_size": doc.event.size
            }
            attributes = JSON.stringify(attributes);
            data.append('files', doc.event, doc.event.name);
        }
        data.append('attributes', attributes);
        sendMessage(data).then(res => {
            // setMessage("")
            setFiles([])
            // fetch()
        })
        setDocPop(false);
    }

    const checkIncomingTagging = (msg) => {
        if (msg.attributes && msg.attributes.mention_data && filterData && filterData.allList) {
            let nameArr = []
            let body = msg.body
            msg.attributes.mention_data.forEach((mg) => {
                filterData.allList.forEach((el) => {
                    if (mg['userId'] == el['id']) {
                        nameArr.push(el['name'])
                    }
                })
            })
            nameArr.forEach((el) => {
                body = body.replace(el, '<span style="color:blue" contenteditable="false">' + el + '</span>')
            })

            return (ReactHtmlParser(body))
        } else {
            return (msg.body)
        }
    }

    const LocalPreParser = (data) => {
        return data.join("");
    }

    const MessageCard = ({ msg }) => {
        return (
            <div style={{ width: '100%' }}>


                <Grid item sm={12} xs={12}>
                    <Grid container style={localStorage.getItem('userId')==msg.userId?{    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end'}:null}>
                        <Grid item xs={7} sm={12} md={7} lg={7} style={localStorage.getItem('userId')==msg.userId?{display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
    flexDirection: 'column',
    marginRight: '20px'}:null}>

                            <Grid container className="messageBox" style={{ width: "fit-content" }}>
                                <Grid item xs={12}>
                                    {msg.msgType == "MEDIA" &&
                                        <>
                                            <div className="messageMedia" onClick={() => download(msg.media)}>
                                                <div className="imgTxt">
                                                    Download
                                                </div>
                                                <div className="imgDiv">
                                                    <img src={downloadIcon} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <p style={{ wordBreak: 'break-all', margin: "0px" }}>
                                        {checkIncomingTagging(msg)}
                                    </p>
                                </Grid>
                                <Grid item xs={12} className='messageTime'>
                                    {generateChatTime(msg.timestamp)}
                                </Grid>
                            </Grid>
                            <div className="messageFrom">
                                {msg.userName === localStorage.getItem('name') ? "YOU" :
                                    ` ${msg.userName} (${msg.userRole})`
                                }

                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </div>

        )
    }

    const nameList = (name, id) => {
        return (<li onClick={() => selectAt(name, id)}>
            <Grid container>
                <Grid item xs={3}>
                    <div class="nameCircle">{name[0]}</div>
                </Grid>
                <Grid item xs={9}>
                    {name}
                </Grid>
            </Grid>
        </li>)
    }

    const taggingList = () => {
        return (
            filterData && filterData.allList && filterData.allList.length &&
            <>
                <div className="atTagOptions">
                    <ul>
                        {
                            currTagName == '' && filterData.allList.map((el) => (
                                el.name && el.id ? nameList(el.name, el.id) : ''
                            ))
                        }
                        {
                            currTagName != '' && filterData.allList.filter(x => x.name.trim().toLowerCase().includes(currTagName.trim().toLowerCase())).map((el) => (
                                el.name && el.id ? nameList(el.name, el.id) : ''
                            ))
                        }
                        {
                            currTagName != '' && filterData.allList.filter(x => x.name.trim().toLowerCase().includes(currTagName.trim().toLowerCase())).length == 0 &&
                            "Not Found"
                        }
                    </ul>
                </div>
            </>
        )
    }

    const handlePaste = (event) => {
        let myMessageArr = messageArr
        let myMessageRaw = messageRaw
        event.preventDefault();
        let pasteTxt = event.clipboardData.getData('Text');
        let leftChar = myMessageArr.length
        leftChar = 80 - leftChar
        pasteTxt = pasteTxt.slice(0, leftChar)
        for (let i = 0; i < pasteTxt.length; i++) {
            // const element = array[i];
            myMessageArr.push(pasteTxt[i])
            myMessageRaw = myMessageRaw + pasteTxt[i]
        }
        const selection = window.getSelection();
        if (!selection.rangeCount) return false;
        selection.deleteFromDocument();
        selection.getRangeAt(0).insertNode(document.createTextNode(pasteTxt));
        setMessageArr(myMessageArr)
        setMessageRaw(myMessageRaw)
    }

    return (
        <>
            <Box className='myBoxViewWithoutScroll'>
                <Grid container className="messageContainer" style={{ display: "flex", overflow: "auto", flexWrap: "nowrap", flexDirection: "column" }} ref={messagesEndRef}>
                    {messageData.map((msg) => (
                        <MessageCard msg={msg} />
                    ))}
                </Grid>
                <Paper component="form" className="commentBox">
                    <Badge variant="dot" color="primary" invisible={files != "" ? false : true}>
                        <IconButton onClick={() => openDocPop(true)} sx={{ p: '10px' }} aria-label="menu">
                            <img src={clip} />
                        </IconButton>
                    </Badge>
                    {atTag &&
                        taggingList()
                    }
                    <div
                        contenteditable="true"
                        id="commentInput1"
                        className="commentInputForAt"
                        suppressContentEditableWarning={true}
                        onInput={(event) => handleChangeAt(event)}
                        // onKeyUp={(event) => handleKey(event)}
                        // onKeyPress={(event) => handleKey(event)}
                    // onPaste={(event) => handlePaste(event)}
                    >
                    </div>
                    <IconButton className="sendButton" onClick={sendMsg} type="button" sx={{ p: '10px' }} aria-label="search">
                        <img src={send} />
                    </IconButton>
                </Paper>
            </Box>
            <p className="character-limit">{80 - messageRaw.length} characters</p>
            <DocUploadPop visible={docPop} openDocPop={openDocPop} handleDocPop={handleDocPop} />
        </>
    )
}

export default Comments