import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import {
    Box,
    AppBar,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    IconButton,
    Badge,
    MenuItem,
    Menu,
    Typography,
    Drawer,
    InputBase,
} from '@mui/material/';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import InboxIcon from '@mui/icons-material/Inbox';
import { useEffect, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import mobileLogo from "../../../assets/mobileLogo.svg";
import { useNavigate } from "react-router-dom";
import { windowSize } from "../../../utility";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
var token = localStorage.getItem('access_token')
const localDeviceId = localStorage.getItem('localDeviceId')
// let url = `wss://32qzwnl2yl.execute-api.ap-south-1.amazonaws.com/production?authorization=bearer%20${token}&mobilePlatform=iOS&appName=Hospital%20App%20iOSs&mobilePlatform=desktop&appName=Hostpital_web_app&deviceId=${localDeviceId}`
import {iconNotificationsCount,markReadNotifications } from "../../../services";
import moment from 'moment';
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function PrimarySearchAppBar({ notificationArray, notificationApiArray,notificationCount,setNotificationCount,setNotificationArray }) {
    const { isMobile } = windowSize();
    const [openDrawer, setOpenDrawer] = useState(false)
    const toggleDrawer = (value) => {
        setOpenDrawer(value)
        handleMobileMenuClose()
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const [role, setRole] = useState("");
  
    useEffect(() => {
        let systemRole = localStorage.getItem('roles')
        systemRole = JSON.parse(systemRole)
        console.log(systemRole,"checkR")
        if (systemRole) {
            if (systemRole['role'] == 'cityOps' && (systemRole['otherRoles'] && systemRole['otherRoles'].length && systemRole['otherRoles'].includes("cityOps"))) {
                setRole("cityOps")
            }
            else{
                setRole(systemRole['role'])
            }
        }
    }, []);
    useEffect(()=>{
        if(notificationApiArray.length > 0 ){
            setNotificationArray([notificationApiArray[notificationApiArray.length-1],...notificationArray])
        }

    },[notificationApiArray])

    console.log("role",notificationArray)
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const markingAsRead =(data)=>{
        markReadNotifications(data.messageSid).then(res => {
            routing('/viewTask/' + data.taskId)
                
        }).catch((err)=>{
            routing('/viewTask/' + data.taskId)
        })
    }

    const logOut = () => {
        localStorage.clear();
        window.location.href = '/login'
    };

    const navigate = useNavigate();

    const routing = (path) => {
        // navigate(path)
        window.location.href = path
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>
                <AccountCircle /> &nbsp;{localStorage.getItem('username')}
            </MenuItem>
            <MenuItem onClick={() => logOut()}>
                <LogoutIcon /> &nbsp;Logout
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>
                <AccountCircle /> &nbsp;{localStorage.getItem('username')}
            </MenuItem>

            <MenuItem sx={{padding:'0'}} onClick={() =>{ toggleDrawer(true)
             if(notificationCount>0){
                            iconNotificationsCount()
                            setNotificationCount(0)
                            }
                    
            }}>
                <IconButton
                    size="large"
                    aria-label="show 17 new notifications"
                    color="inherit" >
                    <Badge badgeContent={notificationCount} color="error" onClick={() => toggleDrawer(true)}>
                        <NotificationsIcon  />
                    </Badge>
                </IconButton>
                <span>Notifications</span>
            </MenuItem>
            {/* <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem> */}
            <MenuItem onClick={() => logOut()}>
                <LogoutIcon /> &nbsp;Logout
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <Box sx={{ flexGrow: 1 }} className="appBarTop">
                <AppBar position="static" style={{ background: "#06203f" }}>
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            sx={{ mr: 2 }}
                        >
                            {isMobile ?
                                <>
                                    <img src={mobileLogo} alt="PristynCare-mobile" style={{ width: "30px" }} />
                                    <div className='mobHead'> City Ops Task Manager</div>
                                </>
                                : <img src="https://img.pristyncare.com/new_brand%2Felements%2Flogo.svg" alt="PristynCare-logo" style={{ maxWidth: "150px" }} />}
                        </IconButton>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/* <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={()=>{navigate('/bdCreateTask')}}
                                color="inherit"
                            >
                                 <AddCircleOutlineIcon />
                            </IconButton> */}

                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <IconButton
                                size="large"
                                aria-label="show 17 new notifications"
                                color="inherit"
                                onClick={() => {toggleDrawer(true)
                                    if(notificationCount>0){
                                         iconNotificationsCount()
                                         setNotificationCount(0)
                                    }
                                }
                                }
                            >
                                <Badge badgeContent={notificationCount} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                        </Box>
                        <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                       {role=="cityOps"? <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={()=>{navigate('/bdCreateTask')}}
                                color="inherit"
                                style={{padding:"3px"}}
                            >
                           <AddCircleOutlineIcon />
                            </IconButton>:null}
                            <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                                style={{padding:"3px"}}
                            >
                                <MoreIcon />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
                <Drawer
                    anchor={"right"}
                    open={openDrawer}
                    onClose={() => toggleDrawer(false)}
                    PaperProps={{ sx: { height: "100vh", minWidth: "250px", background: "#00263E", color: "white", padding: "10px 0px", top: ['0px', '65px', '64kpx'] } }}>
                    <List style={{ paddingBottom: "60px" }}>
                        {notificationArray.length > 0 ? notificationArray.map((itemData) => (
                           
                            <ListItem className='notificationList' ket={itemData.messageSid} disablePadding>
                                <ListItemButton onClick={()=>{
                                 if(itemData && !itemData.isRead) {markingAsRead(itemData)}
                                 else{
                                    routing('/viewTask/' + itemData.taskId)
                                 }
                                   
                                }}> 
                                    <div className='notificationButton' style={itemData.isRead ? {    backgroundColor: 'grey',
    color: 'white'}:{
    backgroundColor: 'white',
    color: 'black'
}}>
                                        <p style={{ margin: '5px 8px' }}>
                                            {itemData.taskId}
                                            {console.log(itemData)}
                                        </p>
                                        <p style={{
                                            margin: '5px 8px', wordBreak: "break-word",
                                            width: "250px"
                                        }}>
                                            {itemData.body}
                                        </p>
                                        <p className='dateText'>
                                            {/* {itemData.dateCreated} */}
                                            {moment(itemData.dateCreated).format('DD-MM-YYYY || hh:mm A')}
                                        </p>
                                    </div>
                                </ListItemButton>
                            </ListItem>
                        ))
                            : <ListItem>
                                No Notification.
                            </ListItem>
                        }
                    </List>

                </Drawer>
            </Box>
        </>
    );
}
