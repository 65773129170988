import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import documentImage from "../../../assets/document.svg";
import noFiles from "../../../assets/noFiles.png";
import docImage from "../../../assets/docImage.png";
import pdfIcon from "../../../assets/icon-pdf.svg";
import docIcon from "../../../assets/icon-doc.svg";
import csvIcon from "../../../assets/icon-csv.svg";
import excelIcon from "../../../assets/icon-xls.svg";
const IMAGE_OBJ = {
  pdf: pdfIcon,
  xlsx: excelIcon,
  csv: csvIcon,
  doc: docIcon,
  docx: docIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docIcon,
  'application/pdf':pdfIcon,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':excelIcon,
  'text/csv':csvIcon,
  default: documentImage
}
const useStyles = makeStyles({
  viewAllBar: {
    backgroundColor: "#00263E",
    padding: "16px 0px 16px 24px !important",
  },
  viewAllHead: {
    color: "white",
    textAlign: "center",
  },
  cancelIcon: {
    color: "white",
  },
  formContainer: {
    padding: "40px",
  },
  noFiles: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
  },
  imageClass: {
    width: "100%",
    height: "90%",
  },
  imageClassDiv: {
    width: "50vw",
  },
  infoDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "& p": {
      margin: "0",
      padding: "0",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
  infoDivMobile: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      margin: "0",
      padding: "0",
    },
  },
  forDialogBox: {
    "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
      maxHeight: "calc(100% - 1%)",
      minWidth: "100%",
    },
  },
});

const AllImageView = ({ savedFileArr, isMobile }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const handleClickOpen = (validUrl, item) => {
    if (checkURL(validUrl)) {
      setSelectedItem({ downUrl: item, url: item });
    } else {
      setSelectedItem({ downUrl: item, url: documentImage });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function checkURL(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }
  function generateAttachTime(t) {
    let timestamp = new Date(t);
    let time =
      createDate(timestamp.toDateString().split(" ")) +
      ", " +
      createTime(timestamp.toTimeString().split(" ")[0].split(":"));
    return time;
  }
  const createDate = (dateString) => {
    return dateString[1] + " " + dateString[2];
  };

  const createTime = (timeString) => {
    let hr = parseInt(timeString[0]);
    if (hr > 12) {
      return hr - 12 + ":" + timeString[1] + " PM";
    } else {
      return hr + ":" + timeString[1] + " AM";
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className={
          savedFileArr.length > 0 ? classes.formContainer : classes.noFiles
        }
      >
        {savedFileArr && savedFileArr.length > 0 ? (
          savedFileArr.map((item, i) => {
            return item.subUrl ? (
              item.subUrl.map((imageUrl, index) => {
                return (
                  <Grid item xs={6} md={6} spacing={4}>
                    <div
                      style={
                        isMobile
                          ? { height: "12rem", width: "100%" }
                          : { height: "23rem", width: "100%" }
                      }
                    >
                      <img
                        className={classes.imageClass}
                        src={
                          checkURL(item.multiUrl[index]) ? imageUrl : IMAGE_OBJ[item.filetype?item.filetype:'default']
                        }
                        alt={item.filetype}
                        onClick={() =>
                          handleClickOpen(item.multiUrl[index], imageUrl)
                        }
                      />
                    </div>

                    <div
                      className={
                        isMobile ? classes.infoDivMobile : classes.infoDiv
                      }
                    >
                      <p>
                        {item.fileName?item.fileName:`Attachment ${index + 1} of ${i + 1}`}
                        
                      </p>
                      <p
                        style={
                          isMobile
                            ? { margin: "0px", fontSize: "0.9rem" }
                            : null
                        }
                      >
                        {generateAttachTime(item.timestamp)}
                      </p>
                    </div>
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={6} md={6} spacing={4}>
                <div
                  style={
                    isMobile
                      ? { height: "12rem", width: "100%" }
                      : { height: "23rem", width: "100%" }
                  }
                >
                  <img
                    className={classes.imageClass}
                    src={checkURL(item.message_media) ? item.url : IMAGE_OBJ[item.filetype?item.filetype:'default']}
                    alt={item.filetype}
                    onClick={() =>
                      handleClickOpen(item.message_media, item.url)
                    }
                  />
                </div>

                {/* <div ><p>Attachment {index+1}</p> <p style={{float:'right'}}>{generateAttachTime(item.timestamp)}</p></div> */}
                <div
                  className={isMobile ? classes.infoDivMobile : classes.infoDiv}
                >
                  <p>{item.fileName ? item.fileName : `Attachment ${i + 1}`}</p>{" "}
                  <p
                    style={
                      isMobile ? { margin: "0px", fontSize: "0.9rem" } : null
                    }
                  >
                    {generateAttachTime(item.timestamp)}
                  </p>
                </div>
              </Grid>
            );

            //   <Grid item xs={6} md={6} spacing={4} >

            //     {item.subUrl?
            //     item.subUrl.map((imageUrl,index)=>{
            //       return(
            //         <img className={classes.imageClass} src={checkURL(item.multiUrl[index])?imageUrl:documentImage} alt="qwertyu" onClick={()=>handleClickOpen(item.multiUrl[index],imageUrl)} />
            //       )
            //     }):
            //   <img className={classes.imageClass} src={checkURL(item.message_media)?item.url:documentImage} alt="qwertyu" onClick={()=>handleClickOpen(item.message_media,item.url)} />}
            // </Grid>
          })
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={noFiles} />
            <p> No Files</p>
          </div>
        )}
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.forDialogBox}
      >
        <DialogTitle id="alert-dialog-title" className="viewAllBar">
          <Grid container>
            <Grid xs={1} className="viewAllHead">
              <a href={selectedItem.downUrl} download target="_blank">
                <DownloadIcon className="cancelIcon" />
              </a>
            </Grid>
            <Grid xs={10} className="viewAllHead">
              Documents for Task
            </Grid>
            <Grid xs={1} style={{ display: "flex", justifyContent: "end" }}>
              <Button onClick={handleClose}>
                <CancelIcon className="cancelIcon" />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img
              className={classes.imageClassDiv}
              src={selectedItem.url}
              alt="qwertyu"
            />
          </div>
        </DialogContent>
        {/* <DialogActions>
          
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </>
  );
};
export default AllImageView;
