import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Layout from "../layout";
import CreateTask from "../layout/screens/CreateTask";
import ViewTask from "../layout/screens/ViewTask";
import EditTask from "../layout/screens/EditTask";
import Login from "../layout/screens/Login";
import BdTaskCreate from '../layout/screens/createTaskMobileView/bdTaskCreate'
import { getAllFilterData, getBDNotifications, getHospitalMapping, socketUrl, getClinicMapping } from "../services";
import { PrimarySearchAppBar } from "../layout/common";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { v4 as uuidv4 } from 'uuid';

// if (localStorage.getItem('access_token')) {
//     const client = new W3CWebSocket(socketUrl);
// }


export default function Routing() {

    const [filterData, setFilterData] = useState(null);
    const [notificationArray, setNotificationArray] = useState([])
    const [notificationApiArray, setNotificationApiArray] = useState([])
    const [hospitalData, sethospitalData] = useState(null);
    const [comingMessage, setComingMessage] = useState([])
    const [clinicData, setClinicData] = useState(null);
    const [notificationCount,setNotificationCount]=useState(0)
    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            getAllFilterData().then(res => {
                if (res.data.result) {
                    let myTagList = []
                    res.data.result && res.data.result.amList && res.data.result.amList.map((el) => (
                        el.name && el.userId ? myTagList.push({ name: el.name, id: el.userId }) : ''
                    ))
                    res.data.result && res.data.result.bdList && res.data.result.bdList.map((el) => (
                        el.name && el.userId ? myTagList.push({ name: el.name, id: el.userId }) : ''
                    ))
                    res.data.result && res.data.result.gmList && res.data.result.gmList.map((el) => (
                        el.name && el.name ? myTagList.push({ name: el.name, id: el.userId }) : ''
                    ))
                    res.data.result && res.data.result.reporters && res.data.result.reporters.map((el) => (
                        el.reporterName && el.reporterId ? myTagList.push({ name: el.reporterName, id: el.reporterId }) : ''
                    ))
                    myTagList.sort((a, b) => a.name.localeCompare(b.name))
                    res.data.result['allList'] = myTagList

                    let myBdList = []
                    res.data.result && res.data.result.bdList && res.data.result.bdList.map((el) => (
                        el.name && el.userId ? myBdList.push({ name: el.name, userId: el.userId }) : ''
                    ))
                    myBdList.sort((a, b) => a.name.localeCompare(b.name))
                    res.data.result['bdList'] = myBdList

                    let myGmList = []
                    res.data.result && res.data.result.gmList && res.data.result.gmList.map((el) => (
                        el.name && el.userId ? myGmList.push({ name: el.name, userId: el.userId }) : ''
                    ))
                    myGmList.sort((a, b) => a.name.localeCompare(b.name))
                    res.data.result['gmList'] = myGmList

                    let myAgmList = []
                    res.data.result && res.data.result.agmList && res.data.result.agmList.map((el) => (
                        el.name && el.userId ? myAgmList.push({ name: el.name, userId: el.userId }) : ''
                    ))
                    myAgmList.sort((a, b) => a.name.localeCompare(b.name))
                    res.data.result['agmList'] = myAgmList

                    let myAmList = []
                    res.data.result && res.data.result.amList && res.data.result.amList.map((el) => (
                        el.name && el.userId ? myAmList.push({ name: el.name, userId: el.userId }) : ''
                    ))
                    myAmList.sort((a, b) => a.name.localeCompare(b.name))
                    res.data.result['amList'] = myAmList

                    let myReporters = []
                    res.data.result && res.data.result.reporters && res.data.result.reporters.map((el) => (
                        el.reporterName && el.reporterId ? myReporters.push({ reporterName: el.reporterName, reporterId: el.reporterId }) : ''
                    ))
                    myReporters.sort((a, b) => a.reporterName.localeCompare(b.reporterName))
                    res.data.result['reporters'] = myReporters

                    setFilterData(res.data.result);
                }
            })
            getHospitalMapping().then(res => {
                console.log(res.data.result)
                if (res.data.result) {
                    sethospitalData(res.data.result)
                }
            })
            getClinicMapping().then(res => {
                console.log(res.data.result)
                if (res.data.result) {
                    setClinicData(res.data.result)
                }
            })
        }
    }, [localStorage.getItem('access_token')])

    useEffect(() => {
        if (localStorage.getItem('access_token')) {
            const client = new W3CWebSocket(socketUrl+`&authorization=bearer%20${localStorage.getItem('access_token')}`);
            console.log("WebSocket socketClient", client)
            client.onopen = () => {
                console.log('WebSocket Client Connected');
            };
            client.onmessage = (data) => {
                let newData = JSON.parse(data.data)
                addNewMessagge(newData)
            };
            client.onerror = function () {
                console.log('WebSocket Connection Error');
            };

            getBDNotifications(localStorage.getItem("userId")).then(res => {
                if (res.data && res.data.result && res.data.result.notificationDataList.length > 0) {
                    let transformArr = []
                    res.data.result.notificationDataList.forEach(el => {
                        transformArr.push({
                            "dateCreated": el._created_at,
                            "body": el.messageBody,
                            "title": el.title,
                            "key": el.key,
                            "messageSid": el._id,
                            "taskId": el.taskId,
                            'isRead':el.read ? el.read: false,
                        })
                    });
                    // setNotificationApiArray(transformArr)
                    setNotificationArray([...transformArr])
                    setNotificationCount(res.data.result.notificationCount)
                }
            })
        }
    }, [localStorage.getItem('access_token')])

    const addNewMessagge = (data) => {

        if (data.event == "mentionNotification" || data.event == "statusNotification" || data.event == "assignNotification" || data.event == "visitPlanNotification" || data.event=="attachmentNotification") {
           
            // notificationArray.push(data.messageData)
            notificationApiArray.push(data.messageData)
            setNotificationApiArray([...notificationApiArray])    
            setNotificationCount((prev)=> prev + 1)
        }
        else {
            if (data && data.event) {
                setComingMessage([data])
            }
        }
    }

    return (
        <BrowserRouter>
            {window.location.pathname != '/login' && <PrimarySearchAppBar notificationArray={notificationArray} notificationApiArray={notificationApiArray}  notificationCount={notificationCount} setNotificationCount={setNotificationCount} setNotificationArray={setNotificationArray} />}
            <Routes>
                {localStorage.getItem('access_token') == null &&
                    <Route path="*" element={<Navigate to="/login" />} />
                }
                {localStorage.getItem('access_token') != null &&
                    <Route path="/">
                        <Route index element={<Layout filterData={filterData} comingMessage={comingMessage} />} />
                        <Route path='/createTask' element={<CreateTask filterData={filterData} hospitalData={hospitalData} clinicData={clinicData} />} />
                        <Route path='/viewTask/:id' element={<ViewTask filterData={filterData} comingMessage={comingMessage} />} />
                        <Route path="/editTask/:id" element={<EditTask filterData={filterData} hospitalData={hospitalData} clinicData={clinicData} />} />
                        <Route path="/bdCreateTask" element={<BdTaskCreate filterData={filterData} hospitalData={hospitalData} clinicData={clinicData}/>} />
                        </Route>
                }
                <Route path="/login" element={<Login />} />
            </Routes>
        </BrowserRouter>
    )
}