import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, CssBaseline, AppBar, Toolbar, Card, Tabs, Tab } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Box, display } from "@mui/system";
import { MyCard, Accordian, TabPanel, FilterArea, Filter, Search, SearchIconWrapper, StyledInputBase } from "./common";
import { getTaskList, getBDTasksList, getAllFilterData } from "../services";
import { windowSize } from "../utility";
import debounce from 'lodash.debounce';
import Loader from './common/Loader/loader';
import "./style.css";
import moment from 'moment';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
export default function Layout({ filterData, comingMessage }) {

    const { isMobile } = windowSize();
    const initialDate = new Date()
    const [dateRangeSelected, setDateRangeSelection] = useState({ visitDate: false, startDate: false, endDate: false })
    const [filter, setFilter] = useState(false)
    const [taskListArr, setTaskListArr] = useState([])
    const [taskListArrMob, setTaskListArrMob] = useState([])
    const [value, setValue] = React.useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true)
    const [params, setParams] = useState({ page: 1 });
    const [pageNO, setPageNo] = useState(1)
    const [filtered, setFiltered] = useState({});
    const [mobileFiltered, setMobileFiltered] = useState({ 'status': 'All' })
    const [totalCount, setTotalCount] = useState(0)
    const scrollDebounceRef = useRef();
    const [desktopFilter, setDesktopFilter] = useState({});
    const [search, setSearch] = useState('')
    const [noData, setDataMessage] = useState(false)
    const isFirstRender = useRef(true);
// console.log(mobileFiltered," garima mobileFiltered in main")
    // new approach
    const [taskListObj, setTaskListObj] = useState({})
    const [currentTaskList, setCurrentTaskList] = useState([])

    useEffect(() => {
        let filteredTmp = JSON.parse(localStorage.getItem('filtered'))
        console.log(filteredTmp, "filteredTmp")
        if (filteredTmp) {
            setFiltered(filteredTmp)
        }
    }, [])

    useEffect(() => {
        if (filtered) {
            localStorage.setItem('filtered', JSON.stringify(filtered))
        }
    }, [filtered])

    function currentTaskListHandler(cityName, index) {

        setCurrentTaskList(taskListObj[cityName])
        setValue(index)
    }

    // helper function
    function DTOforTaskListToObj(taskListArr) {
        setCurrentTaskList([])
        if (taskListArr && taskListArr.length) {
            return taskListArr.reduce((prev, curr, idx) => {
                if (idx === 0) {
                    setCurrentTaskList(curr.title.filter(x => x.count > 0))
                }
                if (curr && curr.count > 0) {
                    prev[curr.city] = curr.title.filter(x => x.count > 0)
                    return prev
                }
            }, {})
        } else {
            return {}
        }
    }



    useEffect(() => {

        if (comingMessage.length > 0 && comingMessage[0].event == "onStatusChange" && comingMessage[0].messageData.status) {
            let newArr = []
            if (isMobile) {
                taskListArrMob.length > 0 && taskListArrMob.forEach((listItem) => {
                    if (listItem.taskId === comingMessage[0].messageData.taskId) {
                        listItem.status = comingMessage[0].messageData.status
                        newArr.push(listItem)
                    }
                    else {
                        newArr.push(listItem)
                    }
                })
                setTaskListArrMob([...newArr])
                setTaskListObj(DTOforTaskListToObj(newArr))
                // setCurrentTaskList(taskListObj[newArr[0].city])


            }
            else {
                taskListArr.length > 0 && taskListArr.forEach((listItem) => {
                    if (listItem.taskId === comingMessage[0].messageData.taskId) {
                        listItem.status = comingMessage[0].messageData.status
                        newArr.push(listItem)
                    }
                    else {
                        newArr.push(listItem)
                    }
                })
                setTaskListArr([...newArr])

            }
        }
    }, [comingMessage])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            // setHasMore(true)
            setTaskListArr([])
            setTaskListArrMob([])
            // setParams({ ...params, page: 1 })
            // setPageNo(1)
            getTaskListWithFilter(1, params.date, true);
        } else {
            setHasMore(true)
            setTaskListArr([])
            setTaskListArrMob([])
            setParams({ ...params, page: 1 })
            setPageNo(1)
            getTaskListWithFilter(1, params.date, true);
        }
    }, [isMobile, search, filtered, mobileFiltered])

    const scrollDebounce = debounce((event) => {
        if (!hasMore) return;
        if (scrollDebounceRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollDebounceRef.current;
            if (scrollTop + clientHeight >= scrollHeight) {
                let page = params.page + 1;
                setParams({ ...params, ...{ page: page } })
                getTaskListWithFilter(pageNO + 1, params.date, false);
                setPageNo(pageNO + 1)
            }
        }
    }, 300);

    const searchDebounce = debounce((txtToSearch) => {
        setSearch(txtToSearch)
    }, 1000)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigate = useNavigate();

    const routing = (path) => {
        navigate(path)
    }

    const switchFilter = () => {
        setFilter(!filter)
    }

    const dateFormate = (d) => {
        return new Date(d).toISOString().split('T')[0]
    }

    const fixedFormatDate = (timestamp) =>{
        return moment(timestamp).format('YYYY-MM-DD');
    }

    const getTaskListWithFilter = (page, date, clean) => {
        
        setIsLoading(true)
        if (isMobile) {
            let data = {}
            // Start Date Range
            dateRangeSelected.startDate ? data['startDateFrom'] = fixedFormatDate(mobileFiltered.startDateFrom) : ''
            dateRangeSelected.startDate ? data['startDateTo'] = fixedFormatDate(mobileFiltered.startDateTo) : ''

            // Visit Date Range
            dateRangeSelected.visitDate ? data['visitStartDate'] = fixedFormatDate(mobileFiltered.visitDateFrom) : ''
            dateRangeSelected.visitDate ? data['visitEndDate'] = fixedFormatDate(mobileFiltered.visitDateTo) : ''

            // End Date Range
            dateRangeSelected.endDate ? data['endDateFrom'] = fixedFormatDate(mobileFiltered.endDateFrom) : ''
            dateRangeSelected.endDate ? data['endDateTo'] = fixedFormatDate(mobileFiltered.endDateTo) : ''

            // status
            mobileFiltered.status.toLowerCase() !== 'all' ? data['status'] = mobileFiltered.status : ''

            // visit date flag
            mobileFiltered.visitDateFlag ? data['visitDateFlag'] = mobileFiltered.visitDateFlag : ''
            let visitDateFlagTmp = JSON.parse(localStorage.getItem('visitDateFlag'))
            if (visitDateFlagTmp) {
                data['visitDateFlag'] = true
            }

            // search
            search ? data["searchText"] = search : ''

            getBDTasksList({ "bdId": localStorage.getItem('userId'), ...data })
                .then(res => {
                    if (res.data && res.data['result']) {
                        let val = res.data['result']
                        let cityArr = []
                        let taskArr = []
                        val.forEach(task => {
                            if (!cityArr.includes(task.city)) {
                                cityArr.push(task.city)
                            }
                            if (!taskArr.includes(task.taskType)) {
                                taskArr.push(task.taskType)
                            }
                        })

                        let taskToPush = []
                        let titleToPush = []
                        let cityTOPush = []
                        let count = 0
                        cityArr.forEach(city => {
                            titleToPush = []
                            count = 0
                            taskArr.forEach(title => {
                                taskToPush = []
                                val.forEach(task => {
                                    if (task.taskType == title && task.city == city) {
                                        taskToPush.push(task)
                                    }
                                })
                                titleToPush.push({ title: title, task: taskToPush, count: taskToPush.length })
                                count = count + taskToPush.length
                            })
                            cityTOPush.push({ city: city, title: titleToPush, count: count })
                        })
                        setTaskListArrMob(cityTOPush) //for Mobile
                        setTaskListObj(DTOforTaskListToObj(cityTOPush))
                        // setDataMessage(true)
                    }
                })
                .catch(() => { })
                .finally(() => {
                    // false goes here
                    setIsLoading(false)
                })
        } else {
            // init
            // setIsLoading(true)
            var data = {}
            // page
            data["page"] = page
            // {filters begins}
            // creation date
            filtered.creationDate && filtered.creationDate[0] ? data["creationDate"] = filtered.creationDate[0] : ''
            // start date
            filtered && filtered['startDate'] && filtered['startDate'].length && filtered['startDate'][0]['startDate'] ? data["startDateFrom"] = filtered['startDate'][0]['startDate'] : ''
            filtered && filtered['startDate'] && filtered['startDate'].length && filtered['startDate'][0]['endDate'] ? data["startDateTo"] = filtered['startDate'][0]['endDate'] : ''
            // end date
            filtered && filtered['endDate'] && filtered['endDate'].length && filtered['endDate'][0]['startDate'] ? data["endDateFrom"] = filtered['endDate'][0]['startDate'] : ''
            filtered && filtered['endDate'] && filtered['endDate'].length && filtered['endDate'][0]['endDate'] ? data["endDateTo"] = filtered['endDate'][0]['endDate'] : ''
            // visit date
            filtered && filtered['visitDate'] && filtered['visitDate'].length && filtered['visitDate'][0]['startDate'] ? data["visitStartDate"] = filtered['visitDate'][0]['startDate'] : ''
            filtered && filtered['visitDate'] && filtered['visitDate'].length && filtered['visitDate'][0]['endDate'] ? data["visitEndDate"] = filtered['visitDate'][0]['endDate'] : ''
            // status
            filtered.status && filtered.status.length && filtered.status[0] && filtered.status[0] !== 'All' ? data["status"] = filtered.status[0] : ''
            // task type
            filtered.taskType && filtered.taskType.length && filtered.taskType ? data["taskType"] = filtered.taskType : ''
            // department
            filtered.department && filtered.department.length && filtered.department ? data["department"] = filtered.department : ''
            // reporter
            filtered.reporter && filtered.reporter.length && filtered.reporter.map(el => el.userId) ? data["reporterId"] = filtered.reporter.map(el => el.userId) : ''
            // city
            filtered.city && filtered.city.length && filtered.city ? data["city"] = filtered.city : ''
            // bd name
            filtered.bdName && filtered.bdName.length && filtered.bdName.map(el => el.userId) ? data["bdId"] = filtered.bdName.map(el => el.userId) : ''
            // gm/agm
            filtered.gm && filtered.gm.length && filtered.gm.map(el => el.userId) ? data["gmId"] = filtered.gm.map(el => el.userId) : ''
            // am 
            filtered.am && filtered.am.length && filtered.am.map(el => el.userId) ? data["amId"] = filtered.am.map(el => el.userId) : ''
            // search
            search ? data["searchText"] = search : ''

            setDesktopFilter(data)
            getTaskList(data).then(function (res) {
                if (res.data && res.data['result'] && res.data['result']['data']) {
                    let val = res.data['result']['data']
                    let count = res.data['result']['count']
                    setTotalCount(Number(count))
                    setHasMore(true)
                    if (res.data['result']['data'].length == 0) {
                        setHasMore(false)
                    }
                    if (clean) {
                        setTaskListArr(val)   //for Web
                    } else {
                        val = taskListArr.concat(val)
                        setTaskListArr(val)
                    }
                    // setIsLoading(false)
                    // setDataMessage(true)
                }
            })
                .catch(() => { })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const handelFilterTab = (value, type) => {
        // setIsLoading(true)
        setFiltered({ ...value })
    }

    const removeFilterCallBack = (type) => {
        let something = { ...filtered }
        if (type == 'All') {
            Object.keys(something).forEach((filter) => {
                something[filter] = []
            })
        }
        else if (type == 'Mobile') {
            localStorage.removeItem('mobileFiltered')
            localStorage.setItem('visitDateFlag',false)
            let data={ visitDate: false, startDate: false, endDate: false }
            localStorage.setItem('dateRangeSelected',JSON.stringify(data))
            setDateRangeSelection({ visitDate: false, startDate: false, endDate: false })
            setMobileFiltered({ 'status': 'All' })
        }
        else {
            something[type] = []
        }
        setFiltered(something)
    }

    const countCity = (city) => {
        let count = city.map(item => item.count).reduce((currentCount, c) => currentCount + c, 0)
        return count
    }
    return (
        <React.Fragment xs={{ background: '#fff' }}>
            {isLoading && <Loader />}
            <CssBaseline />
            <Box className="myBox" data-component="TaskListContainer">

                {/* App Bar Starts */}
                <Box sx={{ flexGrow: 1, px: '0 !important' }}>
                    <AppBar
                        sx={{
                            position: 'static',
                            background: '#EAF1F9',
                            overflow: 'hidden',
                            display:'flex',
                            justifyContent:'center'
                        }}>
                        <Toolbar>
                            {!isMobile &&
                                <>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        sx={{ flexGrow: 2, display: { xs: 'none', sm: 'block' }, color: '#3D7CC9', fontSize: '20px' }}
                                    >
                                        Total Count: {totalCount}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        noWrap
                                        component="div"
                                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: '#00263E', fontSize: '24px' }}
                                    >
                                        City Ops Task Manager
                                    </Typography>
                                </>
                            }
                            
                            <Search style={{color:'fff',display:'flex'}}>
                            <div className="searchHead">
                            <SearchIconWrapper className="searchIcon">
                                    <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                onChange={(e) => searchDebounce(e.target.value)}
                                inputProps={{ 'aria-label': 'search' }}
                                placeholder="Search"
                                style={{paddingLeft:'10px'}}
                            />
                            </div>
                            </Search>
                        </Toolbar>
                    </AppBar>
                </Box>
                {/* App Bar Ends */}

                {/* Filter Area Starts */}
                <FilterArea
                    dateRangeSelected={dateRangeSelected}
                    setDateRangeSelection={setDateRangeSelection}
                    filtered={filtered}
                    mobileFiltered={mobileFiltered}
                    setMobileFiltered={setMobileFiltered}
                    isMobile={isMobile}
                    filterCallBack={switchFilter}
                    removeFilterCallBack={removeFilterCallBack}
                    desktopFilter={desktopFilter} />
                {/* Filter Area Ends */}


                {/* Card View Starts */}
                {isMobile &&
                    // <Box sx={{ width: '100%', marginTop: '20px' }}>
                    //     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    //         <Tabs className="tabBar" value={value} onChange={handleChange} aria-label="basic tabs example">
                    //             {taskListArrMob && taskListArrMob.map((task, index) => (
                    //                 <Tab label={task.city + " (" + task.count + ")"} {...a11yProps({ index })} />
                    //             ))}
                    //         </Tabs>
                    //     </Box>
                    //     {console.log(taskListArrMob)}
                    //     {taskListArrMob.length ? taskListArrMob.map((task, index) => (
                    //         <TabPanel value={value} index={index}>
                    //             {task.title && task.title.map((subtask, index2) => (
                    //                 <Accordian title={subtask.title} count={subtask.count}
                    //                     card={subtask.task && subtask.task.map((tasklist, index3) => (
                    //                         <Card onClick={() => routing('/viewTask/' + tasklist.taskId)} variant="outlined" className={isMobile ? "myCardMobile" : "myCard"}>
                    //                             <MyCard index={index3} task={tasklist} isMobile={isMobile} />
                    //                         </Card>
                    //                     )
                    //                     )} />
                    //             ))}
                    //         </TabPanel>
                    //     )) : <>{<p className="noDataMsg">No Data Found!</p>}</>}
                    // </Box>
                    <Box sx={{ width: '100%', marginTop: '20px' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: "100vw" }}>
                            <Tabs value={value} className="tabBar btn-scroll" aria-label="basic tabs example">

                                {taskListObj && Object.keys(taskListObj).map((task, index) => (
                                    <Tab onClick={() => {
                                        currentTaskListHandler(task, index)
                                    }} label={task + " (" + (taskListObj[task].map(item => item.count).reduce((currentCount, c) => currentCount + c, 0)) + ")"} {...a11yProps({ index })} />
                                ))}
                            </Tabs>
                        </Box>

                        <Box className="accordian-container">
                            {currentTaskList && currentTaskList.length ? currentTaskList.map((subtask, index) => (
                                <TabPanel value={index} index={index}>
                                    <Accordian title={subtask.title} count={subtask.count}
                                        card={subtask.task && subtask.task.map((tasklist, index3) => (
                                            <Card onClick={() => routing('/viewTask/' + tasklist.taskId)} variant="outlined" className={isMobile ? "myCardMobile" : "myCard"}>
                                                <MyCard index={index3} task={tasklist} isMobile={isMobile} />
                                            </Card>
                                        )
                                        )} />

                                </TabPanel>
                            )) : <>{<p className="noDataMsg">No Data Found!</p>}</>}
                            <div style={{"height":"70px"}}></div>
                        </Box>
                    </Box>
                }
                {!isMobile &&
                    <div className="fixedLayout" onScroll={scrollDebounce} ref={scrollDebounceRef}>
                        {taskListArr ?
                            taskListArr.map((task, index) => (
                                <Card variant="outlined" className="myCard">{<MyCard index={index} task={task} filterData={filterData} />}</Card>
                            )) :
                            <>
                                {<p className="noDataMsg">No Data Found!</p>}
                            </>}
                    </div>
                }
                {/* Card View Ends */}

            </Box>
            <Filter
                display={filter}
                filterData={filterData}
                filtered={filtered}
                clickAwayFilter={setFilter}
                removeFilterCallBack={removeFilterCallBack}
                handelFilterTab={handelFilterTab} />
        </React.Fragment>
    );
}
